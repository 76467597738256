#header-banner {
  padding: 15px 0;

  .section-sztarok.node-categorized-page & {
    background-color: $c-sztarok;
  }

  .section-fashion-beauty.node-categorized-page & {
    background-color: $c-fashion;
  }

  .section-egeszseg.node-categorized-page & {
    background-color: $c-egeszseg;
  }

  .section-lifestyle.node-categorized-page & {
    background-color: $c-lifestyle;
  }

  .section-utazas.node-categorized-page & {
    background-color: $c-utazas;
  }

  .section-bed-love.node-categorized-page & {
    background-color: $c-bed-love;
  }

  .section-home-design.node-categorized-page & {
    background-color: $c-home-design;
  }

  .section-eskuvo.node-categorized-page & {
    background-color: $c-eskuvo;
  }

  .section-recept.node-categorized-page & {
    background-color: $c-recept;
  }

  .section-christmas.node-categorized-page & {
    background-color: $c-christmas;
  }

  .sticky & {
    display: none;
  }
}

.femcafe-adv {
  position: relative;

  .section-social-gallery & {
    margin-bottom: $basic-unit;
  }

  &.bottom {
    margin: $basic-unit 0;
  }

  .femcafe-adv-title {
    @include rotate;
    color: $darkgrey;
    font-size: 12px;
    position: absolute;
    line-height: 1;
    right: -10px;
    top: 22px;
  }

  .textlink {
    background: $grey;
    padding: 1px;
    min-height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: $basic-unit / 2;
      font-family: $body-font;
      font-size: 16px;
      line-height: 200%;
    }

    a {
      color: $c-black !important;
    }

    .section-sztarok & {
      background: $c-sztarok;
    }

    .section-fashion-beauty & {
      background: $c-fashion;
    }

    .section-egeszseg & {
      background: $c-egeszseg;
    }

    .section-lifestyle & {
      background: $c-lifestyle;
    }

    .section-utazas & {
      background: $c-utazas;
    }

    .section-bed-love & {
      background: $c-bed-love;
    }

    .section-home-design & {
      background: $c-home-design;
    }

    .section-eskuvo & {
      background: $c-eskuvo;
    }

    .section-recept & {
      background: $c-recept;
    }

    .section-christmas & {
      background: $c-christmas;
    }
  }
}

#block-spike-ad-top-banner {
  text-align: center;
  max-width: 970px;
  margin: 0 auto;
}

.pane-spike-ad-bb1-zona,
.pane-spike-ad-bb2-zona,
.pane-spike-ad-bb3-zona,
#block-spike-ad-bb1-zona,
#block-spike-ad-bb2-zona,
#block-spike-ad-bb3-zona {
  text-align: center;
}

.pane-spike-ad-mobile-ad-top,
.pane-spike-ad-mobile-ad,
.pane-spike-ad-mobile-ad-adsense-nyitooldali,
.pane-spike-ad-mobile-ad-footer,
#block-spike-ad-mobile-ad,
#block-spike-ad-mobile-ad-cikk-alatt,
.pane-spike-ad-mobile-ad-cube-brutus,
#block-spike-ad-mobile-ad-top {
  text-align: center;
  margin: 0 -15px $basic-unit;
}

/* roadblock hirdetések, 640px szélességgel */
#block-spike-ad-roadblock,
#block-spike-ad-normal-banner,
.pane-spike-ad-normal-banner,
#block-spike-ad-content-bottom,
.pane-spike-ad-roadblock,
.pane-spike-ad-roadblock-fc-adsense,
#block-spike-ad-roadblock-fc-adsense {
  width: 640px;
  height: auto;
  overflow: hidden;
  margin: 0 auto 1.5 * $basic-unit;
  text-align: center;
}

#block-spike-ad-galeria-adsense {
  margin: 0 auto 1.5 * $basic-unit;

  .content {
    text-align: center;
  }
}

/* Hirdetes title before advertisement */
.pane-spike-ad-roadblock-fc-adsense .pane-content::before,
#block-spike-ad-roadblock-fc-adsense .content::before {
  text-align: center;
  font-size: 10px;
  color: $darkgrey;
  content: 'Hirdetés';
  display: block;
  margin-bottom: 5px;
  line-height: initial;
}

.pane-spike-ad-roadblock-fc-adsense {
  clear: both;
  padding-top: 20px;
}

#block-spike-ad-normal-banner,
.pane-spike-ad-normal-banner {
  height: auto;
}

#block-spike-ad-roadblock .content,
#block-spike-ad-content-bottom .content,
.pane-spike-ad-normal-banner .pane-content,
.pane-spike-ad-roadblock .pane-content {
  padding: 0;
}

.mobile #content .panel-pane.pane-spike-ad-mobile-ad-cube-brutus img,
.mobile #content .panel-pane.pane-spike-ad-mobile-ad-top img {
  max-width: 100% !important;
  width: 100% !important;
  height: auto !important;
}

.mobile_ad_top,
.mobile_middle_ad {
  overflow: hidden;
}

.mobile .pane-spike-ad-mobile-ad-adsense-cikkoldali,
.mobile #block-spike-ad-mobile-ad-adsense-cikkoldali,
.mobile .pane-spike-ad-mobile-ad-adsense-nyitooldali,
.mobile .pane-spike-ad-mobile-ad-adsense-listaoldali,
.mobile #block-spike-ad-mobile-ad-adsense-listaoldali,
.mobile #block-spike-ad-mobile-ad-above-fbbox {
  text-align: center;
}

.mobile #block-spike-ad-mobile-ad-adsense-cikkoldali {
  width: 100%;
  margin-bottom: 15px;
  clear: both;
}

#block-spike-ad-mobile-ad-footer .content {
  text-align: center;
  padding-top: 10px;
}

.mobile #block-spike-ad-roadblock {
  display: none;
}

.mobile .fixed_google_ad {
  position: fixed;
  z-index: 10002;
  left: 0;
  border: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  text-align: center;
  max-height: 50px;
  max-width: 320px;
  margin: 0 auto 0 0;
}

.colorize {
  padding: 1px 0;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;

  &.yellowbg {
    background: $colorize-yellow;
    box-shadow: 5px 0 0 $colorize-yellow, -5px 0 0 $colorize-yellow;
  }

  &.celebbg {
    background: $c-sztarok;
    box-shadow: 5px 0 0 $c-sztarok, -5px 0 0 $c-sztarok;
  }

  &.fashionbg {
    background: $c-fashion;
    box-shadow: 5px 0 0 $c-fashion, -5px 0 0 $c-fashion;
  }

  &.egeszsegbg {
    background: $c-egeszseg;
    box-shadow: 5px 0 0 $c-egeszseg, -5px 0 0 $c-egeszseg;
  }

  &.receptbg {
    background: $c-recept;
    box-shadow: 5px 0 0 $c-recept, -5px 0 0 $c-recept;
  }

  &.bedlovebg {
    background: $c-bed-love;
    box-shadow: 5px 0 0 $c-bed-love, -5px 0 0 $c-bed-love;
  }

  &.vilagbg {
    background: $c-utazas;
    box-shadow: 5px 0 0 $c-utazas, -5px 0 0 $c-utazas;
  }

  &.lifestylebg {
    background: $c-lifestyle;
    box-shadow: 5px 0 0 $c-lifestyle, -5px 0 0 $c-lifestyle;
  }

  &.homebg {
    background: $c-home-design;
    box-shadow: 5px 0 0 $c-home-design, -5px 0 0 $c-home-design;
  }

  &.eskuvobg {
    background: $c-eskuvo;
    box-shadow: 5px 0 0 $c-eskuvo, -5px 0 0 $c-eskuvo;
  }

  &.christmasbg {
    background: $c-christmas;
    box-shadow: 5px 0 0 $c-christmas, -5px 0 0 $c-christmas;
  }

  &.blackbg {
    background: $c-black;
    box-shadow: 5px 0 0 $c-black, -5px 0 0 $c-black;
    color: $c-white;
  }
}

/**
 * Content box defaults
 */
.view.content-box {
  background: $content-box-bg;
  border: 1px solid $content-box-border;
  overflow: hidden;

  .view-header {
    width: 372px;
    height: 60px;

    @include breakpoint($bp-tablet) {
      width: auto !important;
      height: auto !important;
    }

    a {
      height: 62px;
      width: 372px;
      padding: 0;
      line-height: 100%;
      display: block;
    }

    p {
      margin: 0 !important;
    }
  }

  .view-content {
    padding: 0;

    .views-row {
      width: auto;
      margin: 0;
      padding: 12px;
      border-bottom: 1px solid $content-box-brd1;
      border-top: 1px solid $content-box-brd2;
      clear: both;
      min-height: 100px;
    }

    .views-row-last {
      border-bottom: 0;
      //padding-bottom: 0;
    }

    .views-row-first {
      border-top: 0;
    }
  }

  .views-field-field-default-picture {
    background: none;
    padding: 0;
    float: left;
    margin-right: 10px;

    @include breakpoint($bp-tablet) {
      float: none;
    }
  }

  .views-field-title {
    float: none !important;
    width: auto !important;
    margin: 10px 0 !important;
    height: auto !important;

    a {
      color: $c-black;
      font-size: 18px;
      font-family: $body-font;
      line-height: 110%;
      font-weight: bold;
    }
  }

  .view-footer {
    line-height: 0;

    a {
      display: block;
      width: 372px;
      height: 79px;
      padding: 0;
      line-height: 100%;

      @include breakpoint($bp-tablet) {
        width: auto;
        height: auto;
      }
    }

    p {
      margin: 0 !important;
    }
  }
}

ins[data-ad-status="unfilled"] {
  display: none !important;
}
