// visually hidden
@mixin visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin default-btn {
  padding: 1.33 * $basic-unit 2 * $basic-unit;
  margin-top: 2 * $basic-unit;
  font-size: 1.33 * $basic-unit;
  justify-content: center;
  align-items: center;
  border: 0;
  color: $c-white;
  background: $main-purple;
  text-shadow: none;
  box-shadow: none;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    opacity: 0.8;
    transition: 1s ease;
  }
}

@mixin drop-shadow() {
  filter: $drop-shadow;
}

@mixin rotate($degree: -90deg) {

  transform: rotate($degree);
  /* Safari */
  -webkit-transform: rotate($degree);
  /* Firefox */
  -moz-transform: rotate($degree);
  /* IE */
  -ms-transform: rotate($degree);
  /* Opera */
  -o-transform: rotate($degree);
  /* Internet Explorer */
  $filter: map-get((
      "-90deg": 3,
      "+90deg": 1,
  ), $degree);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$filter);
}
