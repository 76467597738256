.not-front:not(.taxonomy-page),
.not-front.page-tags,
.front #front_layout,
.not-front #rovat_layout {

  .sidebar {
    &--wrapper {
      //display: flex;
      flex: 1;
      max-width: 374px;
      padding-left: 0;
      padding-right: 0;
      width: auto;
      float: none;
      margin-left: auto;

      @include breakpoint($bp-tablet-below) {
        display: none;
      }

      /*
      @include breakpoint($bp-sm-desktop) {
        flex: 0 0 350px;
        max-width: 350px;
        background-color: $c-black;
      }

      @include breakpoint($bp-md-desktop) {
        flex: 0 0 380px;
        max-width: 380px;
      }
       */

      @include breakpoint($bp-desktop) {
        flex: 0 0 374px;
        max-width: 374px;
        min-height: 100px;
      }

      @include breakpoint($bp-tablet) {
        max-width: 225px;
        flex: 0 0 225px;
      }
    }

    &--container {
      position: relative;
      flex: 0 0 100%;
      max-width: 100%;
      z-index: 1;
      margin-left: 0;

      .block {
        margin-bottom: 2 * $basic-unit;
      }
    }
  }

  .main--wrapper {
    flex: 1;
    max-width: 796px;
    margin-right: auto;
    width: auto;
    float: none;

    @include breakpoint($bp-tablet) {
      max-width: 481px;
    }

    @include breakpoint($bp-tablet-below) {
      max-width: 100%;
    }

    .main--container {
      max-width: 772px;
      margin: 0;

      @include breakpoint($bp-tablet) {
        max-width: 465px;
      }

      @include breakpoint($bp-tablet-below) {
        max-width: 100%;
      }
    }
  }

  #container {
    @include flex-container;

    .mobile & {
      display: block;
    }

    @include breakpoint($bp-tablet-below) {
      display: block;
    }
  }
}

/* side bar end */
#pagewrapper {
  margin-bottom: 2 * $basic-unit;

  .mobile.taxonomy-page & {
    padding-top: $basic-unit;
  }
}

.main--wrapper {
  .sidebar--wrapper {
    /*
    @include breakpoint($bp-sm-desktop-below) {
      display: none;
    }
     */

    .stop-scroll & {
      display: block;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 50px 30px 115px;
      background-color: $c-black;
      z-index: 9;
      overflow: scroll;
      -webkit-animation: slide-down 0.30s ease-out;
      -moz-animation: slide-down 0.30s ease-out;
    }
  }
}

.panel-col-top {
  .panel-2col-stacked & {
    width: 100%;
  }

  .inside {
    display: flex;

    @include breakpoint($bp-tablet-below) {
      display: block;
    }

    .layout-0 {
      width: 772px;
      margin-right: auto;

      @include breakpoint($bp-tablet) {
        width: 465px;
      }

      @include breakpoint($bp-tablet-below) {
        max-width: 100%;
      }
    }

    .panel-separator {
      flex: 0;
    }

    .layout-3 {
      width: 374px;
      margin-left: auto;
      margin-top: 16px;

      @include breakpoint($bp-tablet) {
        width: 225px;
      }

      @include breakpoint($bp-tablet-below) {
        width: 100%;
      }
    }
  }
}

.header--wrapper {
  .stop-scroll & {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
    background-color: $c-black;
    transition: 0.30s ease;
  }
}

#footer-wrapper {
  background: $main-purple;
  height: 288px;

  .stop-scroll & {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: $c-black;
    transition: 0.30s ease;
  }
}

.page-background {
  width: 100%;
  height: 500px;
  position: absolute;
  z-index: -1;
  background-color: $c-lifestyle;

  .mobile & {
    height: 380px;
  }

  @include breakpoint($bp-tablet-below) {
    height: 380px;
  }

  body:not(.node-categorized-page) &,
  body.page-blog & {
    display: none;
  }

  .section-sztarok & {
    background-color: $c-sztarok;
  }

  .section-fashion-beauty & {
    background-color: $c-fashion;
  }

  .section-egeszseg & {
    background-color: $c-egeszseg;
  }

  .section-lifestyle & {
    background-color: $c-lifestyle;
  }

  .section-utazas & {
    background-color: $c-utazas;
  }

  .section-bed-love & {
    background-color: $c-bed-love;
  }

  .section-home-design & {
    background-color: $c-home-design;
  }

  .section-eskuvo & {
    background-color: $c-eskuvo;
  }

  .section-recept & {
    background-color: $c-recept;
  }

  .section-christmas & {
    background-color: $c-christmas;
  }

  .section-meteospasmyl & {
    background-color: $c-meteospasmyl;
  }
}

.fixed-header {
  position: fixed;
}

.fixed-main {
  position: fixed;
  max-width: 340px;
}

