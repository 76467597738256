#navigation {
  position: relative;
  margin: 2 * $basic-unit 0 $basic-unit;
  border-bottom: 1px solid $main-purple;

  @include breakpoint($bp-tablet-below) {
    display: none;
  }

  @include breakpoint($bp-tablet) {
    display: none;
  }

  .header--wrapper.sticky &,
  .section-inspiralonok & {
    display: none;
  }

  .hide-menu & {
    display: none;
  }
}

#navigation-opener {
  display: none;
  max-width: 70px;
  height: 32px;

  .hide-menu & {
    display: block;
    //margin-left: auto;

    @include breakpoint($bp-tablet) {
      margin-left: 0;
    }
  }

  /*
  @include breakpoint($bp-tablet-below) {
    margin-left: auto;
  }
  */

  &.opened span {
    vertical-align: 1px;
  }

  a {
    color: $main-purple;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    background-image: url("../images/icon_menu.svg");
    background-repeat: no-repeat;
    background-size: 23px 29px;
    background-position: right 8px;
    width: 65px;
    height: 20px;
  }

  &.opened a {
    background-position: right -10px;
  }

  .header--wrapper.sticky & {
    display: block;
    flex: 1;
  }

  @include breakpoint($bp-tablet) {
    display: block;
  }

  @include breakpoint($bp-tablet-below) {
    display: block;
  }
}

#navigation-links {
  @include drop-shadow;
  display: none;
  z-index: 50;
  padding: 0;

  .desktop & {
    position: fixed;
    //margin-top: 65px;
    top: 65px;

    @include breakpoint($bp-tablet-below) {
      margin-top: 0;
    }
  }

  .mobile & {
    position: relative;
  }

  .mobile.sticky-header & {
    position: fixed;
  }

  .drop-down-container {
    @include flex-wrap;
    padding: $basic-unit;
    background: $c-white;
  }

  .desktop.admin-menu & {
    //margin-top: 75px;
    top: 95px;
  }

  /*
  &.visible {
    display: flex !important;

    @include breakpoint($bp-tablet-below) {
      position: relative;
      margin: 0;
      width: 100vw;
    }

    .mobile & {
      position: relative;
      margin: 0;
      width: 100vw;
    }
  }
   */

  .title {
    font-size: 0.75 * $basic-unit;
    text-transform: uppercase;
    margin-top: $basic-unit;
    font-family: $body-font;
  }

  .primary-links {
    border-top: 1px solid $secondary-purple;
    margin-top: 2 * $basic-unit;
    //width: 59%;
    width: 100%;

    .mobile & {
      //width: 49%;
      width: 100%;
    }

    @include breakpoint($bp-tablet-below) {
      //width: 49%;
      width: 100%;
    }
  }

  .secondary-links {
    border-top: 1px solid $secondary-purple;
    margin: 2 * $basic-unit 0 0 auto;
    // 2% for spacer...
    width: 39%;

    .mobile & {
      width: 49%;
    }

    @include breakpoint($bp-tablet-below) {
      width: 49%;
    }

    ul.links li {
      //width: 50%;
      // Updated due to IN menu button.
      width: 110px;

      &.menu-url-inspiralonok {
        min-width: 240px;
      }

      &.menu-hidden {
        display: none !important;
      }

      @include breakpoint($bp-tablet) {
        width: 100%;
      }

      @include breakpoint($bp-tablet-below) {
        width: 100%;
      }

      a {
        color: $c-black;
        display: block;
        font-size: 16px;
        height: 40px;
        line-height: 60%;
        text-decoration: none;
        text-transform: capitalize;
      }
    }
  }

  .banner-wrapper {
    flex-basis: 100%;
    position: relative;
    margin-bottom: 2 * $basic-unit;
  }

  ul.links {
    @include flex-wrap;
    //justify-content: space-between;
    justify-content: flex-start;
    padding: 0 40px;

    @include breakpoint($bp-tablet-below) {
      //flex-direction: column;
      justify-content: flex-start;
      padding-left: 0;
      padding-right: $basic-unit;
    }

    .mobile & {
      //flex-direction: column;
      justify-content: flex-start;
      padding-left: 0;
      padding-right: $basic-unit;
    }

    li {
      display: block;
      width: 20%;

      @include breakpoint($bp-tablet) {
        width: 33%;
      }

      @include breakpoint($bp-tablet-below) {
        //width: 100%;
        width: 50%;
      }

      .mobile & {
        //width: 100%;
        width: 50%;
      }

      a {
        background-repeat: no-repeat;
        background-size: 32px 32px;
        padding-left: 42px;
        padding-top: 12px;

        &:hover {
          margin-top: 0;
          border-bottom: 0;
        }
      }

      &.menu-url-sztarok a {
        background-image: url("../images/icon_sztarok.svg");

        &:hover {
          color: $c-sztarok;
        }
      }

      &.menu-url-fashionbeauty a,
      &.menu-url-fashion-beauty a {
        background-image: url("../images/icon_fashion-beauty.svg");

        &:hover {
          color: $c-fashion;
        }
      }

      &.menu-url-egeszseg a {
        background-image: url("../images/icon_egeszseg.svg");

        &:hover {
          color: $c-egeszseg;
        }
      }

      &.menu-url-lifestyle a {
        background-image: url("../images/icon_lifestyle.svg");

        &:hover {
          color: $c-lifestyle;
        }
      }

      &.menu-url-utazas a {
        background-image: url("../images/icon_utazas.svg");

        &:hover {
          color: $c-utazas;
        }
      }

      &.menu-url-bedlove a,
      &.menu-url-bed-love a {
        background-image: url("../images/icon_bed-love.svg");

        &:hover {
          color: $c-bed-love;
        }
      }

      &.menu-url-homedesign a,
      &.menu-url-home-design a {
        background-image: url("../images/icon_home-design.svg");

        &:hover {
          color: $c-home-design;
        }
      }

      &.menu-url-eskuvo a {
        background-image: url("../images/icon_eskuvo2.svg");

        &:hover {
          color: $c-eskuvo;
        }
      }

      &.menu-url-recept a {
        background-image: url("../images/icon_recept.svg");

        &:hover {
          color: $c-recept;
        }
      }

      &.menu-url-blog a {
        background-image: url("../images/icon_interjuk.svg");

        &:hover {
          color: $c-interju;
        }
      }

      &.menu-url-christmas a {
        background-image: url("../images/icon_christmas.svg");

        &:hover {
          color: $c-christmas;
        }
      }
    }
  }
}

#navigation-links ul.links li,
#secondary #secondary-menu li {
  &.menu-url-horoszkop a {
    background-image: url("../images/icon_horoscope.svg");

    &:hover {
      color: $c-horoscope;
    }
  }

  &.menu-url-szakertoink a {
    background-image: url("../images/icon_szakertok.svg");

    &:hover {
      color: $c-szakertok;
    }
  }

  &.menu-url-royal-clinics a {
    background-image: url("../images/icon_royal_clinics.svg");

    &:hover {
      color: $c-royal-clinics;
    }
  }

  &.menu-url-femanyu a {
    background-image: url("../images/icon_femanyu.svg");

    &:hover {
      color: $c-femanyu;
    }
  }

  &.menu-url-inspiralonok a {
    //background-image: url("../images/icon_inspiralonok.svg");
    background-image: url("../images/IN_gomb_240x60px.png");
    text-indent: -5000px;
    overflow: hidden;
    height: 60px;
    background-size: unset;
    padding-left: 0;

    @include breakpoint($bp-mobile-inspiralonok-main) {
      height: 40px;
      background-size: 160px;
    }

    .mobile & {
      height: 40px;
      background-size: 160px;
    }

    &:hover {
      color: $c-inspiralonok;
    }
  }

  &.menu-url-agi-vlog a {
    background-image: url("../images/icon_agi_vlog.svg");

    &:hover {
      color: $c-agi-vlog;
    }
  }

  &.menu-url-meteospasmyl a {
    background-image: url("../images/icon_meteospasmyl.png");

    &:hover {
      color: $c-meteospasmyl;
    }
  }

  &.menu-url-20-perc-oszinten a {
    background-image: url("../images/20_perc_oszinten_menugomb.png");
    background-size: contain;
    padding: 0;
    margin-bottom: 10px;
    margin-top: -10px;

    &:hover {
      color: $c-20perc-oszinten;
    }
  }

  &.menu-url-dogblog a {
    background-image: url("../images/icon_dogblog.svg");

    &:hover {
      color: $c-dogblog;
    }
  }

  &.menu-url-highlife a {
    background-image: url("../images/icon_highlife.svg");

    &:hover {
      color: $c-highlife;
    }
  }
}

#primary {
  #main-menu {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: space-between;
    height: 40px;
    align-items: center;
  }
}

.primary-links {
  li {

    &.menu-hidden {
      display: none !important;
    }

    a {
      font-size: 16px;
      line-height: 60%;
      color: $c-black;
      text-transform: capitalize;
      text-decoration: none;
      display: block;
      height: 40px;

      &:hover {
        margin-top: -6px;
        border-bottom: 6px solid $main-purple;
      }
    }

    &.menu-url-sztarok a:hover {
      border-color: $c-sztarok;
    }

    &.menu-url-fashion-beauty a:hover {
      border-color: $c-fashion;
    }

    &.menu-url-egeszseg a:hover {
      border-color: $c-egeszseg;
    }

    &.menu-url-lifestyle a:hover {
      border-color: $c-lifestyle;
    }

    &.menu-url-utazas a:hover {
      border-color: $c-utazas;
    }

    &.menu-url-bed-love a:hover {
      border-color: $c-bed-love;
    }

    &.menu-url-home-design a:hover {
      border-color: $c-home-design;
    }

    &.menu-url-eskuvo a:hover {
      border-color: $c-eskuvo;
    }

    /*
    &.menu-url-eskuvo {
      a {
        background-image: url('../images//icon_eskuvo.svg');
        background-size: 32px 32px;
        background-repeat: no-repeat;
        background-position-y: top;
        background-position-x: left;
        padding-left: 38px;
        padding-top: 12px;
        height: 56px;

        &:hover {
          border-color: $c-eskuvo;
          margin-top: -18px;
          height: 40px;
        }

        .drop-down-container & {
          height: 40px;
        }
      }
    }
     */

    &.menu-url-christmas a:hover {
      border-color: $c-christmas;
    }

    &.menu-url-recept a:hover {
      border-color: $c-recept;
    }

    &.menu-url-20-perc-oszinten a {
      background-image: url("../images/20_perc_oszinten_menugomb.png");
      background-size: contain;
      padding: 0;
      line-height: 0;
      text-indent: -10000px;
      overflow: hidden;
      height: 43px;
      width: 133px;
      margin-top: -10px;

      &:hover {
        border-bottom: 0;
        border-color: $c-interju;
      }
    }

    &.menu-url-blog a:hover {
      border-color: $c-interju;
    }
  }
}

#main-menu li.menu-url-blog {
  display: inline-block;

  a {
    position: relative;

    &::after {
      position: absolute;
      top: -4px;
      right: -10px;
      content: url("../images/arrow_down.svg");
    }
  }
}

#secondary {
  @include drop-shadow;
  display: none;
  position: absolute;
  //width: 200px;
  // Update because of IN menu button.
  width: 276px;
  z-index: 20;
  background: $c-white;
  right: 0;

  &.visible {
    display: block;
  }

  #secondary-menu {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 2 * $basic-unit 1.5 * $basic-unit;
    list-style: none;
    justify-content: space-evenly;

    li {

      &.menu-hidden {
        display: none !important;
      }

      a {
        font-size: 16px;
        line-height: 60%;
        color: $c-black;
        text-transform: capitalize;
        text-decoration: none;
        display: block;
        background-repeat: no-repeat;
        background-size: 32px 32px;
        padding-left: 42px;
        padding-top: 12px;
        height: 40px;
      }
    }
  }
}
