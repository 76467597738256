#block-spike-social-buttons {
  ul.links li.search {
    padding: 0;

    a {
      display: inline-block;
      height: 34px;
      border-right: 1px solid $secondary-purple;
      padding-right: $basic-unit;
      background: url("../images/icon_search.svg") no-repeat;
      text-indent: 1000px;
      background-size: 34px 32px;
      margin-bottom: -2px;

      .section-inspiralonok .header--wrapper:not(.sticky) & {
        background: url("../images/icon_search_gold.svg") no-repeat;
      }

      &:hover {
        background: url("../images/icon_search_hover.svg") no-repeat;
        background-position: -19px -4px;
        background-size: 72px 72px;
      }
    }
  }
}

body:not(.front):not(.taxonomy-page) #block-spike-social-buttons .social-icons:not(.opened) li.search a,
body:not(.taxonomy-page) #block-spike-social-buttons .social-icons:not(.opened) li.search a {
  margin-bottom: 2px;
}

#search-opener {
  margin-left: auto;
  width: 50px;

  .desktop & {
    display: none;

    @include breakpoint($bp-tablet) {
      display: block;
    }

    @include breakpoint($bp-tablet-below) {
      display: block;
    }
  }

  a {
    display: block;
    height: 34px;
    background: url("../images/icon_search.svg") no-repeat;
    text-indent: 1000px;
    background-size: 34px 32px;

    .section-inspiralonok & {
      background: url("../images/icon_search_gold.svg") no-repeat;
    }

    &:hover {
      background: url("../images/icon_search_hover.svg") no-repeat;
      background-position: -19px -4px;
      background-size: 72px 72px;
    }
  }
}

#search-box-wrapper {
  width: 100vw;
  height: 100vh;
  background: $overlay-bg;
  //backdrop-filter: blur(32px);
  position: absolute;
  z-index: 200;

  &.hidden {
    @include visuallyhidden;
  }

  #search-box {
    position: fixed;
    z-index: 100;
    width: 680px;
    border: 2px solid $main-purple;
    background: $c-white;
    padding: 3 * $basic-unit;
    top: 20%;
    left: 50%;
    margin-top: -100px;
    margin-left: -340px;

    .mobile & {
      margin-left: -170px;
      width: 300px;
      padding: 1.5 * $basic-unit;
      top: 40%;
    }

    .form-item {
      margin: 0;
    }

    #spike-search-box-google-cse-form > div {
      input.form-text {
        border: 1px solid $secondary-purple;
        padding: 1.5 * $basic-unit $basic-unit 1.5 * $basic-unit 2.5 * $basic-unit;
        color: $main-purple;
        background: $grey url("../images/bg_search_box.svg") no-repeat 6px center;
        width: calc(100% - 45px);
        font-size: 1.33 * $basic-unit;
      }

      input.form-submit {
        display: none;
      }
    }

    .close {
      cursor: pointer;
      display: block;
      background: url("../images/icon_close.svg") no-repeat;
      background-position: -90px -3px;
      background-size: 316px 53px;
      width: 2 * $basic-unit;
      height: 2 * $basic-unit;
      position: absolute;
      top: -$basic-unit;
      right: -$basic-unit;

      &:hover {
        background-position: -165px -3px;
      }
    }

    #search-tips {
      margin-top: 1.5 * $basic-unit;
      border-top: 1px solid $secondary-purple;
      padding-top: $basic-unit;

      .title {
        text-transform: uppercase;
        font-size: 0.75 * $basic-unit;
        font-family: $body-font;
        margin-bottom: 2 * $basic-unit;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          padding: 0;

          a {
            display: inline-block;
            background: $tags-bg;
            padding: 0.5 * $basic-unit $basic-unit;
            margin: 0 $basic-unit $basic-unit 0;
            text-decoration: none;

            &:hover {
              color: $main-purple;
            }
          }
        }
      }
    }
  }
}
