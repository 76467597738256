.blog-listpage.blog-royal-clinics {
  .views-row {
    .views-field-title {
      a {
        background: url("../images/logo_royal_clinics.png") no-repeat left center;
        padding-left: 150px;
        line-height: 40px;
        min-height: 66px;
      }
    }
  }

  .view-footer {
    text-align: center;
  }
}

.section-royal-clinics {
  #royal_clinics_blog_pre_cikk_image {
    margin: 20px auto 10px;
    text-align: center;

    img {
      .mobile & {
        width: 100%;
        height: auto;
      }

      @include breakpoint($bp-tablet-below) {
        width: 100%;
        height: auto;
      }
    }
  }

  &.desktop #content-content .node h1 {
    background: url("../images/logo_royal_clinics.png") no-repeat left center;
    padding-left: 150px;
  }
}

/* Skin */
/*
.desktop.blog-royal-clinic {
  #header-banner {
    display: none;
    background: none;
    border: 0;
    max-width: 980px;
    height: 250px;

    .region-header-banner > .block {
      margin: -5px auto 0;
      width: 980px;
      height: 0;
      display: block;
    }
  }

  #navigation {
    margin-bottom: -1px;
  }

  #pagewrapper {
    cursor: pointer;
    padding: 250px 0 0;
    margin-top: 0;

    #page {
      cursor: default;
    }

    &.szponzoralt {
      background: $c-white url('../szponzoracio/royal_clinics_femcafe_kapu.jpg') no-repeat;

      #page {
        background: $c-white;
      }
    }

    &.scrolled {
      background-attachment: scroll;
    }

    &.fixed {
      background-attachment: fixed;
    }

    &.bottom {
      background-position: center 100%;
    }

    &.top {
      background-position: center 130px;
    }

    &.zero {
      background-position: center 0;
    }
  }

  &.admin-menu {
    #pagewrapper {
      &.top {
        background-position: center 151px;
      }

      &.zero {
        background-position: center 30px;
      }
    }
  }

  #container {
    margin-top: -1px;
  }

  &.section-social-gallery {
    #pagewrapper.top {
      background-position: center 40px;
    }

    &.admin-menu {
      #pagewrapper.top {
        background-position: center 60px;
      }
    }
  }
}

#block-spike-ad-royal-clinics-2022-roadblock {
  text-align: center;

  img:not(.avcounter) {
    @include breakpoint($bp-tablet) {
      width: 100%;
      height: auto;
    }

    @include breakpoint($bp-tablet-below) {
      width: 100%;
      height: auto;
    }

  }
}

#block-spike-ad-royal-clinics-2022-mobile {
  text-align: center;
}
*/
