ul.links.social-icons,
.node .meta .links {
  li {
    a {
      display: inline-block;
      width: 32px;
      height: 32px;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

#block-spike-social-buttons {
  @include breakpoint($bp-tablet) {
    display: none;

    .header--wrapper.sticky & {
      display: block;
    }
  }
}

.mobile .social_icons_wrapper {
  float: right;
  margin-bottom: $basic-unit;
}

ul.links.social-icons {
  a {
    background-repeat: no-repeat;
    background-size: 359px 71px;
    background-position: -89px -4px;

    &:hover {
      background-position: -189px -4px;
    }

    #footer & {
      background-position: -139px -4px;
      background-size: 361px 72px;

      &:hover {
        background-position: -240px -4px;
      }
    }
  }

  #footer & li {
    display: inline-block !important;
    margin-right: 0 !important;
  }

  .fbpage {
    a {
      background-image: url("../images/social-icons/fb_round.svg");
    }

    .not-front:not(.taxonomy-page) #header-region & {
      display: none;
      margin-right: -48px;
    }
  }

  .fb-share-article {
    position: relative;

    a {
      background-image: url("../images/social-icons/fb_round.svg");
      margin-left: 40px;
    }

    .prefix {
      position: absolute;
      display: inline-block;
      font-size: 22px;
    }

    .not-front:not(.taxonomy-page) #header-region & {
      display: none;
      margin-right: -48px;
    }
  }

  .fb_iframe_widget {
    top: -10px;
  }

  .instagram {
    a {
      background-image: url("../images/social-icons/insta_round.svg");
    }

    .not-front:not(.taxonomy-page) #header-region & {
      display: none;
      margin-right: -48px;
    }
  }

  .tiktok {
    a {
      background-image: url("../images/social-icons/tiktok_round.svg");
    }

    .not-front:not(.taxonomy-page) #header-region & {
      display: none;
      margin-right: -48px;
    }
  }

  .viber {
    a {
      background-image: url("../images/social-icons/viber_round.svg");
    }

    .not-front:not(.taxonomy-page) #header-region & {
      display: none;
      margin-right: -48px;
    }
  }

  .pinterest_page,
  .pinterest {
    a {
      background-image: url("../images/social-icons/pinterest_round.svg");
    }

    .not-front:not(.taxonomy-page) #header-region & {
      display: none;
      margin-right: -48px;
    }
  }

  .youtube {
    a {
      background-image: url("../images/social-icons/youtube_round.svg");
    }

    .not-front:not(.taxonomy-page) #header-region & {
      display: none;
      margin-right: -48px;
    }
  }

  .follow_us_close {
    a {
      background-image: url("../images/icon_close.svg");
    }

    :not(.opened) & {
      display: none;
    }
  }

  .follow_us {
    a {
      @include default-btn;
      text-indent: unset !important;
      margin-top: 0;
      width: auto;
      height: auto;
      font-family: $main-font;
      padding: 8px 20px;
      margin-bottom: 2px;

      .section-inspiralonok .header--wrapper:not(.sticky) & {
        background: $c-inspiralonok;
        color: $main-purple;
      }
    }

    .front &,
    .taxonomy-page & {
      display: none;
    }
  }

  /*
  &.opened .follow_us {
    a {
      width: 0 !important;
    }
  }
   */
}
