/* 20 perc őszintén */
.page-20-perc-oszinten {
  h1#page-title {
    font-size: 2.5 * $basic-unit;
    color: $c-black;
  }

  .view-display-id-first_item {
    .views-field-title {
      position: absolute;
      bottom: 25px;
      left: 25px;
      width: 300px;

      .field-content {
        background: $c-20perc-oszinten;
        padding: 2px;
        line-height: 180%;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        box-shadow: 5px 0 0 $c-20perc-oszinten, -5px 0 0 $c-20perc-oszinten;

        a {
          font-size: 2 * $basic-unit;
          font-weight: bold;
          color: $main-purple;
          font-family: $main-font;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}


.view-husz-perc-oszinten {
  .views-row {
    @include flex-wrap;
  }

  .image_wrapper {
    position: relative;
    width: 278px;
    margin-right: 32px;

    @include breakpoint($bp-tablet) {
      width: 225px;
      margin-right: 15px;

      img {
        max-height: 175px !important;
      }
    }

    @include breakpoint($bp-tablet-below) {
      width: 50%;
      margin-right: 15px;

      img {
        max-height: unset;
        height: auto;
      }
    }
  }

  picture,
  .image_wrapper > a > img {
    border: 1px solid $c-20perc-oszinten;
    padding: 8px;
    display: block;
    line-height: 0;
    max-width: calc(100% - 16px);

    @include breakpoint($bp-tablet) {
      margin-bottom: $basic-unit;
    }

    &:hover {
      border-width: 9px;
      padding: 0;
    }
  }

  picture {

    img {
      max-width: 100%;
      height: auto;
    }

    @include breakpoint($bp-tablet-below) {
      max-width: 100%;
      height: auto;
    }
  }

  & > a > img {
    @include breakpoint($bp-tablet-below) {
      max-width: calc(100% - 16px);
      height: auto;
    }
  }

  .category {
    position: absolute;
    padding: $basic-unit / 2 $basic-unit;
    left: 9px;
    background: $c-20perc-oszinten;

    @include breakpoint($bp-tablet) {
      padding: $basic-unit / 3 $basic-unit / 2;
    }

    @include breakpoint($bp-tablet-below) {
      padding: $basic-unit / 3 $basic-unit / 2;
    }

    span {
      font-size: $basic-unit;
      color: $c-white;
    }
  }

  .title-body {
    font-family: $body-font;
    font-size: 20px;
    line-height: 160%;
    margin-left: auto;
    width: calc(100% - 310px);

    @include breakpoint($bp-tablet) {
      width: calc(100% - 240px);

      .views-field-body {
        display: none;
      }
    }

    @include breakpoint($bp-tablet-below) {
      margin-left: 0;
      width: calc(50% - 15px);
    }

    a {
      font-family: $main-font;
      font-size: 32px;
      line-height: 125%;
      color: $c-black;
      text-decoration: none;

      @include breakpoint($bp-tablet) {
        font-size: 20px;

        &:hover {
          box-shadow: inset 0 -10px $c-20perc-oszinten;
        }
      }

      @include breakpoint($bp-tablet-below) {
        font-size: 16px;
      }

      &:hover {
        box-shadow: inset 0 -18px $c-20perc-oszinten;
        transition: 0.30s ease;
      }
    }

    .views-field-body {
      .mobile & {
        display: none;
      }

      @include breakpoint($bp-tablet-below) {
        display: none;
      }
    }
  }

  .attachment {
    clear: both;
  }

  &.view-display-id-first_item {
    .views-row {
      float: none;
      width: auto;
      position: relative;
    }

    .views-field-field-default-picture {
      padding-bottom: 5px;
    }

    .mobile-image {
      display: none;
    }
  }

  &.view-display-id-small_pics .views-row {
    float: none;
    width: auto;
    position: relative;
  }
}

.mobile {
  #social-gallery-current-item.recommendation {
    .arrow-left {
      top: 230px;
    }
  }

  &.section-social-gallery {
    #block-spike-ad-husz-perc-oszinten-video {
      margin-top: 20px;
    }
  }
}
