.stop-scroll {
  overflow: hidden;

  @include breakpoint($bp-tablet-landscape-below) {
    bottom: 0;
    top: 0;
    position: fixed;
  }
}

.clearfix {
  @include clearfix;
}

.sr-only,
.visually-hidden {
  background-color: $c-white;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  color: $c-black;
}

.hidden {
  display: none;
}

/*
.ajax-progress {
  display: none;
}
*/
.ajax-progress {
  .glyphicon {
    font-size: 90%;
    margin: 0 -.25em 0 0.5em;
  }
}

.toolbar-meta {
  margin-top: 0;
  margin-left: auto;
}

.bg-color {
  background-color: $c-white;
}

.pl-30 {
  padding-left: 30px !important;
}


.md-hidden {
  @include breakpoint($bp-sm-desktop) {
    display: none !important;
  }
}

.fixed-footer {
  position: fixed !important;
  left: 0;
  right: auto;
  bottom: 0;
  padding: 0 30px;

  @include breakpoint($bp-sm-desktop) {
    width: 380px;

  }

  @include breakpoint($bp-desktop) {
    width: 400px;
  }
}

.flex-container {
  @include flex-container;
}

svg.ext,
svg.mailto {
  display: none;
}

.input__required {
  .form-required {
    &::after {
      content: "*" !important;
      color: $c-red !important;
      font-size: 16px;
      line-height: normal;
      vertical-align: text-top;
    }
  }
}
