.view-quizes {

  &.layout-2 {
    .views-row {
      @include flex-wrap;

      @include breakpoint($bp-tablet) {
        align-items: center;
      }

      @include breakpoint($bp-tablet-below) {
        align-items: center;
      }

      .image_wrapper {
        width: 278px;
        margin-right: 32px;
        position: relative;

        picture,
        & > a > img {
          border: 1px solid $c-lifestyle;
          padding: 8px;
          display: block;
          line-height: 0;
          max-width: calc(100% - 16px);

          &:hover {
            border-width: 9px;
            padding: 0;
          }
        }

        @include breakpoint($bp-tablet) {
          width: 225px;
          margin-right: 15px;

          img {
            max-height: 175px !important;
          }
        }

        @include breakpoint($bp-tablet-below) {
          width: 50%;
          margin-right: 15px;

          img {
            max-height: unset;
            height: auto;
          }
        }
      }

      .category {
        position: absolute;
        padding: $basic-unit / 2 $basic-unit;
        left: 9px;
        top: 195px;
        background: $c-lifestyle;

        @include breakpoint($bp-tablet) {
          padding: $basic-unit / 3 $basic-unit / 2;
        }

        @include breakpoint($bp-tablet-below) {
          padding: $basic-unit / 3 $basic-unit / 2;
        }

        span {
          font-size: $basic-unit;
          color: $c-black;
        }

        @include breakpoint($bp-tablet) {
          top: 154px;
        }
      }

      .title-body {
        font-family: $body-font;
        font-size: 20px;
        line-height: 160%;
        margin-left: auto;
        width: calc(100% - 310px);

        @include breakpoint($bp-tablet) {
          width: calc(100% - 240px);

          .views-field-body {
            display: none;
          }
        }

        @include breakpoint($bp-tablet-below) {
          margin-left: 0;
          width: calc(50% - 15px);
        }

        a {
          font-family: $main-font;
          font-size: 32px;
          line-height: 125%;
          color: $c-black;
          text-decoration: none;

          @include breakpoint($bp-tablet) {
            font-size: 20px;

            &:hover {
              box-shadow: inset 0 -10px $c-lifestyle;
            }
          }

          @include breakpoint($bp-tablet-below) {
            font-size: 16px;
          }

          &:hover {
            box-shadow: inset 0 -18px $c-lifestyle;
            transition: 0.30s ease;
          }
        }

        .views-field-body {

          .mobile & {
            display: none;
          }

          @include breakpoint($bp-tablet-below) {
            display: none;
          }
        }
      }
    }
  }
}

.section-teszt {
  .webform-client-form {
    padding-bottom: 20px;

    .form-actions {
      text-align: center;
    }
  }

  .webform-component .element-outer > .form-item > label {
    line-height: 24px;
    background-color: $main-purple;
    padding: 10px;
    color: $c-white;
    border-radius: 2px;
    font-weight: normal;
  }

  h3 {
    font-family: $body-font;
  }

  .field-name-field-answer-a,
  .field-name-field-answer-b,
  .field-name-field-answer-c,
  .field-name-field-answer-d {
    font-family: $body-font;
    line-height: 160%;
  }

  .bottom_link {
    text-align: center;
    margin: 3 * $basic-unit 0;

    a.button {
      @include default-btn;
    }
  }
}
