.pane-spike-newsletter-newsletter-subscribe,
#block-spike-newsletter-newsletter-subscribe {
  max-width: 100%;
}

.spike-newsletter-subscribe-form {
  background-image: url("../images/bg_newsletter.svg");
  background-repeat: no-repeat;
  //background: url("../images/bg_newsletter.png") no-repeat;
  width: 360px;
  height: 320px;
  padding: 225px 7px 12px;

  @include breakpoint($bp-tablet) {
    width: calc(100% - 14px);
    background-size: contain;
    padding-top: 100px;
    height: auto;
    background-color: $secondary-purple;
  }

  .subscribe-now {
    font-family: $main-font;
    color: $c-white;
    font-size: 4 * $basic-unit;
    line-height: 125%;
    font-weight: $regular;

    @include breakpoint($bp-tablet) {
      font-size: 2 * $basic-unit;
    }
  }

  input.form-text {
    max-width: calc(100% - 36px);
    padding: 1.33 * $basic-unit;
    background: $bg-form-item;
    color: $darkgrey;
    border-radius: $basic-unit / 2;
  }

  .form-type-checkbox {
    display: flex;

    label {
      color: $c-sztarok;
      font-size: 12px;
      line-height: 133%;
      padding-left: 20px;
      font-family: $body-font;

      a {
        color: $c-sztarok;
      }
    }
  }

  .submit-buttons {
    @include flex-container;
    align-items: center;

    @include breakpoint($bp-tablet) {
      flex-direction: column;
    }

    .fb-subscribe {

      @include breakpoint($bp-tablet) {
        margin-bottom: $basic-unit;
      }

      a {
        @include default-btn;
        background-image: url("../images/bg_fbsubscribe_button.svg");
        background-repeat: no-repeat;
        padding: $basic-unit $basic-unit $basic-unit 28px;
        background-position: 4px 13px;
        display: block;
        margin-top: 0;

        @include breakpoint($bp-tablet) {
          font-size: 14px;
        }
      }
    }

    .form-submit {
      margin-left: auto;
      background: $c-sztarok;
      padding: $basic-unit;
      color: $main-purple;
      margin-top: 0;

      @include breakpoint($bp-tablet) {
        margin-left: 0;
      }
    }
  }
}

.subscribe-popup-wrapper {
  background-color: $overlay-bg;
  z-index: 996;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /*
  .close-button {
    background: url('../images/icon_close.svg');
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: relative;
    left: 50%;
    top: -283px;
    margin-left: 250px;
    z-index: 65;
  }
  */
}

#thankyou-box {

  .close-button {
    @include default-btn;
    cursor: pointer;
  }

  .thankyou-content {
    position: relative;
    left: 50%;
    top: 150px;
    margin-left: -150px;
    width: 270px;
    height: 200px;
    background: $c-white url("../images/bg_newsletter_thanks.png") no-repeat center 35px;
    z-index: 60;
    padding: 180px 40px 0;
    text-align: center;

    p {
      font-size: 3 * $basic-unit;
      margin: 0 0 2 * $basic-unit;
      line-height: 125%;
      color: $main-purple;
      text-align: center;
    }
  }
}


#privacy-policy-box {
  .close-button {
    top: 127px;
    margin-left: 260px;

    &.inactive {
      cursor: auto;
      background: url('../images/newsletter-subscribe_popup_close_inactive.jpg');
    }
  }
}

.privacy-policy-content {
  position: relative;
  left: 50%;
  top: 160px;
  margin-left: -320px;
  width: 620px;
  height: 450px;
  z-index: 997;
  padding: 10px 20px;
  background: $c-white;
  overflow: auto;
  font-family: Helvetica, sans-serif;
  font-size: 17px;
  line-height: 23px;
  color: $main-purple;

  p {
    text-align: center;
    padding-bottom: 15px;
  }

  ul {
    padding-left: 30px;
    margin: 10px 0;

    li {
      list-style-type: disc;
    }
  }

  .intro {
    font-size: 15px;
    line-height: 20px;
    color: $c-white;
    text-align: center;
    font-family: Helvetica, sans-serif;
    padding: 10px 20px;
    background: $main-purple;
    margin: -10px -20px 20px;
  }

  .button-wrapper {
    text-align: center;
    margin: 25px;
  }

  .button {
    @include default-btn;
    /*
    background-color: #624c83;
    background-image: -moz-linear-gradient(top, #624c83 5%, #947eb5 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #624c83), color-stop(100%, #947eb5));
    background-image: -webkit-linear-gradient(top, #624c83 5%, #947eb5 100%);
    background-image: -o-linear-gradient(top, #624c83 5%, #947eb5 100%);
    background-image: -ms-linear-gradient(top, #624c83 5%, #947eb5 100%);
    background-image: linear-gradient(to bottom, #624c83 5%, #947eb5 100%);
    padding: 10px;
    color: #fff;
    text-shadow: 1px 1px 0 #000;
    border: 1px solid #fff;
    border-radius: 12px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;

    &:hover {
      background-color: #947eb5;
      background-image: -moz-linear-gradient(top, #947eb5 5%, #8470a2 100%);
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #947eb5), color-stop(100%, #8470a2));
      background-image: -webkit-linear-gradient(top, #947eb5 5%, #8470a2 100%);
      background-image: -o-linear-gradient(top, #947eb5 5%, #8470a2 100%);
      background-image: -ms-linear-gradient(top, #947eb5 5%, #8470a2 100%);
      background-image: linear-gradient(to bottom, #947eb5 5%, #8470a2 100%);
      text-decoration: none;
    }

     */
  }
}

/* popup subscribe */
/*
.admin-menu #block-spike-newsletter-newsletter-subscribe-popup {
  margin-top: 20px;
}
#block-spike-newsletter-newsletter-subscribe-popup .spike-newsletter-subscribe-form-v1 {
  position: relative;
  left: 50%;
  top: 260px;
  margin-left: -320px;
  width: 305px;
  height: 280px;
  background: url('../images/newsletter-subscribe_popup.jpg') no-repeat;
  padding: 93px 10px 5px 35px;
  color: white;
  font-family: Arial, sans-serif;
  z-index: 998;
}
#nyeremenyjatek-box {
  position: relative;
  left: 50%;
  top: -283px;
  margin-left: -20px;
  width: 350px;
  height: 440px;
  background: url('../images/newsletter-subscribe_popup_nyeremeny.jpg') no-repeat;
  z-index: 997;

  a {
    display: block;
    height: 100%;
  }
}
.admin-menu #block-spike-newsletter-newsletter-subscribe-popup {
  margin-top: 20px;
}
*/
