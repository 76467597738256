.video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin-top: -0.81666em;
    margin-left: -1.5em;
  }
}

.field-name-field-video {
  position: relative;
  width: 100%;
  height: 400px;
}

.video-wrapper {
  text-align: center;
  position: relative;
  margin-bottom: 2 * $basic-unit;

  .video-title {
    background-color: $webform-bg;
    font-size: 2em;
    padding: 8px 12px;

    @include breakpoint($bp-tablet) {
      font-size: 1.5em;
    }

    @include breakpoint($bp-tablet-below) {
      font-size: 1.2em;
    }

    .video-label {
      text-transform: uppercase;
      background-color: $bg-cta-button;
      color: $c-white;
      padding: 2px 6px;
    }
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
  }

  .video-js {
    margin: 0 auto;
  }
}

#video-container {
  position: relative;
  /* forces the container to match a 16x9 aspect ratio */
  /* replace with 75% for a 4:3 aspect ratio, if needed */
  padding-bottom: 56.25%;
}

#video-element {
  /* forces the contents to fill the container */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#ad-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.63332em;
  width: 3em;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  margin-top: -0.81666em;
  margin-left: -1.5em;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid $c-white;
  //background-color: #2B333F;
  background-color: $overlay-bg;
  color: $c-white;
  border-radius: 0.3em;
  transition: all 0.4s;
}

#ima-sample-container {
  margin-left: auto;
  margin-right: auto;
  width: 728px;
}

.urlLink {
  color: $brown;
  text-decoration: underline;
  cursor: pointer;
}

.stream-type-container {
  display: inline;
  margin-bottom: 15px;
}

.stream-param-title {
  margin: 15px 0 0;
}

.dai-player {
  width: 640px;
  height: 360px;
}

#ima-sample-videoplayer {
  position: relative;
  background-color: $c-black;
  border-radius: 5px;
  box-shadow: 0 0 20px $c-light-black;
  border: 2px $grey solid;
  width: 640px;
  height: 360px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

#content_video {
  overflow: hidden;
}

#ima-sample-playlistDiv {
  height: 122px;
  width: 224px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.ima-sample-playlistItem {
  height: 122px;
  width: 102px;
  float: left;
  margin-right: 10px;
  cursor: pointer;
}

.ima-sample-playlistImg {
  border: 1px solid;
}

#ima-sample-console {
  font-family: courier, monospace;
  font-size: 12px;
  margin-top: 20px;
  height: 200px;
  width: 630px;
  padding: 5px;
  border: 1px $grey solid;
  overflow-y: scroll;
  margin-left: auto;
  margin-right: auto;
}

#ima-sample-companionDiv {
  width: 728px;
  height: 90px;
  margin-top: 20px;
}

#live-api-key,
#vod-api-key {
  margin-top: 5px;
}

#dai-load-button {
  margin-top: 15px;
}
