/* Adventi naptar */
#adventi {
  margin: 0 auto;
  background: $bg-advent-cal;
  width: 374px;
  min-height: 216px;
  padding: 0;

  @include breakpoint($bp-tablet) {
    max-width: 100% !important;
  }

  @include breakpoint($bp-tablet-below) {
    max-width: 100%;
  }

  .top {
    background-image: url("../images/christmas/bg_adventi_naptar_top.png");
    background-repeat: repeat-x;
    background-position: top left;
    padding-top: 15px;
  }

  .bottom {
    background-image: url("../images/christmas/bg_adventi_naptar_bottom.png");
    background-repeat: repeat-x;
    background-position: bottom left;
    padding-bottom: 14px;
  }

  .left {
    background-image: url("../images/christmas/bg_adventi_naptar_left.png");
    background-repeat: repeat-y;
    background-position: top left;
    width: calc(100% - 20px);
    padding-left: 20px;
  }

  .right {
    background-image: url("../images/christmas/bg_adventi_naptar_right.png");
    background-repeat: repeat-y;
    background-position: top right;
    width: calc(100% - 20px);
    padding-right: 20px;
  }

  #title {
    color: $c-christmas;
    font-size: 34px;
    text-align: center;
    background-image: url("../images/christmas/bg_adventi_naptar_title.png");
    background-repeat: no-repeat;
    background-position: top center;
    padding: 45px 0;

    @include breakpoint($bp-tablet) {
      font-size: 20px;
    }

    @include breakpoint($bp-mobile) {
      font-size: 28px;
      padding: 50px 0;
    }
  }

  .aktiv {
    display: inline-block;
  }

  #calendar {
    @include flex-wrap();
    width: 100%;
    justify-content: space-between;

    @include breakpoint($bp-tablet) {
      justify-content: flex-start;
    }

    @include breakpoint($bp-mobile) {
      justify-content: space-evenly;
    }

    div {
      @include breakpoint($bp-tablet) {
        &:nth-child(3n) a {
          margin-right: 0;
        }
      }
    }

    a {
      display: block;
      text-indent: -10000px;
      overflow: hidden;
      height: 61px;
      width: 61px;
      margin-bottom: 6px;
      background-repeat: no-repeat;

      @include breakpoint($bp-tablet) {
        margin-bottom: 1px;
        margin-right: 1px;
      }

      @include breakpoint($bp-tablet-below) {
        margin-bottom: 4px;
      }

      .mobile & {
        margin-bottom: 4px;

        @include breakpoint($bp-mobile) {
          margin-bottom: 7px;
        }
      }

      &:hover {
        box-shadow: 1px 1px 10px 1px $c-light-black;
        scale: 1.05;
      }
    }

    .inactive a {
      opacity: 0.5;

      &:hover {
        box-shadow: none;
        scale: none;
      }
    }

    .day1 a {
      background-image: url("../images/christmas/01.svg");
    }

    .day2 a {
      background-image: url("../images/christmas/02.svg");
    }

    .day3 a {
      background-image: url("../images/christmas/03.svg");
    }

    .day4 a {
      background-image: url("../images/christmas/04.svg");
    }

    .day5 a {
      background-image: url("../images/christmas/05.svg");
    }

    .day6 a {
      background-image: url("../images/christmas/06.svg");
    }

    .day7 a {
      background-image: url("../images/christmas/07.svg");
    }

    .day8 a {
      background-image: url("../images/christmas/08.svg");
    }

    .day9 a {
      background-image: url("../images/christmas/09.svg");
    }

    .day10 a {
      background-image: url("../images/christmas/10.svg");
    }

    .day11 a {
      background-image: url("../images/christmas/11.png");
    }

    .day12 a {
      background-image: url("../images/christmas/12.svg");
    }

    .day13 a {
      background-image: url("../images/christmas/13.svg");
    }

    .day14 a {
      background-image: url("../images/christmas/14.svg");
    }

    .day15 a {
      background-image: url("../images/christmas/15.svg");
    }

    .day16 a {
      background-image: url("../images/christmas/16.svg");
    }

    .day17 a {
      background-image: url("../images/christmas/17.svg");
    }

    .day18 a {
      background-image: url("../images/christmas/18.svg");
    }

    .day19 a {
      background-image: url("../images/christmas/19.svg");
    }

    .day20 a {
      background-image: url("../images/christmas/20.svg");
    }

    .day21 a {
      background-image: url("../images/christmas/21.svg");
    }

    .day22 a {
      background-image: url("../images/christmas/22.svg");
    }

    .day23 a {
      background-image: url("../images/christmas/23.svg");
    }

    .day24 a {
      background-image: url("../images/christmas/24.svg");
      width: 129px;
    }
  }
}
