.rovatslider {
  width: 372px;
  height: 250px;
  border-radius: 0;
  position: relative;

  @include breakpoint($bp-tablet) {
    width: 225px;
    height: 160px;
  }

  .touchslider-viewport {
    overflow: hidden;
    height: 250px;

    @include breakpoint($bp-tablet) {
      height: 160px;
    }
  }

  .touchslider {
    width: 372px;
    height: 250px;

    @include breakpoint($bp-tablet) {
      width: 225px;
      height: 160px;
    }

    &-item {
      height: 250px;
      line-height: 1;

      @include breakpoint($bp-tablet) {
        height: 160px;
      }

      a.image {
        display: block;
        line-height: 1;
        width: 358px;
        height: 200px;
        border: 1px solid $main-purple;
        padding: 6px;

        @include breakpoint($bp-tablet) {
          width: 211px;
          height: 118px;
        }
      }

      .title-wrapper {
        p.title {
          margin: 0.66 * $basic-unit 0 0;

          @include breakpoint($bp-tablet) {
            margin-top: 0.5 * $basic-unit;
          }

          a {
            font-size: 20px;
            text-decoration: none;

            @include breakpoint($bp-tablet) {
              font-size: $basic-unit;
            }
          }
        }
      }
    }
  }

  .arrow-left,
  .arrow-right {
    position: absolute;
    z-index: 50;
    top: 68px;
    display: block;
    background-image: url("../images/arrow_left.svg");
    background-repeat: no-repeat;
    width: 38px;
    height: 38px;
    background-position: -15px 0;
    left: 7px;

    @include breakpoint($bp-tablet) {
      top: 33px;
    }

    &:hover {
      background-position: -15px -94px;
    }
  }

  .arrow-right {
    right: 7px;
    left: unset;
    background-image: url("../images/arrow_right.svg");
  }

  .footer {
    position: absolute;
    bottom: 42px;
    left: 7px;
    z-index: 50;
    font-family: $main-font;
    font-size: $basic-unit;
    font-weight: $regular;
    padding: 0.5 * $basic-unit $basic-unit;
    background: $secondary-purple;
    height: auto;
    line-height: normal;
    width: auto !important;

    @include breakpoint($bp-tablet) {
      padding: 0.33 * $basic-unit 0.5 * $basic-unit;
      bottom: 35px;
    }
  }

  .counter-wrapper {
    position: absolute;
    right: 20px;
    bottom: 47px;

    @include breakpoint($bp-tablet) {
      bottom: 36px;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 0;
        display: inline-block;
        background-image: url("../images/slider_inactive.svg");
        background-position: center;
        text-indent: 10000px;
        background-repeat: no-repeat;
        width: 13px;
        height: 13px;

        &.active {
          background-image: url("../images/slider_active.svg");
        }
      }
    }
  }

  &.sztarok {
    border-color: $c-sztarok;

    .footer {
      background: $c-sztarok;
    }
  }

  &.fashion {
    border-color: $c-fashion;

    .footer {
      background: $c-fashion;
    }
  }

  &.recept {
    border-color: $c-recept;

    .footer {
      background: $c-recept;
    }
  }
}
