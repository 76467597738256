// Styling HTML Elements
*,
*::after {
  box-sizing: content-box;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;

  &.noscroll {
    overflow: hidden;
  }

  @include breakpoint($bp-tablet-below) {
    overflow-x: hidden;
  }
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: $main-font;
  font-style: normal;
  font-weight: $regular;
  color: $c-black;

  &.noscroll {
    overflow: hidden;
  }

  @include breakpoint($bp-tablet-below) {
    overflow-x: hidden;
  }
}

a {
  color: $c-black;
}

h1 {
  font-size: 3 * $basic-unit;
  line-height: 128%;
  font-weight: $regular;

  &#page-title {
    color: $main-purple;
  }

  @include breakpoint($bp-tablet) {
    font-size: 2.66 * $basic-unit;
  }
}

h2 {
  font-size: 2.66 * $basic-unit;
  line-height: 125%;
  font-weight: $regular;

  @include breakpoint($bp-tablet) {
    font-size: 2.33 * $basic-unit;
  }
}

h3 {
  font-size: 1.66 * $basic-unit;
  line-height: 125%;
  font-weight: $regular;

  @include breakpoint($bp-tablet) {
    font-size: 1.5 * $basic-unit;
  }
}

h4 {
  font-size: 1.5 * $basic-unit;
  line-height: 125%;
  font-weight: $regular;

  @include breakpoint($bp-tablet) {
    font-size: 1.33 * $basic-unit;
  }
}

h5 {
  font-size: 1.33 * $basic-unit;
  line-height: 150%;
  font-weight: $regular;

  @include breakpoint($bp-tablet) {
    font-size: 1.25 * $basic-unit;
  }
}

.button {
  @include default-btn();
}

img {
  @include breakpoint($bp-tablet) {
    &:not(.noresize):not(.avcounter) {
      max-width: 100%;
      height: auto !important;
    }
  }

  @include breakpoint($bp-tablet-below) {
    &:not(.noresize):not(.avcounter) {
      max-width: 100%;
      height: auto !important;
    }
  }

  .mobile &:not(.noresize):not(.avcounter) {
    max-width: 100%;
    height: auto !important;
  }
}

table {
  tbody {
    border-top: 0;
  }
}

hr {
  clear: both;
  border: 0;
  height: 2px;
  width: 100%;
  margin-top: 10px;
  background-color: $main-purple;
}
