#pageheader {

  .block.block-koponyeg {

    .header--wrapper.sticky & {
      display: none;
    }

    .weatherinfo {

      font-family: $body-font;
      font-size: 12px;
      line-height: 150%;
      /* identical to box height, or 100% */
      text-align: right;
      color: $secondary-purple;


      .section-inspiralonok & {
        color: $c-inspiralonok;
      }

      .today {
        font-weight: 700;
        color: $main-purple;

        .section-inspiralonok & {
          color: $c-inspiralonok;
        }
      }
    }

    .weatherimage {
      height: 32px;
      width: 32px;
      background-size: contain !important;
      margin-left: 12px;

      &.chance-of-rain {
        background: url('../images/weather/chance-of-rain.png') no-repeat;
      }

      &.chance-of-snow {
        background: url('../images/weather/chance-of-snow.png') no-repeat;
      }

      &.clear {
        background: url('../images/weather/clear.svg') no-repeat;
      }

      &.cloudy {
        background: url('../images/weather/cloudy.svg') no-repeat;
      }

      &.drizzle {
        background: url('../images/weather/drizzle.svg') no-repeat;
      }

      &.falling-snow {
        background: url('../images/weather/falling-snow.png') no-repeat;
      }

      &.fog {
        background: url('../images/weather/fog.svg') no-repeat;
      }

      &.frost {
        background: url('../images/weather/frost.svg') no-repeat;
      }

      &.light-drizzle {
        background: url('../images/weather/light-drizzle.png') no-repeat;
      }

      &.light-rain {
        background: url('../images/weather/light-rain.png') no-repeat;
      }

      &.light-snow {
        background: url('../images/weather/light-snow.svg') no-repeat;
      }

      &.mist {
        background: url('../images/weather/mist.png') no-repeat;
      }

      &.mostly-cloudy {
        background: url('../images/weather/mostly-cloudy.svg') no-repeat;
      }

      /*
      &.night-clear { background: url('../images/weather/night-clear.png') no-repeat; }
      &.night-mist { background: url('../images/weather/night-mist.png') no-repeat; }
      &.night-partly-cloudy { background: url('../images/weather/night-partly-cloudy.png') no-repeat; }
      &.night-rain { background: url('../images/weather/night-rain.png') no-repeat; }
      &.night-scattered-clouds { background: url('../images/weather/night-scattered-clouds.png') no-repeat; }
      &.night-shallow-fog { background: url('../images/weather/night-shallow-fog.png') no-repeat; }
      &.night-snow { background: url('../images/weather/night-snow.png') no-repeat; }
      &.night-storm { background: url('../images/weather/night-storm.png') no-repeat; }
       */
      &.overcast {
        background: url('../images/weather/overcast.svg') no-repeat;
      }

      &.partly-cloudy {
        background: url('../images/weather/partly-cloudy.svg') no-repeat;
      }

      &.rain {
        background: url('../images/weather/rain.svg') no-repeat;
      }

      &.raind-and-wind {
        background: url('../images/weather/raind-and-wind.png') no-repeat;
      }

      &.rainstorm {
        background: url('../images/weather/rainstorm.svg') no-repeat;
      }

      &.rime {
        background: url('../images/weather/rime.png') no-repeat;
      }

      &.scattered-clouds {
        background: url('../images/weather/scattered-clouds.png') no-repeat;
      }

      &.shallow-fog {
        background: url('../images/weather/shallow-fog.png') no-repeat;
      }

      &.shower {
        background: url('../images/weather/shower.svg') no-repeat;
      }

      &.snow-and-rain {
        background: url('../images/weather/snow-and-rain.svg') no-repeat;
      }

      &.snow {
        background: url('../images/weather/snow.svg') no-repeat;
      }

      &.snow-flurry {
        background: url('../images/weather/snow-flurry.svg') no-repeat;
      }

      &.storm {
        background: url('../images/weather/storm.png') no-repeat;
      }

      &.heat-wave {
        background: url('../images/weather/heat-wave.svg') no-repeat;
      }

      &.thunderstorm {
        background: url('../images/weather/thunderstorm.png') no-repeat;
      }

      &.unknown {
        background: url('../images/weather/unknown.png') no-repeat;
      }

      &.wind {
        background: url('../images/weather/wind.svg') no-repeat;
      }
    }

  }

}
