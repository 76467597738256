/* jatek kerdoiv - node/221285 */
.webform-client-form {
  .limit_reached {
    text-align: center;
    padding: 5px 10px;
    background: $c-magenta;
    border: 1px solid $bg-cta-button;
    color: $c-white;
  }

  .webform-component {
    &.webform-component-markup {
      border: 0;
      font-size: 10px;
      line-height: 120%;

      &.form-item {
        margin: 0;
      }
    }
  }

  #edit-actions {
    text-align: center;
  }
}

.webform-component--kerdesek {
  border: 0;
  padding: 0;
  margin-bottom: 25px;

  .webform-component {
    .resizable-textarea,
    .form-textarea-wrapper {
      resize: none;
      width: 90%;
    }

    .form-textarea-wrapper textarea {
      width: 100%;
      resize: none;
    }

    .textfield-wrapper {
      display: block;
    }

    label {
      display: block;
      background: $brown;
      color: $c-white;
      padding: 8px $basic-unit;
      border-top-left-radius: 3px;
      -webkit-border-top-left-radius: 3px;
      -moz-border-radius-topleft: 3px;
      border-top-right-radius: 3px;
      -webkit-border-top-right-radius: 3px;
      -moz-border-radius-topright: 3px;
      width: calc(100% - 24px);
    }

    .form-radios label {
      background: none !important;
      display: inline;
      color: $c-black;
      padding: 0;
      border-radius: 0;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
    }

    label.fb-like-box-label {
      background-color: $bg-cta-button;
      line-height: 24px;
      padding: 10px;
    }
  }

  .form-required {
    display: none;
  }

  .webform-component-radios .description {
    text-align: center;
  }
}

.webform-component--adatlap {
  .form-required {
    display: inline;
  }

  .webform-component {
    &.webform-component--adatlap--telepulesnev label,
    &.-adatlap--emelet-ajto.webform-component label {
      width: auto;
    }

    .form-text {
      border: 1px solid $tags-bg;
    }
  }

  .webform-component--adatlap--jatek-feltetelei label {
    width: 400px;
  }
}

.webform-component--adatlap--clear {
  margin-bottom: 0 !important;
  height: 1px !important;

  p {
    line-height: 0;
    margin: 0;
  }
}

.webform-container-inline div,
.webform-container-inline.form-item {
  display: inline;
  margin: 0;
}

/* input-text mezok */
.form-text.error,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid $c-red !important;
}

.webform-component .form-radios .form-item label {
  cursor: pointer;
}

.webform-component--reszveteli-feltetelek .break,
.webform-component--reszveteli-feltetelek .readless {
  padding-left: 6px;
  color: $c-magenta;
  text-decoration: underline;
  cursor: pointer;
}

.section-jatek {
  .webform-component--adatlap {
    .webform-component.webform-component--adatlap--honnan-ertesult {
      float: none;
      clear: both;

      label {
        width: auto;
      }

      .form-checkboxes .form-item {
        float: left;
        margin: 10px 10px 10px 0;
      }

      .form-item {
        margin: 0;
      }

      .form-checkboxes {
        margin: 0 0 0 144px;
      }

      span.input-checkbox {
        margin-right: 6px;
      }
    }
  }
}

