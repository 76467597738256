.rteleft {
  text-align: left;
}

.rtejustify {
  text-align: left;
}

.rtecenter {
  text-align: center;
}

.rteright {
  text-align: right;
}

.image-caption-container {
  line-height: 0;
  height: auto !important;

  .image-caption {
    line-height: 165%;
    text-align: center;
    color: $c-caption;
    //height: 0;
    margin-bottom: $basic-unit;
    font-size: 16px;

    @include breakpoint($bp-tablet) {
      max-width: 100% !important;
    }

    @include breakpoint($bp-tablet-below) {
      max-width: 100% !important;
    }

    .mobile & {
      max-width: 100% !important;
    }
  }

  @include breakpoint($bp-tablet) {
    max-width: 100% !important;
  }

  @include breakpoint($bp-tablet-below) {
    max-width: 100% !important;
  }

  .mobile & {
    max-width: 100% !important;
  }
}

.taxonomy-page:not(.page-tags) h1#page-title {
  display: none;
}

.footnote {
  font-size: 11px;
  line-height: 150%;
}

/* Cikk közi keretes szöveg */
.keretes,
.cikkvegi {
  display: inline-block;
  max-width: 270px;
  background-color: $c-keretes;
  padding: 15px 15px 0;

  .section-sztarok & {
    background-color: $c-sztarok;
  }

  .section-fashion-beauty & {
    background-color: $c-fashion;
  }

  .section-egeszseg & {
    background-color: $c-egeszseg;
  }

  .section-lifestyle & {
    background-color: $c-lifestyle;
  }

  .section-utazas & {
    background-color: $c-utazas;
  }

  .section-bed-love & {
    background-color: $c-bed-love;
  }

  .section-home-design & {
    background-color: $c-home-design;
  }

  .section-eskuvo & {
    background-color: $c-eskuvo;
  }

  .section-recept & {
    background-color: $c-recept;
  }

  .section-christmas & {
    background-color: $c-christmas;
  }

  &.align-left {
    float: left;
    margin: 5px 20px 0 0;
  }

  &.align-center {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    padding: 15px 15px 5px;
  }

  &.align-right {
    float: right;
    margin: 5px 0 0 20px;
  }

  h2.keretes-title,
  h2.cikkvegi-title {
    color: $main-purple !important;
    margin: 0 0 15px !important;

    a {
      color: $main-purple;
      text-decoration: none !important;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .keretes-content,
  .cikkvegi-content {
    font-size: 16px;
  }
}

// Hide cikkvegi to move video player upper.
.cikkvegi,
.cikkvegi.align-center {
  display: none;
}

.node-content {
  img.left {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  img.right {
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;
  }
}

img[align="left"] {
  margin-right: $basic-unit;
}

img[align="right"] {
  margin-left: $basic-unit;
}

.node-type-page {
  h2 {
    color: $main-purple;
  }
}

// Mediaajanlo.
.page-mediaajanlo,
.page-femcafehu-mediaajanlo-2022,
.page-femcafehu-mediaajanlo-2023 {
  &.mobile {
    table:not(#mediaajanlo_prices) {
      td {
        width: 100%;
        display: block;
      }
    }
  }
}

.mediaajanlo_prices-wrapper {
  .mobile & {
    overflow-x: auto;
  }
}

#mediaajanlo_prices {
  thead tr {
    background-color: $secondary-purple;
    color: $c-white;
  }

  tbody tr.highlighted {
    background-color: $c-recept;
  }

  td,
  th {
    padding: 5px;
    vertical-align: middle;
    border: 1px solid $grey;
    font-size: 16px;
  }
}

table.designed {
  background: $c-black;
  border-collapse: separate;
  border-spacing: 1px;
  width: 100%;

  td {
    padding: 8px 5px;
    background: $c-white;
    vertical-align: middle;
  }

  p {
    margin: 0;
  }

  thead th {
    background: $secondary-purple;
    color: $c-white;
    padding: 8px 5px;
  }

  tr:nth-child(even) td,
  tr.grey td {
    background: $grey;
  }

  tr.szumma td {
    background: $tags-bg;
    font-weight: bold;
    border-top: 1px double $c-black;
  }
}

table.standard {
  width: 100%;
  border-collapse: separate;

  th {
    background: $darkgrey;
    color: $c-white;
    padding: 2px 4px;
  }

  td {
    padding: 2px 4px;
    border-bottom: 1px solid $grey;
  }
}

body.section-galeria .field-name-field-pictures,
#a-cikkhez-tartozo-galeria,
#social-galeria {
  .field-label {
    font-size: 20px;
    line-height: 150%;
  }

  .field-items {
    @include flex-wrap;
    justify-content: center;

    .field-item {
      width: 140px;
      height: 140px;
      padding: 2px;
      text-align: center;
      background: $tags-bg;

      a {
        display: inline-block;
        height: 140px;
        vertical-align: middle;
        line-height: 140px;
      }

      img {
        max-width: 134px !important;
        max-height: 134px;
        vertical-align: middle;
      }
    }
  }
}

