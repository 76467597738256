.view-display-id-block_1.view-tesco-box {
  .view-header {
    background-color: $tesco-bg;
    color: $c-white;
    font-size: 30px;
    font-family: $body-font;
    height: 76px;
    padding: 0;
    text-align: center;
  }

  .views-field-title {
    a {
      text-decoration: none;
      font-size: 24px;

      &:hover {
        box-shadow: inset 0 -12px $tesco-bg;
        transition: 0.30s ease;
      }
    }
  }

  .view-footer {
    background-color: $tesco-bg;
    color: $c-white;
    font-size: 30px;
    font-family: $body-font;
    padding: $basic-unit / 2 $basic-unit 0 $basic-unit;
    text-align: center;

    .tesco_logo {
      background-image: url("../szponzoracio/tesco_logo_box.png");
      background-repeat: no-repeat;
      height: 38px;
      width: 118px;
      text-indent: -10000px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
