// Define variables here
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 360px,
  md: 579px,
  lg: 768px,
  xl: 1200px,
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  md: 579px,
  lg: 736px,
  xl: 1200px,
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;

// Printing
$print-page-size: a3 !default;
$print-body-min-width: map-get($grid-breakpoints, 'lg') !default;

//fonts
$main-font:      'DM Serif Display', serif;
$body-font:      'DM Sans', sans-serif;
$light:           300;
$regular:         400;
$semi-bold:       500;
$bold:            700;
$x-bold:          900;
$basic-unit:      12px;
