.blog-list-page {
  h1.title {
    display: none;
  }
}

.page-blog {

  #content-content {
    .content {
      @include flex-wrap;
    }

    .blog-item-wrapper {
      max-width: 374px;
      margin: 10px 0;

      &.odd {
        margin-right: 2 * $basic-unit;

        .mobile & {
          margin-right: 0;
        }

        @include breakpoint($bp-tablet-below) {
          margin-right: 0;
        }
      }

      .image-wrapper {
        position: relative;
        margin-bottom: $basic-unit / 2;
      }

      picture,
      .default_picture a > img {
        border: 1px solid $main-purple;
        padding: 8px;
        display: block;
        line-height: 0;
        max-width: calc(100% - 16px);

        &:hover {
          border-width: 9px;
          padding: 0;
        }
      }

      .default_picture a > img {
        height: auto;
      }

      picture {
        img {
          max-width: 100%;
          height: auto;
        }
      }

      .category_title {
        position: absolute;
        padding: 0;
        left: 9px;
        bottom: 9px;

        a {
          display: block;
          background-color: $c-lifestyle;
          font-size: 18px;
          line-height: 18px;
          color: $main-purple;
          text-transform: uppercase;
          padding: 8px;
          text-decoration: none;

          &:hover {
            text-decoration: none;
            color: $c-black;
          }
        }

      }

      .title {
        font-size: 22px;
        line-height: 22px;
        margin: 5px 0 10px;
        height: auto;

        a {
          text-decoration: none;
          line-height: 125%;

          &:hover {
            box-shadow: inset 0 -12px $c-horoscope;
            transition: 0.30s ease;
          }
        }
      }

      .default_picture {
        &.mobile-image {
          display: none;

          .mobile & {
            display: block;

            img {
              width: 100%;
              height: auto;
            }
          }

          @include breakpoint($bp-tablet-below) {
            display: block;

            img {
              width: 100%;
              height: auto;
            }
          }
        }

        .mobile &.desktop-image {
          display: none;
        }

        @include breakpoint($bp-tablet-below) {
          &.desktop-image {
            display: none;
          }
        }
      }

      &.royal_clinics {
        .category_title a {
          background-color: $c-royal-clinics;
          color: $c-white;
        }

        picture,
        .default_picture a > img {
          border-color: $c-royal-clinics;
        }
      }

      &.femanyu {
        .category_title a {
          background-color: $c-femanyu;
          color: $c-white;
        }

        picture,
        .default_picture a > img {
          border-color: $c-femanyu;
        }
      }

      &.highlife {
        .category_title a {
          background-color: $c-highlife;
          color: $c-white;
        }

        picture,
        .default_picture a > img {
          border-color: $c-highlife;
        }
      }
    }
  }
}

.blog-listpage {
  .views-row {
    .views-field-title {
      a {
        font-size: 28px;
        line-height: 30px;
        min-height: 34px;
        clear: both;
        float: none;
        width: auto;
        //padding-left: 110px;
        //background: url("/sites/all/themes/femcafe/images/blog.novagyok.png") 0 center no-repeat;
        height: auto;
        display: block;
        text-decoration: none;
        margin-bottom: 20px;
      }
    }

    .views-field-php {
      .meta.top {
        height: 40px;
        background-color: $grey;
        color: $c-black;
        padding: 6px 8px;

        .mobile & {
          display: none;
        }
      }

      .meta.bottom {
        margin-top: 24px;

        .mobile & {
          display: none;
        }
      }
    }

    .views-field-body {
      font-family: $body-font;
      font-weight: $regular;
      line-height: 125%;
    }

    .views-field-field-default-picture {
      margin-bottom: 2 * $basic-unit;
      text-align: center;

      img {
        @include breakpoint($bp-tablet) {
          width: 100%;
          height: auto;
        }

        @include breakpoint($bp-tablet-below) {
          width: 100%;
          height: auto;
        }
      }
    }

    .readmore {
      margin-left: auto;

      a {
        @include default-btn;
        font-family: $main-font;
        padding: $basic-unit / 2 $basic-unit;
      }
    }
  }
}
/*
.page-blog .blog-item-wrapper .category_title.purple a {  background-color: #8a5ecb; }
.page-blog .blog-item-wrapper .category_title.pink a {  background-color: #cc69a4; }
.page-blog .blog-item-wrapper .category_title.mustard a {  background-color: #C9AD7F; }
.page-blog .blog-item-wrapper .category_title.goldenbrown a {  background-color: #95843E; }
.page-blog .blog-item-wrapper .category_title.blue a {  background-color: #48d4dc; }
.page-blog .blog-item-wrapper .category_title.orange a {  background-color: #EC5C26; }
*/
