/* Over 18 */
#content-content .node.adult.blocked .node-content,
#content-content .node.adult.opened #over_18_layer {
  display: none;
}

#over_18_layer {
  width: 100%;
  height: auto;
  min-height: 310px;
  display: none;

  .mobile & {
    margin: 10px auto;
    clear: both;
  }

  .inner_wrapper {
    font-family: $body-font;
    font-size: 20px;
    width: calc(100% - 100px);
    margin: 0 auto;
    border: 3px solid $c-red;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    padding: 50px;
    background: $c-white;

    .mobile & {
      padding: 15px;
      width: calc(100% - 30px);
    }
  }

  .karika,
  .over_18_notice {
    text-align: center;
    margin-bottom: 50px;

    .mobile & {
      margin-bottom: 30px;
    }

    a {
      color: $c-red;
    }
  }

  .over_18_buttons {
    text-align: center;

    a {
      display: inline-block;
      background: $main-purple;
      color: $c-white;
      padding: 10px;
      text-align: center;
      width: 240px;
      margin-right: 30px;
      text-decoration: none;

      &.underage {
        background: $c-red;
        margin-right: 0;

        @include breakpoint($bp-tablet) {
          margin: 0 auto;
        }

        @include breakpoint($bp-tablet-below) {
          margin: 0 auto;
        }

        .mobile & {
          margin: 0 auto;
        }
      }

      @include breakpoint($bp-tablet) {
        display: block;
        margin: 0 auto 15px;
      }

      @include breakpoint($bp-tablet-below) {
        display: block;
        margin: 0 auto 15px;
      }

      .mobile & {
        display: block;
        margin: 0 auto 15px;
      }
    }
  }
}
