.node {
  .terms {
    &.tags {
      .item-list {
        li {
          padding: 0;

          a {
            display: inline-block;
            background: $tags-bg;
            padding: 0.5 * $basic-unit $basic-unit;
            margin: 0 $basic-unit $basic-unit 0;
            text-decoration: none;

            &:hover {
              color: $main-purple;
            }

          }
        }
      }
    }
  }
}

.pre_cikk_image {
  margin: 20px auto 10px;
  text-align: center;

  img {
    .mobile & {
      width: 100%;
      height: auto;
    }

    @include breakpoint($bp-tablet-below) {
      width: 100%;
      height: auto;
    }
  }
}

.node-content {
  .field-name-body {
    font-family: $body-font;
    font-size: 1.66 * $basic-unit;
    line-height: 160%;

    @include breakpoint($bp-tablet) {
      font-size: 1.5 * $basic-unit;
    }

    img {
      max-width: 100%;
      height: auto;

      @include breakpoint($bp-tablet) {
        max-width: 100% !important;
        height: auto !important;
      }

      @include breakpoint($bp-tablet-below) {
        max-width: 100% !important;
        height: auto !important;
      }

      .mobile & {
        max-width: 100% !important;
        height: auto !important;
      }
    }

    p::selection {
      background-color: $secondary-purple;
      color: $c-white;
    }

    h2 {
      font-size: 2.66 * $basic-unit;

      @include breakpoint($bp-tablet) {
        font-size: 2.33 * $basic-unit;
      }
    }

    a {
      text-decoration: underline;
      color: $main-purple;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.node-links {
  clear: both;
}

.meta {
  @include flex-container;
  align-items: center;
  font-family: $body-font;
  font-size: $basic-unit;

  &.bottom {
    margin-bottom: 2 * $basic-unit;
  }

  .category {
    margin-right: 2 * $basic-unit;
    font-family: $main-font;
  }

  .submitted {
    margin-right: 2 * $basic-unit;
  }

  .shares {
    margin-left: auto;
  }
}


.section-sztarok {
  .node-content h2 {
    color: $c-sztarok;
  }

  .field-name-body p::selection {
    background-color: $c-sztarok;
  }
}

.section-fashion-beauty {
  .node-content h2 {
    color: $c-fashion;
  }

  .field-name-body p::selection {
    background-color: $c-fashion;
  }
}

.section-egeszseg {
  .node-content h2 {
    color: $c-egeszseg;
  }

  .field-name-body p::selection {
    background-color: $c-egeszseg;
  }
}

.section-lifestyle {
  .node-content h2 {
    color: $c-lifestyle;
  }

  .field-name-body p::selection {
    background-color: $c-lifestyle;
  }
}

.section-utazas {
  .node-content h2 {
    color: $c-utazas;
  }

  .field-name-body p::selection {
    background-color: $c-utazas;
  }
}

.section-bed-love {
  .node-content h2 {
    color: $c-bed-love;
  }

  .field-name-body p::selection {
    background-color: $c-bed-love;
  }
}

.section-home-design {
  .node-content h2 {
    color: $c-home-design;
  }

  .field-name-body p::selection {
    background-color: $c-home-design;
  }
}

.section-eskuvo {
  .node-content h2 {
    color: $c-eskuvo;
  }

  .field-name-body p::selection {
    background-color: $c-eskuvo;
  }
}

.section-recept {
  .node-content h2 {
    color: $c-recept;
  }

  .field-name-body p::selection {
    background-color: $c-recept;
  }
}

.section-christmas {
  .node-content h2 {
    color: $c-christmas;
  }

  .field-name-body p::selection {
    background-color: $c-christmas;
  }
}
