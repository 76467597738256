.webform-client-form.webform-quiz {
  .webform-progressbar-quiz {
    background-color: $secondary-purple;
    padding: $basic-unit / 2 $basic-unit;
    width: calc(100% - 24px);
  }

  .webform-component-radios {
    label {
      font-family: $body-font;
      font-weight: $regular;
      margin-bottom: $basic-unit;
    }
  }

  .form-actions {
    input.form-submit[value="Következő oldal"],
    input.form-submit[value="Indulhat a kvíz"] {
      background-image: url("../images/arrow_right_pager.svg");
      background-position: calc(100% - 12px) center;
      background-repeat: no-repeat;
      padding-right: 3 * $basic-unit;
    }
  }
}

.section-kviz {
  .quiz-answer {
    border: 1px solid $main-purple;
    background: $tags-bg;
    padding: 15px;
    font-family: $body-font;
    font-weight: $regular;

    p.result {
      text-align: center;
      font-size: 26px !important;

      .score {
        font-weight: bold;
        font-size: 42px;
        color: $c-magenta;
      }
    }
  }
}
