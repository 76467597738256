#footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4 * $basic-unit;

  .footer-logo {
    margin: 0;
    display: block;
    background: url("../femcafe_logo_white.svg") no-repeat;
    width: 267px;
    height: 64px;
  }

  ul.social-icons {
    margin: 2 * $basic-unit 0;
  }

  ul.menu {
    li {
      padding: 0 $basic-unit;
      margin: 0;

      .mobile & {
        padding: 0 10px;
      }

      a {
        font-family: $body-font;
        font-size: $basic-unit;
      }

      &.menu-hidden {
        display: none !important;
      }
    }
  }

  // Copyright.
  #block-block-6 {
    color: $c-white;
    font-family: $body-font;
    font-size: $basic-unit;
    text-align: center;
  }
}
