.block-app-core-logos {
  position: relative;
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  .stop-scroll & {
    margin-top: 0;
  }

  .social-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    a {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-right: 16px;



      .region-footer & {
        margin-right: 20px;

        @include breakpoint($bp-mobile) {
          margin-right: 15px;
        }
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .logo-wrapper {
    a {
      display: block;
      margin: 5px;
      line-height: 0;

      &:hover {
        opacity: 0.6;
      }
    }

    img {
      @include breakpoint($bp-mobile) {
        width: 100px;
      }
    }
  }
}
