.view-display-id-block_1.view-dyson-box {
  .view-header {
    background-color: $c-black;
    color: $c-white;
    font-size: 30px;
    font-family: $body-font;
    height: 76px;
    padding: 0;
    text-align: center;
  }

  .views-field-title {
    margin-top: 0 !important;

    a {
      text-decoration: none;
      font-size: 18px;

      @include breakpoint($bp-tablet) {
        margin-right: 15px;
      }

      &:hover {
        box-shadow: inset 0 -10px $content-box-brd2;
        transition: 0.30s ease;
      }
    }
  }

  .view-footer {
    background-color: $c-white;
    border-top: 1px solid $c-black;
    max-height: 70px;
    padding: 0;
    text-align: center;

    a {
      width: auto;
      height: auto;
    }

    .dyson_logo {
      background-image: url("../szponzoracio/dyson_logo_180x73.png");
      background-repeat: no-repeat;
      background-size: contain;
      height: 73px;
      width: 180px;
      text-indent: -10000px;
      overflow: hidden;
      display: inline-block;
    }
  }
}
