header {

  .node-type-page & {
    background: $c-lifestyle;
  }

  .section-sztarok & {
    background: $c-sztarok;
  }

  .section-fashion-beauty & {
    background: $c-fashion;
  }

  .section-egeszseg & {
    background: $c-egeszseg;
  }

  .section-lifestyle & {
    background: $c-lifestyle;
  }

  .section-utazas & {
    background: $c-utazas;
  }

  .section-bed-love & {
    background: $c-bed-love;
  }

  .section-home-design & {
    background: $c-home-design;
  }

  .section-eskuvo & {
    background: $c-eskuvo;
  }

  .section-recept & {
    background: $c-recept;
  }

  .section-christmas & {
    background: $c-christmas;
  }

  .page-horoszkop & {
    background: $c-horoscope;
  }
}

#pageheader {
  @include flex-wrap;
  align-items: center;
  padding-top: 2 * $basic-unit;

  .mobile & {
    padding-bottom: $basic-unit;
  }

  @include breakpoint($bp-tablet-below) {
    padding-bottom: $basic-unit;
  }

  .header--wrapper.sticky & {
    padding: $basic-unit 0;
    align-items: center;
  }

  #logo-title {
    width: 17%;

    @include breakpoint($bp-tablet) {
      width: 27%;
    }

    @include breakpoint($bp-tablet-below) {
      width: 33%;
    }
  }

  #logo {
    margin: 0;
    display: block;
    background: url("../femcafe_logo.svg") no-repeat;
    background-size: contain;
    width: 175px;
    height: 42px;

    .section-inspiralonok .header--wrapper:not(.sticky) & {
      background: url("../femcafe_logo_gold.svg") no-repeat;
    }
  }

  #header-region {
    width: 70%;
    margin-left: auto;

    .hide-menu & {
      margin: auto;
    }

    @include breakpoint($bp-tablet) {
      width: 40%;
    }

    @include breakpoint($bp-tablet-below) {
      display: none;
    }

    .header--wrapper.sticky & {
      margin-left: unset;
      flex: 1 1 50%;
    }

    .region-header {
      justify-content: flex-end;

      .hide-menu & {
        justify-content: center;
      }

      .header--wrapper.sticky & {
        justify-content: center;
      }

      .block {
        border-left: 1px solid $secondary-purple;
        height: 34px;
        padding-left: 12px;

        &:not(:last-of-type) {
          padding-right: 12px;
        }

        @include breakpoint($bp-tablet) {
          border: 0;
        }
      }
    }
  }
}

.header--wrapper {
  &.sticky {
    @include drop-shadow;
    position: fixed;
    top: 0;
    background: $c-white;
    max-width: 100vw;
    z-index: 99;

    .admin-menu & {
      top: 30px;
    }

    .logo-title {
      flex: 1 1 33%;
    }
  }
}
