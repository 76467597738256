.pager-show-more {
  .pager-show-more-next {
    a {
      @include default-btn();

      .mobile & {
        font-size: 1.67 * $basic-unit !important;
        padding: 20px 40px 20px 28px !important;
      }

      @include breakpoint($bp-tablet-below) {
        font-size: 1.67 * $basic-unit !important;
        padding: 20px 40px 20px 28px !important;
      }
    }
  }
}
