.node-content ul.pager,
.node-content ul.mobile-pager {
  height: 25px;
  line-height: 100%;
  padding: 10px 0 !important;
  //border-radius: 2px;
  margin: 30px 0;
  text-align: center;
  //display: flex;
  align-items: center;
}

.item-list ul.pager,
.item-list ul.mobile-pager {
  li {
    padding: 0 $basic-unit;
    margin: 0;
    display: inline-block;
    font-size: 28px;
    color: $main-purple;

    a {
      text-decoration: none !important;
      color: $secondary-purple;

      &.active {
        display: inline-block;
      }
    }

    &.pager-last,
    &.pager-first {
      display: none;
    }

    &.last a,
    &.first a {
      display: inline-block;
      width: auto;
      font-size: 16px;
      background-color: $main-purple;
      background-repeat: no-repeat;
      color: $c-white;
      margin-top: -15px;

      &:hover {
        background-color: $secondary-purple;
        color: $c-white;
      }
    }

    &.first {
      margin-right: auto;
      padding: 0;
      float: left;

      a {
        background-image: url("../images/arrow_left_pager.svg");
        background-position: 12px center;
        padding: 16px 24px 16px 36px;
      }

      &.pager-current {
        float: none;
      }
    }

    &.last:not(.mobile-pager-previous) {
      //margin-left: auto;
      padding: 0;
      float: right;

      a {
        background-image: url("../images/arrow_right_pager.svg");
        background-position: calc(100% - 12px) center;
        padding: 16px 36px 16px 24px;
      }

      &.pager-current {
        float: none;
      }
    }

    &.pager-show-more-next.last.first {
      float: none;
    }

    &.pager-current {
      padding: 0 $basic-unit;
    }
  }
}
