.page-horoszkop {
  h1#page-title {
    background: url("../images/logo_horoszkop.png") no-repeat;
    padding-left: 65px;
    padding-top: 10px;
    min-height: 70px;
  }
}

.horoscope-boxes-wrapper {
  @include flex-wrap;

  @include breakpoint($bp-tablet-below) {
    flex-direction: column;
  }

  .horoscope-block-wrapper {
    max-width: 374px;
    margin-bottom: 2 * $basic-unit;
    position: relative;

    @include breakpoint($bp-tablet) {
      max-width: 225px;
      margin-bottom: 1.33 * $basic-unit;
    }

    @include breakpoint($bp-tablet-below) {
      max-width: 100%;
    }

    &.odd {
      margin-right: 2 * $basic-unit;

      @include breakpoint($bp-tablet) {
        margin-right: 15px;
      }

      @include breakpoint($bp-tablet-below) {
        margin-right: 0;
      }
    }

    .image_wrapper {
      position: relative;
    }

    .category {
      position: absolute;
      padding: $basic-unit / 2 $basic-unit;
      left: 9px;
      bottom: 9px;
      background: $c-horoscope;

      @include breakpoint($bp-tablet) {
        padding: $basic-unit / 3 $basic-unit / 2;
      }

      @include breakpoint($bp-tablet-below) {
        padding: $basic-unit / 3 $basic-unit / 2;
      }

      span {
        font-size: $basic-unit;
        color: $c-black;
      }
    }

    picture,
    .image_wrapper > a > img {
      border: 1px solid $c-horoscope;
      padding: 8px;
      display: block;
      line-height: 0;
      max-width: calc(100% - 16px);
      height: auto;

      @include breakpoint($bp-tablet) {
        margin-bottom: $basic-unit;
      }

      &:hover {
        border-width: 9px;
        padding: 0;
      }
    }

    picture {

      img {
        max-width: 100%;
        height: auto;
      }

      @include breakpoint($bp-tablet-below) {
        max-width: 100%;
        height: auto;
      }
    }

    & > a > img {
      @include breakpoint($bp-tablet-below) {
        max-width: calc(100% - 16px);
        height: auto;
      }
    }

    .views-field-title,
    .views-field-field-listpage-title {
      margin-top: 1.5 * $basic-unit;

      a {
        text-decoration: none;
        color: $c-black;
        position: relative;
        font-size: 20px;
        line-height: 125%;

        @include breakpoint($bp-tablet) {
          font-size: 16px;
        }

        &:hover {
          box-shadow: inset 0 -12px $c-horoscope;
          transition: 0.30s ease;
        }
      }

    }
  }
}

#block-views-horoszkop-block-ajanlo h2 {
  border-top: 2px solid $main-purple;
  color: $main-purple;
  margin-top: 0;
  padding-top: 20px;
}

.view-horoszkop {
  .layout-2 & {
    .views-row {
      @include flex-wrap;

      @include breakpoint($bp-tablet) {
        align-items: center;
      }

      @include breakpoint($bp-tablet-below) {
        align-items: center;
      }

      .image_wrapper {
        width: 278px;
        margin-right: 32px;
        position: relative;

        picture,
        & > a > img {
          border: 1px solid $c-lifestyle;
          padding: 8px;
          display: block;
          line-height: 0;
          max-width: calc(100% - 16px);

          &:hover {
            border-width: 9px;
            padding: 0;
          }
        }

        @include breakpoint($bp-tablet) {
          width: 225px;
          margin-right: 15px;

          img {
            max-height: 175px !important;
          }
        }

        @include breakpoint($bp-tablet-below) {
          width: 50%;
          margin-right: 15px;

          img {
            max-height: unset;
            height: auto;
          }
        }
      }

      .category {
        position: absolute;
        padding: $basic-unit / 2 $basic-unit;
        left: 9px;
        top: 195px;
        background: $c-lifestyle;

        @include breakpoint($bp-tablet) {
          padding: $basic-unit / 3 $basic-unit / 2;
        }

        @include breakpoint($bp-tablet-below) {
          padding: $basic-unit / 3 $basic-unit / 2;
        }

        span {
          font-size: $basic-unit;
          color: $c-black;
        }

        @include breakpoint($bp-tablet) {
          top: 154px;
        }
      }

      .title-body {
        font-family: $body-font;
        font-size: 20px;
        line-height: 160%;
        margin-left: auto;
        width: calc(100% - 310px);

        @include breakpoint($bp-tablet) {
          width: calc(100% - 240px);

          .views-field-body {
            display: none;
          }
        }

        @include breakpoint($bp-tablet-below) {
          margin-left: 0;
          width: calc(50% - 15px);
        }

        a {
          font-family: $main-font;
          font-size: 32px;
          line-height: 125%;
          color: $c-black;
          text-decoration: none;

          @include breakpoint($bp-tablet) {
            font-size: 20px;

            &:hover {
              box-shadow: inset 0 -10px $c-lifestyle;
            }
          }

          @include breakpoint($bp-tablet-below) {
            font-size: 16px;
          }

          &:hover {
            box-shadow: inset 0 -18px $c-lifestyle;
            transition: 0.30s ease;
          }
        }

        .views-field-body {

          .mobile & {
            display: none;
          }

          @include breakpoint($bp-tablet-below) {
            display: none;
          }
        }
      }
    }
  }
}

.section-horoszkop {
  .node-content {

    hr {
      background-color: unset;
    }

    .field-name-body {
      table {
        width: auto !important;
        border: 1px solid $c-horoscope;

        @include breakpoint($bp-tablet) {
          max-width: 180px !important;
        }

        .mobile & {
          max-width: 150px !important;
        }

        @include breakpoint($bp-tablet-below) {
          max-width: 150px !important;
        }

        &[align="left"] {
          margin-right: $basic-unit;
        }

        tbody {
          border: 0;
        }

        td {
          text-align: center;
          padding: 8px;

          img {
            display: block;
          }
        }
      }

      .horoscope_info {
        text-align: center;
      }
    }
  }
}

#block-spike-horoszkop-cikk-fblike-box {
  text-align: center;
}
