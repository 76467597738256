.blog-listpage.blog-dogblog {
  margin-top: 20px;

  .views-row {
    .views-field-title {
      a {
        background-image: url("../images/logo_dogblog.svg");
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 75px;
        line-height: 40px;
        min-height: 66px;
        background-size: 75px 75px;
      }
    }
  }

  .view-footer {
    text-align: center;
    margin: 2 * $basic-unit 0;
  }
}

.section-dogblog {
  /*
  #dogblog_pre_cikk_image {
    margin: 20px auto 10px;
    text-align: center;

    img {
      .mobile & {
        width: 100%;
        height: auto;
      }

      @include breakpoint($bp-tablet-below) {
        width: 100%;
        height: auto;
      }
    }
  }
   */

  &.desktop #content-content .node h1 {
    background-image: url("../images/logo_dogblog.svg");
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 75px;
    background-size: 75px 75px;
  }
}

