.section-inspiralonok {
  background: url("../images/bg_inspiralonok.png");

  &.page-inspiralonok-cikkek {
    #pagewrapper {
      @include breakpoint($bp-tablet-below) {
        margin-bottom: 0;
        padding-bottom: 2 * $basic-unit;
        background: $c-white;
      }
    }

    &.mobile {
      #pagewrapper {
        margin-bottom: 0;
        padding-bottom: 2 * $basic-unit;
        background: $c-white;
      }
    }

    #header-region {
      #block-spike-nameday {
        max-width: none;
      }
    }
  }

  .main--wrapper,
  .main--container {
    max-width: 100% !important;
  }

  #container #main {
    width: 100% !important;
    float: none !important;
    padding-bottom: 20px;
  }

  h1#page-title {
    margin-top: 0;
    padding-top: 25px;
  }

  &.taxonomy-page {
    h1#page-title {
      display: block;
    }
  }
}

/*
.page-inspiralonok {
  #page {
    @include breakpoint($bp-desktop-inspiralonok) {
      min-width: 1020px;
      max-width: 1200px;
    }
  }
}
*/

.page-inspiralonok,
.page-inspiralonok-jeloltek {
  h1#page-title {
    display: none;
  }
}

.page-inspiralonok,
.page-inspiralonok-jeloltek,
.page-inspiralonok-szavazas {

  .inspiralonok-header {
    text-align: center;

    .intro {
      color: $c-inspiralonok;
      margin: 2 * $basic-unit auto;
      width: 80%;
      font-size: 1.5 * $basic-unit;
      font-family: $body-font;
      font-weight: $regular;
      line-height: 133%;

      a {
        color: $c-white;
        text-decoration: none;
      }
    }
  }

  .inspiralonok-sponsors {
    @include flex-wrap;
    align-items: center;
    justify-content: center;
    margin: 2 * $basic-unit 0;

    img {
      margin: $basic-unit;
    }
  }
}

// Main page.
.page-inspiralonok {
  .inspiralonok-boxes-wrapper {
    @include flex-wrap;
    justify-content: center;
  }

  .inspiralonok-block-wrapper {
    width: 500px;
    margin: 10px 0;

    @include breakpoint($bp-tablet-below) {
      width: auto;
    }

    @include breakpoint($bp-tablet) {
      width: auto;
    }

    .mobile & {
      width: 100%;
      float: none;
    }

    .inspiralo-nok-item {
      width: 450px;
      margin: 10px 0 0;
      background: $c-inspiralonok;
      padding: $basic-unit;


      @include breakpoint($bp-tablet-below) {
        width: calc(100% - 24px);
      }

      .mobile & {
        width: calc(100% - 24px);
      }
    }

    &.even {
      .inspiralo-nok-item {
        margin-left: auto;
      }
    }

    .views-field-field-default-picture {
      padding-bottom: 0;

      img {

        @include breakpoint($bp-tablet-below) {
          max-width: 100%;
          height: auto;
        }

        .mobile & {
          max-width: 100%;
          height: auto;
        }
      }
    }

    .views-field-title {
      overflow: visible;
      text-align: center;
      margin: $basic-unit 0;

      a {
        font-size: 1.67 * $basic-unit;
        line-height: 125%;
        color: $c-in-purple;
        text-decoration: none;

        &:hover {
          color: $c-black;
        }
      }
    }
  }
}

.main-page-inspiralonok-cikkek {
  @include flex-wrap;
  justify-content: center;

  .inspiralonok-article-wrapper {
    //background: $c-white;
    max-width: 1000px;

    @include breakpoint($bp-tablet) {
      max-width: 465px;
    }

    @include breakpoint($bp-tablet-below) {
      max-width: 100%;
    }

    &.hidden {
      display: none !important;
    }
  }
}

.page-inspiralonok-cikkek {
  #page {
    background: $c-white;

    h1.title {
      text-align: center;
    }
  }

  .inspiralonok-article-wrapper {
    max-width: 772px;

    @include breakpoint($bp-tablet) {
      max-width: 465px;
    }

    @include breakpoint($bp-tablet-below) {
      max-width: 100%;
    }

    &.hidden {
      display: none !important;
    }
  }
}

// Article listing.
.view-inspiralonok:not(.view-display-id-page) {
  .view-content {
    @include flex-wrap;
    justify-content: center;

    .views-row {
      max-width: 100%;
      margin: 0 0 2 * $basic-unit 0;

      @include breakpoint($bp-md-desktop) {
        max-width: 374px;
      }

      &.views-row-odd {
        margin-right: 0;

        @include breakpoint($bp-md-desktop) {
          margin-right: 2 * $basic-unit;
        }
      }

      .image_wrapper {
        position: relative;

        & > a > img,
        picture {
          border: 1px solid $c-lifestyle;
          display: block;
          line-height: 0;
          max-width: calc(100% - 16px);
          padding: 8px;
          width: 100%;
          height: auto;

          img {
            width: 100%;
            height: auto;
          }

          &:hover {
            border-width: 9px;
            padding: 0;
          }
        }

        .category {
          position: absolute;
          padding: $basic-unit / 2 $basic-unit;
          left: 9px;
          bottom: 9px;
          background: $c-lifestyle;
          max-height: 22px;

          @include breakpoint($bp-tablet) {
            padding: $basic-unit / 3 $basic-unit / 2;
          }

          @include breakpoint($bp-tablet-below) {
            padding: $basic-unit / 3 $basic-unit / 2;
          }

          span {
            font-size: $basic-unit;
            color: $c-black;
          }
        }
      }

      .views-field-title {
        margin-top: 1.3 * $basic-unit;
        margin-bottom: $basic-unit;

        @include breakpoint($bp-tablet) {
          margin-top: $basic-unit;
        }

        a {
          font-size: 20px;
          line-height: 125%;
          color: $c-black;
          text-decoration: none;

          @include breakpoint($bp-tablet) {
            font-size: 16px;
          }

          &:hover {
            box-shadow: inset 0 -10px $secondary-purple;
            transition: 0.30s ease;
          }
        }
      }

      .views-field-body {
        font-family: $body-font;
        font-size: 16px;
        line-height: 160%;
        color: $c-black;
      }

      &.rovat_sztarok {
        .image_wrapper {
          & > a > img,
          picture {
            border-color: $c-sztarok;
          }

          .category {
            background: $c-sztarok;
          }
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -10px $c-sztarok;
        }
      }

      &.rovat_fashion-beauty {
        .image_wrapper {
          & > a > img,
          picture {
            border-color: $c-fashion;
          }

          .category {
            background: $c-fashion;
          }
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -10px $c-fashion;
        }
      }

      &.rovat_egeszseg {
        .image_wrapper {
          & > a > img,
          picture {
            border-color: $c-egeszseg;
          }

          .category {
            background: $c-egeszseg;
          }
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -10px $c-egeszseg;
        }
      }

      &.rovat_lifestyle {
        .image_wrapper {
          & > a > img,
          picture {
            border-color: $c-lifestyle;
          }

          .category {
            background: $c-lifestyle;
          }
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -10px $c-lifestyle;
        }
      }

      &.rovat_utazas {
        .image_wrapper {
          & > a > img,
          picture {
            border-color: $c-utazas;
          }

          .category {
            background: $c-utazas;
          }
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -10px $c-utazas;
        }
      }

      &.rovat_home-design {
        .image_wrapper {
          & > a > img,
          picture {
            border-color: $c-home-design;
          }

          .category {
            background: $c-home-design;
          }
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -10px $c-home-design;
        }
      }

      &.rovat_eskuvo {
        .image_wrapper {
          & > a > img,
          picture {
            border-color: $c-eskuvo;
          }

          .category {
            background: $c-eskuvo;
          }
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -10px $c-eskuvo;
        }
      }
    }
  }

  // Articles on main page.
  &.view-display-id-block_3,
  &.view-display-id-block_4 {
    .view-header h2 {
      text-align: center;
      color: $c-inspiralonok;
      font-family: $body-font;
      text-transform: uppercase;
    }

    .views-row {
      @include breakpoint($bp-md-desktop) {
        width: 500px;
        max-width: 500px;
        margin: 10px 0 !important;
      }

      &.views-row-even {
        .article-wrapper {
          margin-left: auto;
        }
      }

      .article-wrapper {
        padding: $basic-unit;
        background: $c-inspiralonok;
        width: 450px;

        @include breakpoint($bp-mobile-inspiralonok-main) {
          width: auto;
        }
      }

      .image_wrapper {
        picture {
          border: 0;
          padding: 0;
          max-width: 100%;
        }
      }
    }

    .view-footer {
      text-align: right;
      padding: $basic-unit;

      a {
        display: inline-block;
        text-decoration: none !important;
        width: fit-content;
        font-size: 16px;
        background-color: $main-purple;
        background-repeat: no-repeat;
        color: $c-white;
        background-image: url("../images/arrow_right_pager.svg");
        background-position: calc(100% - 12px) center;
        padding: 16px 36px 16px 24px;

        &:hover {
          background-color: $secondary-purple;
          color: $c-white;
        }
      }
    }
  }

  &.layout-2 .view-content {
    .views-row {
      @include flex-wrap;
      width: 100%;
      max-width: unset;
      margin-right: unset !important;

      @include breakpoint($bp-tablet) {
        align-items: center;
      }

      @include breakpoint($bp-tablet-below) {
        align-items: center;
      }

      .image_wrapper {
        margin-right: 32px;
        width: 278px;

        @include breakpoint($bp-tablet) {
          width: 225px;
          margin-right: 15px;

          img {
            max-height: 175px !important;
          }
        }

        @include breakpoint($bp-tablet-below) {
          width: 50%;
          margin-right: 15px;

          img {
            max-height: unset;
            height: auto;
          }
        }
      }

      .category {
        top: 195px;
        bottom: unset;

        @include breakpoint($bp-tablet) {
          top: 154px;
        }
      }

      .title-body {
        font-family: $body-font;
        font-size: 20px;
        line-height: 160%;
        margin-left: auto;
        width: calc(100% - 310px);

        @include breakpoint($bp-tablet) {
          width: calc(100% - 240px);

          .views-field-body {
            display: none;
          }
        }

        @include breakpoint($bp-tablet-below) {
          margin-left: 0;
          width: calc(50% - 15px);
        }

        .views-field-body {
          .mobile & {
            display: none;
          }

          @include breakpoint($bp-tablet-below) {
            display: none;
          }
        }

        a {
          font-family: $main-font;
          font-size: 32px;
          line-height: 125%;
          color: $c-black;
          text-decoration: none;

          &:hover {
            box-shadow: inset 0 -18px $secondary-purple;
            transition: 0.30s ease;
          }

          @include breakpoint($bp-tablet) {
            font-size: 20px;

            &:hover {
              box-shadow: inset 0 -10px $secondary-purple;
            }
          }

          @include breakpoint($bp-tablet-below) {
            font-size: 16px;
          }
        }
      }

      &.rovat_sztarok {
        .title-body a:hover {
          box-shadow: inset 0 -18px $c-sztarok;

          @include breakpoint($bp-tablet) {
            box-shadow: inset 0 -12px $c-sztarok;
          }
        }
      }

      &.rovat_fashion-beauty {
        .title-body a:hover {
          box-shadow: inset 0 -18px $c-fashion;

          @include breakpoint($bp-tablet) {
            box-shadow: inset 0 -12px $c-fashion;
          }
        }
      }

      &.rovat_egeszseg {
        .title-body a:hover {
          box-shadow: inset 0 -18px $c-egeszseg;

          @include breakpoint($bp-tablet) {
            box-shadow: inset 0 -12px $c-egeszseg;
          }
        }
      }

      &.rovat_lifestyle {
        .title-body a:hover {
          box-shadow: inset 0 -18px $c-lifestyle;

          @include breakpoint($bp-tablet) {
            box-shadow: inset 0 -12px $c-lifestyle;
          }
        }
      }

      &.rovat_utazas {
        .title-body a:hover {
          box-shadow: inset 0 -18px $c-utazas;

          @include breakpoint($bp-tablet) {
            box-shadow: inset 0 -12px $c-utazas;
          }
        }
      }

      &.rovat_home-design {
        .title-body a:hover {
          box-shadow: inset 0 -18px $c-home-design;

          @include breakpoint($bp-tablet) {
            box-shadow: inset 0 -12px $c-home-design;
          }
        }
      }
    }
  }
}


.inspiralo-nok-banner {
  background: url("../images/inspiralonok_banner.jpg") no-repeat;
  width: 971px;
  height: 256px;
  display: block;
  margin: 0 auto;

  .mobile & {
    width: 100%;
    //min-height: 300px;
    background: url("../images/inspiralonok_banner_mobile.jpg") no-repeat;
    background-size: contain;
    background-position: top center;
  }

  @include breakpoint($bp-tablet-below) {
    width: 100%;
    //min-height: 300px;
    background: url("../images/inspiralonok_banner_mobile.jpg") no-repeat;
    background-size: contain;
    background-position: top center;
  }
  @include breakpoint($bp-tablet) {
    width: 100%;
    //min-height: 300px;
    background: url("../images/inspiralonok_banner_mobile.jpg") no-repeat;
    background-size: contain;
    background-position: top center;
  }
}

.vote-inspiralo-nok {
  @include default-btn;
  position: absolute;
  left: calc(50% + 230px);
  top: 80px;
  background: $c-sztarok;

  .mobile & {
    left: calc(50% - 75px);
    top: 150px;
  }

  @include breakpoint($bp-tablet-below) {
    left: calc(50% - 75px);
    top: 150px;
  }

  @include breakpoint($bp-tablet) {
    left: calc(50% - 75px);
    top: 150px;
  }
}

// Static pages.
.node-inspiralonok-static {
  background: $c-white;
  padding: 2 * $basic-unit $basic-unit;

  .meta.bottom {
    display: none;
  }
}

// Nominee pages.
.node-inspiralonok-nominee {
  background: $c-white;
  padding: 2 * $basic-unit $basic-unit;

  h1 {
    margin: 0 0 2 * $basic-unit 0;
  }

  #kep {
    float: right;
    margin-left: 30px;
    margin-bottom: 10px;

    .mobile & {
      float: none;
      margin-left: 0;
      margin-top: 10px;
    }
  }

  #body {
    margin-bottom: 25px;
  }

  .meta.top {
    background: $grey;
    padding: 6px;
    margin-bottom: $basic-unit;

    .nominee_category {
      width: auto;
      font-size: 20px;
    }
  }

  .form-wrapper {
    text-align: center;
    float: left;
    width: 33%;
    margin-bottom: 15px;
  }
}

.nominee-pager {
  float: left;
  width: 33%;

  a {
    @include default-btn;
    font-family: $main-font;
    font-size: 16px;

    .mobile & {
      padding: $basic-unit;
    }
  }

  &.next {
    float: right;
    width: auto;

    a {
      text-align: left;
      background-position: right -33px;
    }
  }
}

.rules-notification,
.notification {
  font-size: 14px;
  text-align: center;
  margin: 30px 0 10px;
  color: $c-white;
  font-family: $body-font;

  a {
    text-decoration: underline;
    color: $c-red;
  }
}

// Nominees list page.
.view-inspiralonok.view-display-id-page {
  padding-top: 30px;

  h1.listpage-title {
    text-align: center;
    font-size: 28px;
    font-weight: $regular;
    font-family: $body-font;
    padding-top: 20px;
    margin-bottom: 30px;

    .mobile & {
      font-size: 26px;
    }
  }

  .view-content {

    .nominee-wrapper {
      @include flex-wrap;
      justify-content: space-evenly;
      background: $c-inspiralonok;

      @include breakpoint($bp-tablet-below) {
        flex-direction: column;
        align-items: center;
        margin-top: -1px;
      }
    }

    .views-row {
      padding-bottom: $basic-unit;
      width: 33%;
      text-align: center;

      @include breakpoint($bp-tablet-below) {
        width: 100%;
      }
    }

    h3 {
      flex-basis: 100%;
      font-size: 28px;
      font-weight: $regular;
      font-family: $main-font;
      background: $c-inspiralonok;
      padding: 10px 0 20px;
      text-align: center;
      color: $c-in-purple;
      margin-bottom: 0;

      @include breakpoint($bp-tablet) {
        font-size: 24px;
      }

      .mobile & {
        font-size: 22px;
      }
    }

    .views-field-title {
      font-size: 18px;
      text-align: center;
      margin-bottom: 0;
    }

    .views-field-field-default-picture {
      padding-bottom: 0;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .mobile-view {
      display: none;

      .mobile & {
        display: block;
        text-align: center;

        &.views-field-field-default-picture img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    .mobile & .desktop-view {
      display: none;
    }

    .views-field-field-epic-rating {
      text-align: center;
      font-size: 16px;
    }

    &::after {
      clear: both;
      content: " ";
      display: table-cell;
      height: 0;
      line-height: 0;
    }
  }
}

.page-inspiralonok-szavazas {
  h1#page-title {
    color: $c-inspiralonok;
    text-align: center;
  }
}

#inspiralonok-vote-page-form {
  .form-required {
    display: none;
  }

  .form-item {
    background: $c-inspiralonok;
  }

  .form-type-radios {
    & > label {
      width: calc(100% - 10px);
      background: $c-inspiralonok;
      color: $c-in-purple;
      font-size: 28px;
      padding: 5px;
      font-weight: $regular;
      text-align: center;
    }

    .form-radios {
      /*background: #dac9f2;*/
      margin: 0 0 35px;
      padding: 20px 10px 10px;
      border-top: 0;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      @include breakpoint($bp-tablet-below) {
        flex-wrap: wrap;
      }

      .mobile & {
        flex-wrap: wrap;
      }

      &::after {
        clear: both;
        content: "";
        display: table-cell;
      }

      .form-item {
        margin-bottom: $basic-unit;
        width: 33%;
        display: flex;
        flex-direction: column;
        height: auto;

        label {
          display: block;
        }

        img {
          max-width: 100%;
          height: auto;
        }

        @include breakpoint($bp-tablet-below) {
          text-align: center;
          margin: 0 0 20px;
          width: 100%;
        }

        .mobile & {
          text-align: center;
          margin: 0 0 20px;
          width: 100%;
        }

        &:last-of-type {
          margin-right: 0;
        }

        .option .name,
        .option .epic {
          text-align: center;
          font-size: 16px;
        }

        .option .name a {
          font-weight: bold;
          font-size: 18px;
        }

        span.input-radio {
          order: 2;
          width: 100%;
          margin: $basic-unit auto;
          /* disable checkbox after closing votes...
          display: none;
          */

          &.on {
            background-color: $in-toggle-on;

            &::before {
              background-color: $in-button-on;
            }
          }
        }
      }
    }
  }

  .form-action {
    text-align: center;

    .form-submit {
      font-size: 22px;
      padding: 12px 18px;
    }
  }
}

#inspiralonok-vote-form .form-submit {
  font-size: 22px;
  padding: 12px 18px;
  margin-top: -8px;

  @include breakpoint($bp-tablet-below) {
    margin-top: 0;
    font-size: 16px;
    padding: 7px 10px;
  }

  .mobile & {
    margin-top: 0;
    font-size: 16px;
    padding: 7px 10px;
  }
}

h3.nominee-category {
  width: calc(100% - 10px);
  background: $main-purple;
  color: $c-white;
  padding: 5px;
  margin-bottom: 10px;
}

.innominees-category-wrapper {
  @include flex-container;
  border: $main-purple;
  clear: both;
  justify-content: space-evenly;

  @include breakpoint($bp-tablet-below) {
    flex-direction: column;
  }

  .mobile & {
    flex-direction: column;
  }

  .innominees-wrapper {
    margin: 0 20px 20px 0;
    text-align: center;
    background: $c-white;
    width: 33%;
    padding: $basic-unit 0;

    @include breakpoint($bp-tablet-below) {
      max-width: 398px;
      width: 100%;
      margin: 10px auto;
    }

    .mobile & {
      max-width: 398px;
      margin: 10px auto;
    }

    &:last-child {
      margin-right: 0;

      @include breakpoint($bp-tablet-below) {
        margin-right: auto;
      }

      .mobile & {
        margin-right: auto;
      }
    }
  }
}

#inspiralonok_mobile_banner {
  text-align: center;
}

.inspiralonok_sticky_wrapper {
  position: fixed;
  top: 200px;
  margin-left: -75px;
  z-index: 100;

  &.mobile-view {
    display: none;
  }

  .page-inspiralonok &,
  .page-inspiralonok-szavazas & {
    display: none;
  }

  .sticky-menu-on & {
    margin-left: calc(50% - 675px);

    @include breakpoint($bp-tablet) {
      margin-left: calc(50% - 443px);
    }
  }

  @include breakpoint($bp-tablet-below) {
    & {
      display: none;
    }

    &.mobile-view {
      display: block;
      top: 36px;
      position: absolute;
      margin-left: 50%;

      .inspiralonok_sticky {
        width: 48px;
        height: 48px;
      }

      .sticky-menu-on & {
        margin-left: 50%;
        top: 36px;
        position: fixed;
      }
    }
  }

  .mobile & {
    display: none;
  }

  .mobile &.mobile-view {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-left: unset;
    top: unset;
  }

  .inspiralonok_sticky {
    display: block;
    width: 60px;
    height: 60px;
    background-image: url("../images/icon_inspiralonok.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

// Menu button
.front #pageheader #header-region,
.taxonomy-page #pageheader #header-region,
.page-blog #pageheader #header-region {
  @include breakpoint($bp-md-desktop) {
    width: 83%;
  }
}

.page-node- #pageheader #header-region {
  @include breakpoint($bp-md-desktop) {
    width: calc(83% - 70px);
  }
}

#block-spike-ad-in-gala-menubutton {
  height: auto !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 75px 0 0;

  @include breakpoint($bp-desktop-below) {
    display: none;
  }

  .section-inspiralonok .header--wrapper:not(.sticky) & {
    display: none;
  }

  a#inspiralo-nok-gala-button {
    background-image: url("../szponzoracio/IN_fejlec_gomb_ex.png");
    background-repeat: no-repeat;
    background-size: 200px 44px;
    display: block;
    height: 44px;
    width: 200px;
    font-size: 0;
    font-family: $main-font;
    text-decoration: none;
    padding-top: 15px;
  }
}

// Wallpaper sponsorship
.desktop.wallpaper-skin:not(.section-social-gallery):not(.section-20-perc-oszinten) {
  /*
  #pageheader {
    position: fixed;
  }
  */

  &:not(.taxonomy-page) header {
    padding-bottom: $basic-unit;
  }

  #header-banner {
    background: none;
    border: 0;
    width: 980px;

    .region-header-banner > .block {
      width: 980px;
      height: 250px;
      display: block;
      cursor: pointer;
      border: 0;
    }
  }

  #navigation {
    margin-bottom: 0;
  }
  /*
  #navigation {
    background: none !important;
    margin: 0 auto 0;
    position: relative;
    width: 980px;
    height: 90px;

    #primary {
      padding: 0 0 20px;
    }

    #secondary {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  */

  #rovat_layout .pane-spike-ad-bb1-zona,
  #block-spike-ad-bb1-zona {
    width: 374px;
    height: 600px;
    background-color: $c-in-sponsor;
    background-position: 130px 0;
    background-attachment: fixed;
    background-repeat: no-repeat;
    display: block;
    cursor: pointer;
    visibility: visible;
  }

  #block-spike-ad-roadblock,
  .pane-block.pane-spike-ad-roadblock {
    display: block;
    background-color: $c-in-sponsor;
    background-position: 130px 0;
    background-attachment: fixed;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 360px;
    width: 772px;
    visibility: visible;
    margin: 0 0 20px;
  }

  #full-width-ad {
    display: block;
    width: 100%;
    height: 600px;
    cursor: pointer;
  }

  #footer-wrapper {
    margin-top: 0;
  }

  #pagewrapper {
    cursor: pointer;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;

    #page {
      cursor: default;
      background: $c-white;
      padding-top: 15px;
    }

    &.skinned {
      background-color: $c-in-sponsor;
      background-repeat: no-repeat;
      clear: both;
    }

    &.fixed {
      background-attachment: fixed;
    }

    &.scrolled {
      background-attachment: scroll;
    }

    &.bottom {
      background-position: center 100%;
    }

    &.top {
      background-position: center 70px;
    }

    &.zero {
      background-position: center 0;
    }
  }

  #container {
    margin-top: -1px;
  }

  &.in-wallpaper #rovat_layout .pane-spike-ad-bb1-zona,
  &.in-wallpaper #block-spike-ad-bb1-zona,
  &.in-wallpaper #block-spike-ad-roadblock,
  &.in-wallpaper .pane-block.pane-spike-ad-roadblock,
  &.in-wallpaper #pagewrapper.skinned {
    background-image: url("../szponzoracio/in_2023_rovat_desktop_1520x830_v3.png");
  }
}


/* MOBILE */
/*
.mobile .view-inspiralonok .view-content .desktop-view { display: none; }
.mobile .view-inspiralonok .view-content .mobile-view { display: block; text-align: center; }
.mobile .view-inspiralonok h1.listpage-title { font-size: 26px; }
.mobile .view-inspiralonok .view-content h3 {
  font-size: 22px;
  background: #604776;
  color: #fff;
  padding: 4px 2px;
  text-align: center;
}
.mobile .view-inspiralonok .view-content .mobile-view.views-field-field-default-picture img { max-width: 100%; height: auto; }
.mobile .node-inspiralonok-nominee #kep { float: none; margin-left: 0; margin-top: 10px; }
.mobile #navigation #primary .links > li.menu-2287 a,
.mobile #navigation #primary .links > li.menu-2289 a,
.mobile #navigation #primary .links > li.menu-2280 a,
.mobile #navigation #primary .links > li.menu-2281 a,
.mobile #navigation #primary .links > li.menu-2363 a,
.mobile #navigation #primary .links > li.menu-2288 a,
.mobile #navigation #primary .links > li.menu-2290 a,
.mobile #navigation #primary .links > li.menu-2291 a { background-position: 8px 7px !important; color: #f5d186 !important; background-image: url(../images/bg_menubutton_inspiralonok.png); background-size: 25px 25px; }
*/

/* SKIN */
/*
.desktop.section-inspiralonok #header-banner {
  display: none;
  background: none;
  border: 0;
  max-width: 980px;
  height: 250px;
}
.desktop.section-inspiralonok #header-banner .region-header-banner > .block {
  margin: -5px auto 0;
  width: 980px;
  height: 0;
  display: block;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #navigation,
.desktop.section-inspiralonok #navigation {
  background: none !important;
  margin: 0 auto;
  position: relative;
  width: 980px;
  height: 90px;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #navigation #primary,
.desktop.section-inspiralonok #navigation #primary {
  padding: 0 0 20px;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #navigation #secondary,
.desktop.section-inspiralonok #navigation #secondary {
  padding-left: 10px;
  padding-right: 10px;
}
*/

/* Fix on horoszkop page for IN skin */
/*
.page-aktualis-horoszkop #container, .section-horoszkop:not(.node-type-story) #container {background-color: #fff !important; }
.desktop.front #navigation #secondary { background-color: #68c7bd; }
.desktop:not(.section-inspiralonok) #navigation #secondary { background-color: #68c7bd; }
.desktop.section-sztarok #navigation #secondary { background-color: #e4478a; }
.desktop.section-lifestyle #navigation #secondary { background-color: #cc69a4; }
.desktop.section-fashion-beauty #navigation #secondary { background-color: #8a5ecb; }
.desktop.section-egeszseg #navigation #secondary { background-color: #8ad575; }
.desktop.section-recept #navigation #secondary { background-color: #f98a00; }
.desktop.section-bed-love #navigation #secondary { background-color: #e23200; }
.desktop.section-utazas #navigation #secondary { background-color: #48d4dc; }
.desktop.section-home-design #navigation #secondary { background-color: #d3bb99; }
.desktop.section-uzlet-luxus #navigation #secondary { background-color: #5f5f5f; }

body.desktop:not(.section-lifestyle):not(.section-sztarok) #pagewrapper,
.desktop.section-inspiralonok #pagewrapper {
  cursor: pointer;
  padding: 0;
  margin-top: 0;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #pagewrapper #page,
.desktop.section-inspiralonok #pagewrapper #page {
  cursor: default;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #container,
.desktop.section-inspiralonok #container {
  margin-top: -1px;
  padding-bottom: 1px;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #pagewrapper.skinned,
.desktop.section-inspiralonok #pagewrapper.skinned {
  background: #614776 url('../szponzoracio/IN_2020_skin.jpg') no-repeat;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #pagewrapper.scrolled,
.desktop.section-inspiralonok #pagewrapper.scrolled {
  background-attachment: scroll;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #pagewrapper.fixed,
.desktop.section-inspiralonok #pagewrapper.fixed {
  background-attachment: fixed;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #pagewrapper.top,
.desktop.section-inspiralonok #pagewrapper.top {
  background-position: center 70px;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #pagewrapper.bottom,
.desktop.section-inspiralonok #pagewrapper.bottom {
  background-position: center 100%;
}
body.admin-menu.desktop:not(.section-lifestyle):not(.section-sztarok) #pagewrapper.top,
.admin-menu.desktop.section-inspiralonok #pagewrapper.top {
  background-position: center 90px;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #pagewrapper.zero,
.desktop.section-inspiralonok #pagewrapper.zero {
  background-position: center 0;
}
body.desktop:not(.section-lifestyle):not(.section-sztarok) #footer-wrapper,
.desktop.section-inspiralonok #footer-wrapper {
  margin-top: 0;
}
*/
