.section-social-gallery {

  header {
    width: 100%;
    height: 75px;
  }

  #main {
    max-width: 772px;
  }

  #pageheader #logo {
    margin-top: 5px;
    width: 180px;
    height: 36px;
    background-size: 180px 36px;
  }

  #pagewrapper {
    margin: 0;
    background: none;
    padding: 0 !important;
  }

  h1 {
    display: none;
  }

  .image-wrapper {
    margin-top: 20px;
    line-height: 0;
    text-align: center;
    min-height: 120px;

    #recommended_wrapper {
      text-align: left;

      .title_wrapper {
        margin-top: 0;
      }
    }

    .embed-container {
      iframe.youtube {
        width: 560px;
        height: 360px;

        .mobile & {
          width: 100%;
          height: initial;
        }

        @include breakpoint($bp-tablet-below) {
          width: 100%;
          height: initial;
        }
      }
    }

    .fb-post {
      display: block !important;
    }

    .tw-post .twitter-tweet {
      //display: none;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }

    iframe {
      margin: 0 auto !important;
    }

    img.bigpic,
    img.external {
      max-width: 100%;
    }
  }

  #container #sidebar-right {
    top: 40px;
    min-height: 250px;
    //max-height: 100vh;

    &.sticky {
      position: sticky;
    }
  }

  #block-spike-toplista-sidebar {
    margin-bottom: 2 * $basic-unit;
  }
}

.node-content {
  .image-wrapper {

    position: relative;
    text-align: center;
    min-height: 200px;

    iframe {
      margin: 0 auto !important;
    }

    .sgimage_overflow_wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      display: block;
    }

    .sgimage_overflow {
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: $c-black;
    }

    .sgimage_title {
      color: $c-white;
      padding: 50px 20px;
      position: absolute;
      top: 0;
      width: calc(100% - 40px);
      text-align: center;

      @include breakpoint($bp-tablet-below) {
        font-size: 14px;
        line-height: 160%;
      }

      .mobile & {
        font-size: 14px;
        line-height: 160%;
      }

    }

    .sgimage_cta_button {
      position: absolute;
      top: 50%;
      left: calc(50% - 120px);
      font-family: $main-font;
      font-size: 16px;
      padding: 1.3 * $basic-unit 2 * $basic-unit;
      color: $c-white;
      background: $bg-cta-button;
      border-radius: 3px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
    }

    &:hover .sgimage_cta_button {
      background: $bg-cta-button_over;
    }

    .sgimage_link {
      position: absolute;
      z-index: 10;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
    }
  }
}

#embed-gallery {
  margin: $basic-unit auto;
  width: 100%;
  text-align: center;

  .field-item {
    display: inline-block;
    width: 69px;
    height: 69px;
    padding: 2px;
    background: $tags-bg;
    text-align: center;
    line-height: 0;
    margin: 0 $basic-unit / 2 $basic-unit / 2;

    a {
      display: inline-block;
      height: 69px;
      vertical-align: middle;
      line-height: 65px;
    }

    img {
      max-width: 65px !important;
      max-height: 65px;
      vertical-align: middle;
      line-height: normal;
      font-size: $basic-unit;
    }
  }
}


#social-gallery-wrapper {
  position: relative;

  .imagecaption {
    padding: 10px;
    text-align: center;
    /*margin-bottom: 20px;*/
    background: $c-lifestyle;
    font-family: $body-font;

    .section-sztarok & {
      background: $c-sztarok;
    }

    .section-fashion-beauty & {
      background: $c-fashion;
    }

    .section-egeszseg & {
      background: $c-egeszseg;
    }

    .section-lifestyle & {
      background: $c-lifestyle;
    }

    .section-utazas & {
      background: $c-utazas;
    }

    .section-bed-love & {
      background: $c-bed-love;
    }

    .section-home-design & {
      background: $c-home-design;
    }

    .section-eskuvo & {
      background: $c-eskuvo;
    }

    .section-recept & {
      background: $c-recept;
    }

    .section-christmas & {
      background: $c-christmas;
    }

    a {
      color: $main-purple;
    }

    &.empty {
      background: none;
      padding: 0;
    }
  }

  #social-gallery-node-title {
    margin: 5px 0;
    display: none;

    /*
    a {
      color: #e4478a;
      text-decoration: underline;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      resize: horizontal;
    }
     */
  }

  .social-gallery-head {
    margin: 2 * $basic-unit;

    .desktop & {
      @include flex-container;
    }

    .social-gallery-share {
      margin-right: auto;

      .mobile & {
        margin-bottom: $basic-unit;
      }
    }

    #close-gallery {
      text-align: right;
      margin-left: auto;

      a {
        text-decoration: none;
        font-family: $body-font;
        color: $main-purple;

        &:hover {
          color: $c-black;
        }
      }
    }
  }

  #social-gallery-current-item {
    position: relative;
  }

  #gallery-counter {
    height: 30px;
    text-align: left;
    width: 20%;
    padding: 8px 0 0 10px;
    font-size: 15px;

    .current {
      font-weight: bold;
    }
  }

  .photo_source {
    padding: 5px 0;
    text-align: right;
    font-size: 10px;
    width: 80%;
  }

  .photo {
    text-align: right;
    height: 30px;
    padding: 8px 10px 0 0;
    font-size: 15px;
    width: 100%;
  }

  #social-gallery-items {
    width: 100%;
    clear: both;
    text-align: center;
    margin: 2 * $basic-unit 0;
    overflow-x: auto;

    ul {
      @include flex-wrap;
      justify-content: center;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 7px 7px 0;
        width: 88px;
        height: 88px;
        padding: 2px;
        background: $tags-bg;
        text-align: center;
        line-height: 0;

        &.active {
          border: 1px solid $main-purple;
        }

        &.gallery-item a {
          display: inline-block;
          line-height: 84px;

          img {
            vertical-align: middle;
          }
        }
      }
    }
  }

  .arrow-left,
  .arrow-right {
    position: absolute;
    z-index: 45;
    margin-left: 0;
    top: 150px;
    margin-top: -25px;
    background-image: url("../images/arrow_left_big.svg");
    background-repeat: no-repeat;
    width: 56px;
    height: 56px;
    background-position: -15px 0;
    display: block;

    @include breakpoint($bp-tablet-below) {
      margin-left: 5px;
    }

    .mobile & {
      background-position: -15px -94px;
      margin-left: 0;
    }

    &:hover {
      background-position: -15px -94px;
    }
  }

  .arrow-right {
    right: 0;
    background-image: url("../images/arrow_right_big.svg");

    @include breakpoint($bp-tablet-below) {
      right: 4px;
    }

    .mobile & {
      right: 0;
    }

  }
}
/*
  .szponzoralt #social-gallery-wrapper .arrow-left {
    margin-left: 0;
  }
  .szponzoralt #social-gallery-wrapper .arrow-right {
    right: 0;
  }
  @media only screen and (max-width: 1200px){
    .desktop.section-social-gallery #social-gallery-items {
      width: 620px;
      margin-left: 0;
    }
    .desktop.section-social-gallery #container #sidebar-right {
      right: 10px;
    }
  }
  @media only screen and (max-width: 1035px) {
    .desktop.section-social-gallery #container #main {
      width: 560px !important;
    }
    #social-gallery-wrapper .arrow-left { margin-left: 0; left: 0; background-position: 0 0; }
    #social-gallery-wrapper .arrow-left:hover { background-position: 0 -50px; }
    #social-gallery-wrapper .arrow-right { right: 0; background-position: 0 0; }
    #social-gallery-wrapper .arrow-right:hover { background-position: 0 -50px; }
    .desktop.section-social-gallery.one-sidebar #social-gallery-items {
      width: 560px;
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 912px) {
    .desktop.section-social-gallery #container #main {
      width: 420px !important;
    }
    .desktop.section-social-gallery.one-sidebar #social-gallery-items {
      width: 420px;
    }
    .desktop.section-social-gallery #share {
      margin-left: 50px;
    }
    .desktop.section-social-gallery #close-gallery {
      margin-left: 355px;
    }
  }
  @media only screen and (max-width: 776px) {
    .desktop.section-social-gallery #container #main {
      width: 350px !important;
      margin-left: 10px !important;
    }
    .desktop.section-social-gallery.one-sidebar #social-gallery-items {
      width: 350px;
    }
  }
  @media only screen and (max-width: 700px) {
    .desktop.section-social-gallery #container #sidebar-right { display: none; }
  }
*/
