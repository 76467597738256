.pane-spike-toplista-sidebar,
#block-spike-toplista-sidebar {

  .pane-title,
  h2 {
    border-top: 1.4px solid $main-purple;
    font-size: 2 * $basic-unit;
    color: $main-purple;
    padding-top: $basic-unit;
    font-weight: $regular;

    @include breakpoint($bp-tablet) {
      font-size: 1.5 * $basic-unit;
    }
  }

  .views-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: $basic-unit / 2;

    &.rovat_sztarok {
      /*
      .views-field-counter span {
        color: $c-sztarok;
      }
      */

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-sztarok;
        transition: 0.30s ease;
      }
    }

    &.rovat_fashion-beauty {
      /*
      .views-field-counter span {
        color: $c-fashion;
      }
      */

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-fashion;
        transition: 0.30s ease;
      }
    }

    &.rovat_egeszseg {
      /*
      .views-field-counter span {
        color: $c-egeszseg;
      }
      */

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-egeszseg;
        transition: 0.30s ease;
      }
    }

    &.rovat_lifestyle {
      /*
      .views-field-counter span {
        color: $c-lifestyle;
      }
      */

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-lifestyle;
        transition: 0.30s ease;
      }
    }

    &.rovat_utazas {
      /*
      .views-field-counter span {
        color: $c-utazas;
      }
      */

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-utazas;
        transition: 0.30s ease;
      }
    }

    &.rovat_bed-love {
      /*
      .views-field-counter span {
        color: $c-bed-love;
      }
      */

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-bed-love;
        transition: 0.30s ease;
      }
    }

    &.rovat_home-design {
      /*
      .views-field-counter span {
        color: $c-home-design;
      }
      */

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-home-design;
        transition: 0.30s ease;
      }
    }

    &.rovat_eskuvo {
      /*
      .views-field-counter span {
        color: $c-eskuvo;
      }
      */

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-eskuvo;
        transition: 0.30s ease;
      }
    }

    &.rovat_recept {
      /*
      .views-field-counter span {
        color: $c-recept;
      }
      */

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-recept;
        transition: 0.30s ease;
      }
    }

    &.rovat_christmas {
      /*
      .views-field-counter span {
        color: $c-christmas;
      }
      */

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-christmas;
        transition: 0.30s ease;
      }
    }

    &.rovat_meteospasmyl {
      /*
      .views-field-counter span {
        color: $c-meteospasmyl;
      }
      */

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-meteospasmyl;
        transition: 0.30s ease;
      }
    }
  }

  .views-field-counter {
    width: 4 * $basic-unit;
    text-align: center;
    margin-right: 2 * $basic-unit;

    span {
      font-size: 8.5 * $basic-unit;
      line-height: 70%;
      color: $main-purple;
    }
  }

  .views-field-title {
    flex: 1;
    margin-left: auto;

    a {
      font-size: 1.5 * $basic-unit;
      text-decoration: none;
      line-height: 125%;

      @include breakpoint($bp-tablet) {
        font-size: 1.33 * $basic-unit;
      }

      &:hover {
        box-shadow: inset 0 -10px $secondary-purple;
        transition: 0.30s ease;
      }
    }
  }

  .views-field-daycount,
  .views-field-totalcount {
    flex-basis: 100%;
    color: $grey;
  }
}
