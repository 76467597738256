.section-jatek {

  .view-jatekok {
    .view-display-id-attachment_1 {
      font-weight: $regular;
      font-family: $body-font;
    }
  }

  .layout-2 {
    overflow: hidden;
  }

  .block-views {
    clear: both;
  }

  .layout-3 .views-field-field-default-picture {
    height: auto;
  }

  .webform-component--kerdesek {
    .webform-component form.radios {
      padding-top: 30px;
    }

    .element-outer > .form-item > label {
      line-height: 24px;
      background-color: $main-purple;
      padding: 10px;
      color: $c-white;
      border-radius: 2px;
      font-weight: normal;
    }
  }

  .webform-component--adatlap {
    background: $webform-bg;
    padding: 20px 0;
    border: 0;
    border-bottom: 1px solid $main-purple;

    .fieldset-wrapper {
      padding: 0 20px;
    }

    & > legend {
      height: 40px;
      background: $main-purple url('../images/icon_webform_adatlap_header.png') 10px center no-repeat;
      border-radius: 2px;
      line-height: 40px;
      padding-left: 50px;
      color: $c-white;
      font-size: 30px;
      text-transform: lowercase;
      width: calc(100% - 50px);
    }


    .webform-component {
      overflow: hidden;
      padding: 5px 10px;
      font-size: 16px;
      float: left;

      &:first-of-type {
        padding-top: 10px;
      }

      label {
        font-weight: normal;
        display: inline-block;
        width: 120px;
        text-align: right;
        vertical-align: middle;
      }

      &.webform-component--adatlap--ir-szam input {
        width: 57px;
      }

      &.webform-component--adatlap--telepulesnev input {
        width: 166px;
      }

      &.webform-component--adatlap--utca-hazszam input {
        width: 200px;
      }

      &.webform-component--adatlap--emelet-ajto input {
        width: 42px;
      }

      &.webform-component--adatlap--nem {
        width: 280px;
        padding-right: 0;

        .form-item-submitted-adatlap-nem label {
          text-align: left;
        }
      }

      &.webform-component--adatlap--ruhameret {
        label {
          width: 200px;
          text-align: left;
        }

        .form-radios .form-item label.option {
          width: 10px;
          margin-right: 25px;
        }
      }

      label[for="edit-submitted-adatlap-nem-1"] {
        width: 25px;
      }

      label[for="edit-submitted-adatlap-nem-2"] {
        width: 35px;
      }

      .form-radios {
        margin: 0;

        span.input-radio {
          margin-right: 5px;
        }

        label.option {
          margin-right: 7px;
        }
      }
    }
  }

  .form-item input.form-text,
  .webform-component--adatlap .webform-component input {
    line-height: 100%;
    font-size: 16px;
    margin: 0;
    padding: 2px 7px;
    width: 380px;
    border-radius: 2px;
    border: 0;
  }

  .form-item input.form-text {
    border: 1px solid $grey;
  }

  .webform-component--reszveteli-feltetelek,
  .webform-component--jatek-feltetelei,
  .webform-component--jatek-szabalyzat {
    background: $webform-bg;
    padding: 10px;
    margin: 0;

    label {
      text-align: center;
    }
  }

  .webform-component--jatek-feltetelei {
    padding-bottom: 1px;
  }

  .webform-component--jatek-szabalyzat {
    padding-top: 1px;
  }

  .webform-component--hirlevel-feliratkozas {
    background: $webform-bg;
    padding: 10px;
    margin: 0;

    label {
      text-align: center;
    }
  }

  .webform-component--reszveteli-feltetelek {
    * {
      font-size: 12px !important;
      line-height: 16px;
    }

    h2 {
      font-size: 24px !important;
      color: $main-purple !important;
      line-height: 32px;
    }
  }

  .webform-component .form-checkboxes {
    margin: 0;
  }

  .webform-component--fblike,
  .webform-component--fbshare {
    background: $webform-bg;
    padding: 10px;
    text-align: center;
  }

  .form-actions {
    background: $webform-bg;
    padding-bottom: 10px;
    text-align: center;
    margin: 0;
  }

  h1#page-title,
  #content .node-webform .content > h2,
  .block-views > h2 {
    line-height: 40px;
    padding: 10px 30px 0 0;
    font-size: 36px;
    margin: 15px 0 10px;
  }

  #content .node-webform .content > h2 {
    width: 500px;
    float: left;

    * {
      font-size: 36px !important;
    }
  }

  #content div.sharing {
    top: 230px;
  }

  #content .node-webform .webform-component-fieldset .description a {
    text-decoration: none;
    border: 0;
    border-radius: 2px;
    background-color: $c-red;
    color: $c-white;
    font-size: 16px;
    line-height: 100%;
    padding: 7px 10px;
    cursor: pointer;
  }
}

.view-jatekok {
  &.layout-2 {
    .views-row {
      @include flex-wrap;

      @include breakpoint($bp-tablet) {
        align-items: center;
      }

      @include breakpoint($bp-tablet-below) {
        align-items: center;
      }

      .image-wrapper {
        width: 278px;
        margin-right: 32px;
        position: relative;

        picture,
        .image-wrapper > a > img,
        .views-field-field-default-picture a > img {
          border: 1px solid $c-lifestyle;
          padding: 8px;
          display: block;
          line-height: 0;
          max-width: 100%;
          height: auto;

          &:hover {
            border-width: 9px;
            padding: 0;
          }
        }

        @include breakpoint($bp-tablet) {
          width: 225px;
          margin-right: 15px;

          img {
            max-height: 175px !important;
          }
        }

        @include breakpoint($bp-tablet-below) {
          width: 50%;
          margin-right: 15px;

          img {
            max-height: unset;
            height: auto;
          }
        }
      }

      .category {
        position: absolute;
        padding: $basic-unit / 2 $basic-unit;
        left: 9px;
        top: 195px;
        background: $c-lifestyle;

        @include breakpoint($bp-tablet) {
          padding: $basic-unit / 3 $basic-unit / 2;
          top: 154px;
        }

        @include breakpoint($bp-tablet-below) {
          padding: $basic-unit / 3 $basic-unit / 2;
        }

        span {
          font-size: $basic-unit;
          color: $c-black;
        }
      }

      .title-body {
        font-family: $body-font;
        font-size: 20px;
        line-height: 160%;
        margin-left: auto;
        width: calc(100% - 310px);

        @include breakpoint($bp-tablet) {
          width: calc(100% - 240px);

          .views-field-body {
            display: none;
          }
        }

        @include breakpoint($bp-tablet-below) {
          margin-left: 0;
          width: calc(50% - 15px);
        }

        .title {
          font-family: $main-font;
          line-height: 125%;
          color: $c-black;
          text-decoration: none;

          @include breakpoint($bp-tablet) {
            font-size: 20px;
          }

          @include breakpoint($bp-tablet-below) {
            font-size: 16px;
          }
        }

        .winners {
          font-size: 16px;
          line-height: 120%;

          .mobile & {
            font-size: 14px;
          }

          @include breakpoint($bp-tablet-below) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.panel-col-last .pane-jatekok,
#block-views-jatekok-block-2 {
  h2.pane-title,
  h2 {
    background: $secondary-purple url('../images/icon_nyeremenyjatek_white.png') 10px center no-repeat;
    margin-bottom: 0;
    padding: 0 0 0 50px;
    color: $c-white;
    font-size: 30px;
    text-transform: lowercase;
    text-align: left;
    border-radius: 2px;
    line-height: 40px;

    a,
    a:hover {
      color: $c-white;
      text-decoration: none;
    }
  }

  .views-row {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;

    .views-field-field-default-picture {
      width: 136px;
      margin-right: 8px;

      img {
        border: 1px solid $secondary-purple;
        padding: 8px;
        display: block;
        line-height: 0;
        max-width: 100%;

        &:hover {
          border-width: 9px;
          padding: 0;
        }
      }

      @include breakpoint($bp-tablet) {
        display: none;
      }
    }

    .views-field-title {
      margin-left: auto;
      flex: 1;

      a {
        font-size: 1.33 * $basic-unit;
        line-height: 150%;
        text-decoration: none;

        &:hover {
          box-shadow: inset 0 -10px $secondary-purple;
          transition: 0.30s ease;
        }
      }
    }
  }

  .pane-content,
  .content > .view {
    background: $grey;
  }
}
