.view-interviews {

  &.layout-0 {
    .views-field-title {
      margin: 16px 0;

      a {
        font-size: 36px;
        line-height: 130%;
        color: $c-black;
        text-decoration: none;

        &:hover {
          box-shadow: inset 0 -18px $c-lifestyle;
          transition: 0.30s ease;
        }
      }
    }

    .image_wrapper {
      position: relative;


      .image_wrapper > a > img,
      .views-field-field-default-picture a > img,
      picture {
        border: 1px solid $c-lifestyle;
        display: block;
        line-height: 0;
        max-width: calc(100% - 16px);
        padding: 8px;

        &:hover {
          border-width: 9px;
          padding: 0;
        }
      }

      .fresh-category-wrapper {
        position: absolute;
        padding: $basic-unit / 2 $basic-unit;
        left: 9px;
        bottom: 9px;
        background: $c-lifestyle;

        @include breakpoint($bp-tablet) {
          padding: $basic-unit / 3 $basic-unit / 2;
        }

        @include breakpoint($bp-tablet-below) {
          padding: $basic-unit / 3 $basic-unit / 2;
        }

        span {
          font-size: $basic-unit;
          color: $c-black;
        }
      }
    }
  }
}

.page-interju {
  .interview-block-wrapper {
    max-width: 374px;
    margin: 10px 0;
    float: left;

    &.hidden {
      display: none !important;
    }

    .view-interviews {
      .views-row {
        max-width: 374px;
        margin: 10px 0;

        .image_wrapper {
          position: relative;

          .mobil-image {
            display: none;
          }

          .desktop-image > a > img,
          .desktop-image picture {
            border: 1px solid $c-lifestyle;
            display: block;
            line-height: 0;
            max-width: calc(100% - 16px);
            padding: 8px;

            &:hover {
              border-width: 9px;
              padding: 0;
            }
          }

          .category {
            position: absolute;
            padding: $basic-unit / 2 $basic-unit;
            left: 9px;
            bottom: 9px;
            background: $c-lifestyle;

            @include breakpoint($bp-tablet) {
              padding: $basic-unit / 3 $basic-unit / 2;
            }

            @include breakpoint($bp-tablet-below) {
              padding: $basic-unit / 3 $basic-unit / 2;
            }

            span {
              font-size: $basic-unit;
              color: $c-black;
            }
          }

        }
      }
    }

    &.odd {
      clear: both;
    }

    &.even {
      float: right;
    }

    .views-field-title {
      font-size: 22px;
      line-height: 125%;
      margin: 5px 0 10px;
      height: auto;
      font-family: $main-font;
      overflow: visible;

      a {
        text-decoration: none;

        &:hover {
          box-shadow: inset 0 -10px $c-lifestyle;
          transition: 0.30s ease;
        }
      }
    }

    /*
    .views-field-field-default-picture-1 {
      display: none;
    }
    */
  }

  .fresh-interview-block-wrapper.hidden {
    display: none !important;
  }

  .interview-all {
    width: 100%;
    float: none;
    clear: both;

    .views-field-field-default-picture-1 {
      display: none;
    }

    &.layout-2 {
      .views-row {
        @include flex-wrap;

        @include breakpoint($bp-tablet) {
          align-items: center;
        }

        @include breakpoint($bp-tablet-below) {
          align-items: center;
        }

        .image_wrapper {
          width: 278px;
          margin-right: 32px;
          position: relative;

          picture,
          & > a > img {
            border: 1px solid $c-lifestyle;
            padding: 8px;
            display: block;
            line-height: 0;
            max-width: calc(100% - 16px);

            &:hover {
              border-width: 9px;
              padding: 0;
            }
          }

          @include breakpoint($bp-tablet) {
            width: 225px;
            margin-right: 15px;

            img {
              max-height: 175px !important;
            }
          }

          @include breakpoint($bp-tablet-below) {
            width: 50%;
            margin-right: 15px;

            img {
              max-height: unset;
              height: auto;
            }
          }
        }

        .category {
          position: absolute;
          padding: $basic-unit / 2 $basic-unit;
          left: 9px;
          top: 195px;
          background: $c-lifestyle;

          @include breakpoint($bp-tablet) {
            padding: $basic-unit / 3 $basic-unit / 2;
          }

          @include breakpoint($bp-tablet-below) {
            padding: $basic-unit / 3 $basic-unit / 2;
          }

          span {
            font-size: $basic-unit;
            color: $c-black;
          }

          @include breakpoint($bp-tablet) {
            top: 154px;
          }
        }

        .title_body {
          font-family: $body-font;
          font-size: 20px;
          line-height: 160%;
          margin-left: auto;
          width: calc(100% - 310px);

          @include breakpoint($bp-tablet) {
            width: calc(100% - 240px);

            .views-field-body {
              display: none;
            }
          }

          @include breakpoint($bp-tablet-below) {
            margin-left: 0;
            width: calc(50% - 15px);
          }

          a {
            font-family: $main-font;
            font-size: 32px;
            line-height: 125%;
            color: $c-black;
            text-decoration: none;

            @include breakpoint($bp-tablet) {
              font-size: 20px;

              &:hover {
                box-shadow: inset 0 -10px $c-lifestyle;
              }
            }

            @include breakpoint($bp-tablet-below) {
              font-size: 16px;
            }

            &:hover {
              box-shadow: inset 0 -18px $c-lifestyle;
            }
          }

          .views-field-body {

            .mobile & {
              display: none;
            }

            @include breakpoint($bp-tablet-below) {
              display: none;
            }
          }
        }
      }
    }

  }
}
