span.input-radio,
span.input-checkbox {
  display: inline-block;
  width: 62px;
  height: 34px;
  vertical-align: middle;
  margin-right: 12px;
  position: relative;
  top: -1px;
  cursor: pointer;
  background-color: $toggle-off;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  max-width: 65px;
  max-height: 34px;

  &::before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: $c-white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;

  }

  &.on {
    background-color: $toggle-on;

    &::before {
      transform: translateX(26px);
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
    }
  }
}

input[type="submit"],
.webform-client-form input.webform-submit {
  @include default-btn;
  padding: $basic-unit;
  cursor: pointer;
  font-family: $main-font;
}

form {
  font-family: $body-font;
}

input.form-text {
  max-width: calc(100% - 8px);
}
