.view-display-id-block_2022_december.view-hotwheel-box {
  .view-header {
    a {
      background: $hotwheels-bg url("../images/sponsoration/hotwheels_content_box_header_202212.png") no-repeat top center;
      height: 57px;
      text-indent: -10000px;
      overflow: hidden;
    }
  }

  .views-field-title {
    a {
      text-decoration: none;

      &:hover {
        box-shadow: inset 0 -10px $hotwheels-bg;
        transition: 0.30s ease;
      }
    }
  }

  .view-footer {
    background: url("../images/sponsoration/hotwheels_content_box_footer_bg_202212.gif") repeat-x;
    height: 89px;
    text-align: center;

    a {
      height: 89px;

      img {
        margin-top: -5px;
      }
    }
  }
}
