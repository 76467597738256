/* Cikkvégi keretes szöveg */
.cikkvegi {
  display: inline-block;
  max-width: 270px;
  background-color: $gb-cikkvegi;
  padding: 15px 15px 0;

  &.align-left {
    float: left;
    margin: 5px 20px 0 0;
  }

  &.align-center {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    padding: 2 * $basic-unit;
  }

  &.align-right {
    float: right;
    margin: 5px 0 0 20px;
  }

  h2.cikkvegi-title {
    color: $main-purple;
    margin: 0 0 15px !important;

    a {
      color: $main-purple;
      text-decoration: none !important;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .cikkvegi-content {
    font-size: 20px;
    font-family: $main-font;
  }

  .section-sztarok & {
    background-color: $c-sztarok;
  }

  .section-fashion-beauty & {
    background-color: $c-fashion;
  }

  .section-christmas & {
    background-color: $c-christmas;
  }
}


