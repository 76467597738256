.field-name-body {
  .rolunk {
    @include flex-container;
    width: 100%;
    border-bottom: 2px solid $main-purple;
    padding-bottom: 20px;
    margin-bottom: 20px;

    @include breakpoint($bp-mobile-landscape-below) {
      flex-direction: column;
    }

    .image {
      width: 200px;

      img {
        max-width: unset !important;
      }
    }

    .person {
      h2 {
        margin: 0;
      }

      h3 {
        margin-top: 0;
        font-weight: bold;
        color: $main-purple;
      }

    }
  }

  .field-item > .rolunk:nth-child(odd) {
    .image {
      margin-right: 20px;
    }
  }

  .field-item > .rolunk:nth-child(even) {
    .image {
      margin-left: 20px;
      order: 2;

      @include breakpoint($bp-mobile-landscape-below) {
        order: 1;
      }
    }

    .person {
      order: 1;

      @include breakpoint($bp-mobile-landscape-below) {
        order: 2;
      }
    }
  }

  .field-item > .rolunk:last-of-type {
    border-bottom: 0;
  }
}

.section-rolunk {
  .node h1 {
    margin-bottom: 0;
  }

  .field-name-body {
    img {
      float: right;
      margin-left: 2 * $basic-unit;
      margin-bottom: $basic-unit;

      @include breakpoint($bp-desktop-below) {
        float: none;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }

    h3 {
      font-weight: bold;
      margin-top: 0;
    }
  }
}

