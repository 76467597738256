#block-spike-related-articles {
  h2 {
    font-size: 2 * $basic-unit;
    font-weight: $regular;
    line-height: 100%;
    color: $main-purple;
  }

  .views-row {
    @include flex-wrap;
    margin-bottom: 2 * $basic-unit;
    width: 100%;

    @include breakpoint($bp-tablet) {
      align-items: center;
    }

    .image_wrapper {
      width: 278px;
      margin-right: 32px;
      position: relative;

      @include breakpoint($bp-tablet) {
        width: 225px;
        margin-right: 15px;

        img {
          max-height: 175px !important;
        }
      }
    }

    picture,
    .image_wrapper > a > img {
      border: 1px solid $main-purple;
      padding: 8px;
      display: block;
      line-height: 0;
      max-width: calc(100% - 16px);

      &:hover {
        border-width: 9px;
        padding: 0;
      }
    }

    /*
    .image_wrapper > a {
      overflow: hidden;
      display: block;

      &:hover {
        @include drop-shadow;

        img {
          transform: scale(1.2);
        }
      }

      img {
        transition: transform .5s ease;
      }
    }
    */

    .category {
      left: 9px;
      padding: 6px 12px;
      position: absolute;
      top: 195px;

      @include breakpoint($bp-tablet) {
        top: 154px;
        padding: $basic-unit / 3 $basic-unit / 2;
      }

      span {
        font-size: $basic-unit;
        color: $c-black;
      }
    }

    .title-body {
      font-family: $body-font;
      font-size: 20px;
      line-height: 160%;
      margin-left: auto;
      width: calc(100% - 310px);

      @include breakpoint($bp-tablet) {
        width: calc(100% - 240px);

        .views-field-teaser {
          display: none;
        }
      }

      .views-field-title {
        margin-bottom: $basic-unit;
        margin-top: 1.33 * $basic-unit;
      }

      .views-field-teaser {
        color: $c-black;
        font-family: $body-font;
        font-size: 1.33 * $basic-unit;
        line-height: 160%;
      }

      a {
        font-family: $main-font;
        font-size: 32px;
        line-height: 125%;
        color: $c-black;
        text-decoration: none;

        &:hover {
          box-shadow: inset 0 -18px $secondary-purple;
          transition: 0.30s ease;
        }

        @include breakpoint($bp-tablet) {
          font-size: 20px;

          &:hover {
            box-shadow: inset 0 -12px $secondary-purple;
            transition: 0.30s ease;
          }
        }
      }
    }

    &.rovat-sztarok {
      .category {
        background: $c-sztarok;
      }

      picture,
      .image_wrapper > a > img {
        border-color: $c-sztarok;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -18px $c-sztarok;
        transition: 0.30s ease;

        @include breakpoint($bp-tablet) {
          box-shadow: inset 0 -12px $c-sztarok;
        }
      }
    }

    &.rovat-fashion-beauty {
      .category {
        background: $c-fashion;
      }

      picture,
      .image_wrapper > a > img {
        border-color: $c-fashion;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -18px $c-fashion;
        transition: 0.30s ease;

        @include breakpoint($bp-tablet) {
          box-shadow: inset 0 -12px $c-fashion;
        }
      }
    }

    &.rovat-egeszseg {
      .category {
        background: $c-egeszseg;
      }

      picture,
      .image_wrapper > a > img {
        border-color: $c-egeszseg;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -18px $c-egeszseg;
        transition: 0.30s ease;

        @include breakpoint($bp-tablet) {
          box-shadow: inset 0 -12px $c-egeszseg;
        }
      }
    }

    &.rovat-lifestyle {
      .category {
        background: $c-lifestyle;
      }

      picture,
      .image_wrapper > a > img {
        border-color: $c-lifestyle;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -18px $c-lifestyle;
        transition: 0.30s ease;

        @include breakpoint($bp-tablet) {
          box-shadow: inset 0 -12px $c-lifestyle;
        }
      }
    }

    &.rovat-utazas {
      .category {
        background: $c-utazas;
      }

      picture,
      .image_wrapper > a > img {
        border-color: $c-utazas;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -18px $c-utazas;
        transition: 0.30s ease;

        @include breakpoint($bp-tablet) {
          box-shadow: inset 0 -12px $c-utazas;
        }
      }
    }

    &.rovat-bed-love {
      .category {
        background: $c-bed-love;
      }

      picture,
      .image_wrapper > a > img {
        border-color: $c-bed-love;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -18px $c-bed-love;
        transition: 0.30s ease;

        @include breakpoint($bp-tablet) {
          box-shadow: inset 0 -12px $c-bed-love;
        }
      }
    }

    &.rovat-home-design {
      .category {
        background: $c-home-design;
      }

      picture,
      .image_wrapper > a > img {
        border-color: $c-home-design;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -18px $c-home-design;
        transition: 0.30s ease;

        @include breakpoint($bp-tablet) {
          box-shadow: inset 0 -12px $c-home-design;
        }
      }
    }

    &.rovat-eskuvo {
      .category {
        background: $c-eskuvo;
      }

      picture,
      .image_wrapper > a > img {
        border-color: $c-eskuvo;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -18px $c-eskuvo;
        transition: 0.30s ease;

        @include breakpoint($bp-tablet) {
          box-shadow: inset 0 -12px $c-eskuvo;
        }
      }
    }

    &.rovat-recept {
      .category {
        background: $c-recept;
      }

      picture,
      .image_wrapper > a > img {
        border-color: $c-recept;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -18px $c-recept;
        transition: 0.30s ease;

        @include breakpoint($bp-tablet) {
          box-shadow: inset 0 -12px $c-recept;
        }
      }
    }

    &.rovat-christmas {
      .category {
        background: $c-christmas;
      }

      picture,
      .image_wrapper > a > img {
        border-color: $c-christmas;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -18px $c-christmas;
        transition: 0.30s ease;

        @include breakpoint($bp-tablet) {
          box-shadow: inset 0 -12px $c-christmas;
        }
      }
    }
  }
}
