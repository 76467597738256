.block {
  h2 {
    font-size: 2 * $basic-unit;
    font-family: $main-font;
    font-weight: $regular;
  }
}

.tabs {
  @include breakpoint($bp-tablet-below) {
    display: none;
  }
}

.action-links {
  @include breakpoint($bp-tablet-below) {
    display: none;
  }
}

.messages {
  font-family: $body-font;
}
