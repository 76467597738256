.layout-1 {
  .views-row {
    max-width: 374px;
    margin-bottom: 2 * $basic-unit;

    &.views-row-odd {
      margin-right: 2 * $basic-unit;

      @include breakpoint($bp-tablet) {
        margin-right: 15px;
      }

      .mobile & {
        margin-right: 0;
      }

      @include breakpoint($bp-tablet-below) {
        margin-right: 0;
      }
    }

    @include breakpoint($bp-tablet) {
      max-width: 225px;
    }

    @include breakpoint($bp-tablet-below) {
      max-width: 100%;
    }
  }
}

.layout-full,
.layout-2 {
  .views-row {
    width: 100%;
    margin-bottom: 2 * $basic-unit;
  }
}

.view-front-news,
.view-Babavilag,
.view-Rovatajanlo {

  .view-content {
    @include flex-wrap;

    @include breakpoint($bp-tablet-below) {
      flex-direction: column;
    }
  }

  .views-row {

    .image_wrapper {
      position: relative;
    }

    .views-field-field-category {
      border-top: 2px solid $main-purple;
      font-size: 2 * $basic-unit;
      line-height: 100%;
      padding-top: $basic-unit;
      padding-bottom: $basic-unit / 2;
      margin-bottom: 1.5 * $basic-unit;
      padding-left: 38px;
    }

    .category {
      position: absolute;
      padding: $basic-unit / 2 $basic-unit;
      left: 9px;
      bottom: 9px;

      @include breakpoint($bp-tablet) {
        padding: $basic-unit / 3 $basic-unit / 2;
      }

      @include breakpoint($bp-tablet-below) {
        padding: $basic-unit / 3 $basic-unit / 2;
      }

      span {
        font-size: $basic-unit;
        color: $c-black;
      }
    }

    .views-field-field-av-counter-lead {
      flex-basis: 100%;
    }

    /*
    .image_wrapper > a {
      overflow: hidden;
      display: block;

      &:hover {
        @include drop-shadow;

        img {
          transform: scale(1.2);
        }
      }

      img {
        transition: transform .5s ease;
      }
    }
    */

    picture,
    .image_wrapper > a > img,
    .views-field-field-default-picture a > img {
      border: 1px solid $main-purple;
      padding: 8px;
      display: block;
      line-height: 0;
      max-width: 100%;
      height: auto;

      .layout-2 & {
        max-width: calc(100% - 16px);
      }

      &:hover {
        border-width: 9px;
        padding: 0;
      }
    }

    picture {
      img {
        max-width: 100%;
        height: auto;
      }

      @include breakpoint($bp-tablet-below) {
        max-width: 100%;
        height: auto;
      }
    }

    .mobile-image img {
      .mobile & {
        width: 100%;
      }

      @include breakpoint($bp-tablet-below) {
        width: 100%;
      }
    }


    .image_wrapper > a > img,
    & > a > img {

      @include breakpoint($bp-tablet-below) {
        max-width: 100%;
        height: auto;
      }
    }

    .views-field-title {
      margin-top: 1.3 * $basic-unit;
      margin-bottom: $basic-unit;

      @include breakpoint($bp-tablet) {
        margin-top: $basic-unit;
      }

      a {
        font-size: 20px;
        line-height: 125%;
        color: $c-black;
        text-decoration: none;

        @include breakpoint($bp-tablet) {
          font-size: 16px;
        }

        &:hover {
          box-shadow: inset 0 -10px $secondary-purple;
          transition: 0.30s ease;
        }
      }
    }

    .views-field-body {
      font-family: $body-font;
      font-size: 16px;
      line-height: 160%;
      color: $c-black;
    }

    .mobile-image {
      display: none;

      .mobile & {
        display: block;
      }

      @include breakpoint($bp-tablet-below) {
        display: block;
      }
    }

    .desktop-image {
      display: block;

      .mobile & {
        display: none;
      }

      @include breakpoint($bp-tablet-below) {
        display: none;
      }
    }
  }

  .layout-full & {
    picture,
    .image_wrapper > a > img {
      border: 0;
      padding: 0;
    }

    .views-row {
      background-color: $secondary-purple;
      position: relative;
      padding: 8px;

      @include breakpoint($bp-tablet-below) {
        max-width: calc(100% - 16px);
      }

      .category {
        bottom: 0;
        left: 0;
      }

      .views-field-title {
        a {
          font-size: 3 * $basic-unit;
          line-height: 130%;

          @include breakpoint($bp-tablet) {
            font-size: 2.5 * $basic-unit;
          }

          @include breakpoint($bp-tablet-below) {
            font-size: 2 * $basic-unit;
          }

          &:hover {
            box-shadow: inset 0 -18px $secondary-purple;
            transition: 0.30s ease;
          }
        }
      }
    }
  }

  .layout-2 & {
    .views-row {
      @include flex-wrap;

      @include breakpoint($bp-tablet) {
        align-items: center;
      }

      @include breakpoint($bp-tablet-below) {
        align-items: center;
      }

      .image_wrapper {
        width: 278px;
        margin-right: 32px;

        @include breakpoint($bp-tablet) {
          width: 225px;
          margin-right: 15px;

          img {
            max-height: 175px !important;
          }
        }

        @include breakpoint($bp-tablet-below) {
          width: 50%;
          margin-right: 15px;

          img {
            max-height: unset;
            height: auto;
          }
        }
      }

      .category {
        top: 195px;
        bottom: unset;

        @include breakpoint($bp-tablet) {
          top: 154px;
        }
      }

      .title-body {
        font-family: $body-font;
        font-size: 20px;
        line-height: 160%;
        margin-left: auto;
        width: calc(100% - 310px);

        @include breakpoint($bp-tablet) {
          width: calc(100% - 240px);

          .views-field-body {
            display: none;
          }
        }

        @include breakpoint($bp-tablet-below) {
          margin-left: 0;
          width: calc(50% - 15px);
        }

        :not(.tags) a {
          font-family: $main-font;
          font-size: 32px;
          line-height: 125%;
          color: $c-black;
          text-decoration: none;

          @include breakpoint($bp-tablet) {
            font-size: 20px;

            &:hover {
              box-shadow: inset 0 -10px $secondary-purple;
            }
          }

          @include breakpoint($bp-tablet-below) {
            font-size: 16px;
          }

          &:hover {
            box-shadow: inset 0 -18px $secondary-purple;
            transition: 0.30s ease;
          }
        }

        .tags {
          margin-top: $basic-unit;

          .mobile & {
            display: none;
          }

          @include breakpoint($bp-tablet) {
            display: none;
          }

          @include breakpoint($bp-tablet-below) {
            display: none;
          }
        }

        .tags a {
          display: inline-block;
          background: $tags-bg;
          padding: 0.5 * $basic-unit $basic-unit;
          margin: 0 $basic-unit / 2 $basic-unit / 2 0;
          text-decoration: none;
          font-size: 14px;
          line-height: 100%;

          &:hover {
            color: $main-purple;
          }
        }

        .views-field-body {

          .mobile & {
            display: none;
          }

          @include breakpoint($bp-tablet-below) {
            display: none;
          }
        }
      }
    }
  }

  .layout-0 & {
    .views-row {

      @include breakpoint($bp-tablet-below) {
        display: flex;
        flex-direction: column;
      }

      .image_wrapper {
        .mobile & {
          order: 1;
        }

        @include breakpoint($bp-tablet-below) {
          order: 1;
        }
      }


      .views-field-title {
        margin-bottom: 16px;

        @include breakpoint($bp-tablet-below) {
          order: 2;
        }

        a {
          font-size: 3 * $basic-unit;
          line-height: 130%;

          @include breakpoint($bp-tablet) {
            font-size: 2.5 * $basic-unit;
          }

          @include breakpoint($bp-tablet-below) {
            font-size: 24px;
          }
        }
      }
    }
  }

  .layout-3 & {
    .image_wrapper {
      img {
        max-width: 358px;
        height: auto;

        @include breakpoint($bp-tablet) {
          max-width: 100%;
        }

        @include breakpoint($bp-tablet-below) {
          width: 100%;
        }
      }

      .mobile-image img {
        .mobile & {
          max-width: 100%;
        }

        @include breakpoint($bp-tablet-below) {
          max-width: 100%;
        }
      }
    }
  }

  // Mobile: First front news.
  .layout-10 & {
    .views-row {
      display: flex;
      flex-direction: column;

      .image_wrapper {
        order: 1;
      }

      .views-field-title {
        order: 2;

        a {
          font-size: 32px;
          line-height: 130%;

          .mobile & {
            font-size: 24px;
          }
        }
      }
    }
  }

  .views-row {
    &.rovat_sztarok {
      .layout-full & {
        background: $c-sztarok;
      }

      .views-field-field-category {
        background: url('../images/icon_sztarok.svg') no-repeat 0 6px;
        border-color: $c-sztarok;

        span {
          color: $c-sztarok;
        }
      }

      .category {
        background: $c-sztarok;
      }

      picture,
      .image_wrapper > a > img,
      .views-field-field-default-picture a > img {
        border-color: $c-sztarok;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-sztarok;

        .layout-0 &,
        .layout-2 & {
          box-shadow: inset 0 -18px $c-sztarok;
        }

        @include breakpoint($bp-tablet) {
          .layout-2 & {
            box-shadow: inset 0 -12px $c-sztarok;
          }
        }
      }
    }

    &.rovat_fashion-beauty {
      .layout-full & {
        background: $c-fashion;
      }

      .views-field-field-category {
        background: url('../images/icon_fashion-beauty.svg') no-repeat 0 6px;
        border-color: $c-fashion;

        span {
          color: $c-fashion;
        }
      }

      .category {
        background: $c-fashion;
      }

      picture,
      .image_wrapper > a > img,
      .views-field-field-default-picture a > img {
        border-color: $c-fashion;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-fashion;

        .layout-0 &,
        .layout-2 & {
          box-shadow: inset 0 -18px $c-fashion;
        }

        @include breakpoint($bp-tablet) {
          .layout-2 & {
            box-shadow: inset 0 -12px $c-fashion;
          }
        }
      }
    }

    &.rovat_egeszseg {
      .layout-full & {
        background: $c-egeszseg;
      }

      .views-field-field-category {
        background: url('../images/icon_egeszseg.svg') no-repeat 0 6px;
        border-color: $c-egeszseg;

        span {
          color: $c-egeszseg;
        }
      }

      .category {
        background: $c-egeszseg;
      }

      picture,
      .image_wrapper > a > img,
      .views-field-field-default-picture a > img {
        border-color: $c-egeszseg;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-egeszseg;

        .layout-0 &,
        .layout-2 & {
          box-shadow: inset 0 -18px $c-egeszseg;
        }

        @include breakpoint($bp-tablet) {
          .layout-2 & {
            box-shadow: inset 0 -12px $c-egeszseg;
          }
        }
      }
    }

    &.rovat_lifestyle {
      .layout-full & {
        background: $c-lifestyle;
      }

      .views-field-field-category {
        background: url('../images/icon_lifestyle.svg') no-repeat 0 6px;
        border-color: $c-lifestyle;

        span {
          color: $c-lifestyle;
        }
      }

      .category {
        background: $c-lifestyle;
      }

      picture,
      .image_wrapper > a > img,
      .views-field-field-default-picture a > img {
        border-color: $c-lifestyle;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-lifestyle;

        .layout-0 &,
        .layout-2 & {
          box-shadow: inset 0 -18px $c-lifestyle;
        }

        @include breakpoint($bp-tablet) {
          .layout-2 & {
            box-shadow: inset 0 -12px $c-lifestyle;
          }
        }
      }
    }

    &.rovat_utazas {
      .layout-full & {
        background: $c-utazas;
      }

      .views-field-field-category {
        background: url('../images/icon_utazas.svg') no-repeat 0 6px;
        border-color: $c-utazas;

        span {
          color: $c-utazas;
        }
      }

      .category {
        background: $c-utazas;
      }

      picture,
      .image_wrapper > a > img,
      .views-field-field-default-picture a > img {
        border-color: $c-utazas;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-utazas;

        .layout-0 &,
        .layout-2 & {
          box-shadow: inset 0 -18px $c-utazas;
        }

        @include breakpoint($bp-tablet) {
          .layout-2 & {
            box-shadow: inset 0 -12px $c-utazas;
          }
        }
      }
    }

    &.rovat_bed-love {
      .layout-full & {
        background: $c-bed-love;
      }

      .views-field-field-category {
        background: url('../images/icon_bed-love.svg') no-repeat 0 6px;
        border-color: $c-bed-love;

        span {
          color: $c-bed-love;
        }
      }

      .category {
        background: $c-bed-love;
      }

      picture,
      .image_wrapper > a > img,
      .views-field-field-default-picture a > img {
        border-color: $c-bed-love;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-bed-love;

        .layout-0 &,
        .layout-2 & {
          box-shadow: inset 0 -18px $c-bed-love;
        }

        @include breakpoint($bp-tablet) {
          .layout-2 & {
            box-shadow: inset 0 -12px $c-bed-love;
          }
        }
      }
    }

    &.rovat_home-design {
      .layout-full & {
        background: $c-home-design;
      }

      .views-field-field-category {
        background: url('../images/icon_home-design.svg') no-repeat 0 6px;
        border-color: $c-home-design;

        span {
          color: $c-home-design;
        }
      }

      .category {
        background: $c-home-design;
      }

      picture,
      .image_wrapper > a > img,
      .views-field-field-default-picture a > img {
        border-color: $c-home-design;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-home-design;

        .layout-0 &,
        .layout-2 & {
          box-shadow: inset 0 -18px $c-home-design;
        }

        @include breakpoint($bp-tablet) {
          .layout-2 & {
            box-shadow: inset 0 -12px $c-home-design;
          }
        }
      }
    }

    &.rovat_eskuvo {
      .layout-full & {
        background: $c-eskuvo;
      }

      .views-field-field-category {
        background: url('../images/icon_eskuvo.svg') no-repeat 0 6px;
        border-color: $c-eskuvo;

        span {
          color: $c-eskuvo;
        }
      }

      .category {
        background: $c-eskuvo;
      }

      picture,
      .image_wrapper > a > img,
      .views-field-field-default-picture a > img {
        border-color: $c-eskuvo;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-eskuvo;

        .layout-0 &,
        .layout-2 & {
          box-shadow: inset 0 -18px $c-eskuvo;
        }

        @include breakpoint($bp-tablet) {
          .layout-2 & {
            box-shadow: inset 0 -12px $c-eskuvo;
          }
        }
      }
    }

    &.rovat_recept {
      .layout-full & {
        background: $c-recept;
      }

      .views-field-field-category {
        background: url('../images/icon_recept.svg') no-repeat 0 6px;
        border-color: $c-recept;

        span {
          color: $c-recept;
        }
      }

      .category {
        background: $c-recept;
      }

      picture,
      .image_wrapper > a > img,
      .views-field-field-default-picture a > img {
        border-color: $c-recept;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-recept;

        .layout-0 &,
        .layout-2 & {
          box-shadow: inset 0 -18px $c-recept;
        }

        @include breakpoint($bp-tablet) {
          .layout-2 & {
            box-shadow: inset 0 -12px $c-recept;
          }
        }
      }
    }

    &.rovat_christmas {
      .layout-full & {
        background: $c-christmas;
      }

      .views-field-field-category {
        background: url('../images/icon_christmas.svg') no-repeat 0 6px;
        border-color: $c-christmas;

        span {
          color: $c-christmas;
        }
      }

      .category {
        background: $c-christmas;
      }

      picture,
      .image_wrapper > a > img,
      .views-field-field-default-picture a > img {
        border-color: $c-christmas;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-christmas;

        .layout-0 &,
        .layout-2 & {
          box-shadow: inset 0 -18px $c-christmas;
        }

        @include breakpoint($bp-tablet) {
          .layout-2 & {
            box-shadow: inset 0 -12px $c-christmas;
          }
        }
      }
    }

    &.rovat_meteospasmyl {
      .layout-full & {
        background: $c-meteospasmyl;
      }

      .views-field-field-category {
        background: url('../images/icon_meteospasmyl.png') no-repeat 0 6px;
        border-color: $c-meteospasmyl;

        span {
          color: $c-meteospasmyl;
        }
      }

      .category {
        background: $c-meteospasmyl;
      }

      picture,
      .image_wrapper > a > img,
      .views-field-field-default-picture a > img {
        border-color: $c-meteospasmyl;
      }

      .views-field-title a:hover {
        box-shadow: inset 0 -10px $c-meteospasmyl;

        .layout-0 &,
        .layout-2 & {
          box-shadow: inset 0 -18px $c-meteospasmyl;
        }

        @include breakpoint($bp-tablet) {
          .layout-2 & {
            box-shadow: inset 0 -12px $c-meteospasmyl;
          }
        }
      }
    }
  }
}
