#header-region {

  .header--wrapper.sticky & {
    #block-spike-nameday {
      display: none;
    }
  }

  #block-spike-nameday {
    max-width: 125px;
  }

  div {
    font-family: $body-font;
    font-size: 12px;
    line-height: 150%;
    text-align: right;
    color: $secondary-purple;

    .section-inspiralonok & {
      color: $c-inspiralonok;
    }
  }

  .currentdate {
    text-transform: capitalize;
    color: $main-purple;
    font-weight: 700;

    .section-inspiralonok & {
      color: $c-inspiralonok;
    }
  }
}
