.blog-listpage.blog-femanyu {
  margin-top: 20px;

  .views-row {
    .views-field-title {
      a {
        background: url("../images/logo_femanyu.png") no-repeat left center;
        padding-left: 100px;
        line-height: 40px;
        min-height: 66px;
      }
    }
  }

  .view-footer {
    text-align: center;
    margin: 2 * $basic-unit 0;
  }
}

.section-femanyu {
  #femanyu_blog_pre_cikk_image {
    margin: 20px auto 10px;
    text-align: center;

    img {
      .mobile & {
        width: 100%;
        height: auto;
      }

      @include breakpoint($bp-tablet-below) {
        width: 100%;
        height: auto;
      }
    }
  }

  &.desktop #content-content .node h1 {
    background: url("../images/logo_femanyu.png") no-repeat left center;
    padding-left: 100px;
  }
}

