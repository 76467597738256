.view-pr {

  .views-row {
    background-color: $secondary-purple;
    padding: 8px;
    margin-bottom: 2 * $basic-unit;

    &.big-picture {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    &:not(.big-picture) {
      display: flex;
      flex-wrap: wrap;

      .views-field-field-default-picture {
        width: 116px;
        margin-right: 8px;
        order: 1;

        @include breakpoint($bp-tablet) {
          display: none;
        }
      }

      .views-field-field-av-counter-lead {
        flex-basis: 100%;
      }

      .category {
        padding: $basic-unit / 4 $basic-unit / 2;

        span {
          font-size: 10px;
        }
      }

      .title-wrapper {
        flex: 1;
        order: 2;
        margin-left: auto;
      }

      .views-field-title a {
        font-size: 1.33 * $basic-unit;
        line-height: 150%;
      }
    }

    * {
      font-family: $body-font;
      line-height: 100%;
    }

    .adv-label {
      font-size: 0.66 * $basic-unit;
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    .views-field-title {
      margin-bottom: $basic-unit;

      a {
        font-family: $main-font;
        font-size: 1.66 * $basic-unit;
        line-height: 125%;
        text-decoration: none;

        &:hover {
          box-shadow: inset 0 -10px $secondary-purple;
          transition: 0.30s ease;
        }
      }
    }

    .views-field-field-default-picture {
      position: relative;

      img {
        @include breakpoint($bp-tablet-below) {
          width: 100%;
          height: auto;
        }
      }
    }

    .category {
      position: absolute;
      padding: $basic-unit / 2 $basic-unit;
      left: 0;
      bottom: 3px;
      background-color: $secondary-purple;

      .mobile & {
        bottom: 0;
      }

      span {
        font-family: $main-font;
        font-size: $basic-unit;
        color: $c-black;
      }
    }

    .views-field-field-intro-value {
      margin-top: $basic-unit;
      font-size: 1.33 * $basic-unit;
      line-height: 162%;

      a {
        text-decoration: none;

        /*
        &:hover {
          box-shadow: inset 0 -10px $secondary-purple;
        }
        */
      }
    }

    .views-field-field-av-counter-lead {
      img {
        line-height: 0;
        display: block;
      }
    }

    &.rovat_sztarok {
      background-color: $c-sztarok;

      .category {
        background-color: $c-sztarok;
      }

      /*
      a:hover {
        box-shadow: inset 0 -10px $c-sztarok;
      }
      */
    }

    &.rovat_fashion-beauty {
      background-color: $c-fashion;

      .category {
        background-color: $c-fashion;
      }

      /*
      * a:hover {
        box-shadow: inset 0 -10px $c-fashion;
      }
      */
    }

    &.rovat_egeszseg {
      background-color: $c-egeszseg;

      .category {
        background-color: $c-egeszseg;
      }

      /*
      a:hover {
        box-shadow: inset 0 -10px $c-egeszseg;
      }
      */
    }

    &.rovat_lifestyle {
      background-color: $c-lifestyle;

      .category {
        background-color: $c-lifestyle;
      }

      /*
      a:hover {
        box-shadow: inset 0 -10px $c-lifestyle;
      }
      */
    }

    &.rovat_utazas {
      background-color: $c-utazas;

      .category {
        background-color: $c-utazas;
      }

      /*
      a:hover {
        box-shadow: inset 0 -10px $c-utazas;
      }
      */
    }

    &.rovat_bed-love {
      background-color: $c-bed-love;

      .category {
        background-color: $c-bed-love;
      }

      /*
      a:hover {
        box-shadow: inset 0 -10px $c-bed-love;
      }
      */
    }

    &.rovat_home-design {
      background-color: $c-home-design;

      .category {
        background-color: $c-home-design;
      }

      /*
      a:hover {
        box-shadow: inset 0 -10px $c-home-design;
      }
      */
    }

    &.rovat_eskuvo {
      background-color: $c-eskuvo;

      .category {
        background-color: $c-eskuvo;
      }

      /*
      a:hover {
        box-shadow: inset 0 -10px $c-eskuvo;
      }
      */
    }

    &.rovat_recept {
      background-color: $c-recept;

      .category {
        background-color: $c-recept;
      }

      /*
      a:hover {
        box-shadow: inset 0 -10px $c-recept;
      }
      */
    }

    &.rovat_christmas {
      background-color: $c-christmas;

      .category {
        background-color: $c-christmas;
      }

      /*
      a:hover {
        box-shadow: inset 0 -10px $c-recept;
      }
      */
    }
  }
}

.pr_pictures_narrow {
  border-top: 2px solid $main-purple;
  padding-top: $basic-unit;
  margin: $basic-unit 0 2 * $basic-unit;
  clear: both;
  position: relative;

  h2 {
    color: $darkgrey;
    margin-top: 0;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .content {
    .views-row {
      @include flex-container;
      margin-bottom: 1.5 * $basic-unit;

      .mobile & {
        flex-direction: column;
        margin-bottom: 0;
      }

      @include breakpoint($bp-tablet-below) {
        flex-direction: column;
        margin-bottom: 0;
      }

      .left-column,
      .right-column {
        @include flex-wrap;
        width: 48%;
        align-items: center;

        .mobile & {
          width: 100%;
          margin-bottom: 1.5 * $basic-unit;
        }

        @include breakpoint($bp-tablet-below) {
          width: 100%;
          margin-bottom: 1.5 * $basic-unit;
        }
      }

      .views-field-field-default-picture {
        a {
          border: 1px solid $c-pr-boxes;
          padding: 6px;
          display: block;
          line-height: 0;

          &:hover {
            border-width: 7px;
            padding: 0;
          }
        }
      }

      .views-field-title {
        margin-bottom: $basic-unit / 2;

        a {
          text-decoration: none;
          font-size: 16px;
          line-height: 125%;
        }
      }

      .views-field-field-intro-value {
        a {
          font-family: $body-font;
          text-decoration: none;
          font-size: 14px;
          line-height: 160%;
        }
      }

      .title_body_wrapper {
        margin-left: auto;
        flex: 1;
        padding-left: $basic-unit;
      }

      .right-column {
        margin-left: auto;
      }
    }
  }
}
