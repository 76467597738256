table.szakertok {
  width: 100%;

  td {
    padding: 3px;
    vertical-align: top;

    &.image {
      width: 155px;
    }

    h2 {
      font-size: 2 * $basic-unit;
      line-height: 100%;
      margin: 0;

      @include breakpoint($bp-tablet-below) {
        font-size: 1.33 * $basic-unit;
      }

      .mobile {
        font-size: 1.33 * $basic-unit;
      }
    }

    h3 {
      font-size: 1.33 * $basic-unit;
      line-height: 100%;
      margin: 10px 0;
    }
  }
}

.page-szakertoink hr {
  margin: 16px 0;
}

#block-spike-expert-articles {
  h2 {
    color: $main-purple;
    border-top: 1px solid $main-purple;
    padding-top: 2 * $basic-unit;
  }

  .layout-2 {
    .views-row {
      @include flex-wrap;

      @include breakpoint($bp-tablet) {
        align-items: center;
      }

      @include breakpoint($bp-tablet-below) {
        align-items: center;
      }

      .image_wrapper {
        width: 278px;
        margin-right: 32px;
        position: relative;

        picture,
        & > a > img {
          border: 1px solid $c-lifestyle;
          padding: 8px;
          display: block;
          line-height: 0;
          max-width: calc(100% - 16px);

          &:hover {
            border-width: 9px;
            padding: 0;
          }
        }

        @include breakpoint($bp-tablet) {
          width: 225px;
          margin-right: 15px;

          img {
            max-height: 175px !important;
          }
        }

        @include breakpoint($bp-tablet-below) {
          width: 50%;
          margin-right: 15px;

          img {
            max-height: unset;
            height: auto;
          }
        }
      }

      .category {
        position: absolute;
        padding: $basic-unit / 2 $basic-unit;
        left: 9px;
        top: 195px;
        background: $c-lifestyle;

        @include breakpoint($bp-tablet) {
          padding: $basic-unit / 3 $basic-unit / 2;
        }

        @include breakpoint($bp-tablet-below) {
          padding: $basic-unit / 3 $basic-unit / 2;
        }

        span {
          font-size: $basic-unit;
          color: $c-black;
        }
      }

      .title-body-wrapper {
        font-family: $body-font;
        font-size: 20px;
        line-height: 160%;
        margin-left: auto;
        width: calc(100% - 310px);

        @include breakpoint($bp-tablet) {
          width: calc(100% - 240px);

          .views-field-body {
            display: none;
          }
        }

        @include breakpoint($bp-tablet-below) {
          margin-left: 0;
          width: calc(50% - 15px);
        }

        a {
          font-family: $main-font;
          font-size: 32px;
          line-height: 125%;
          color: $c-black;
          text-decoration: none;

          @include breakpoint($bp-tablet) {
            font-size: 20px;

            &:hover {
              box-shadow: inset 0 -10px $c-lifestyle;
            }
          }

          @include breakpoint($bp-tablet-below) {
            font-size: 16px;
          }

          &:hover {
            box-shadow: inset 0 -18px $c-lifestyle;
          }
        }

        .views-field-body {

          .mobile & {
            display: none;
          }

          @include breakpoint($bp-tablet-below) {
            display: none;
          }
        }
      }

      &.rovat-sztarok {
        .category {
          background: $c-sztarok;
        }

        picture,
        .image_wrapper > a > img {
          border-color: $c-sztarok;
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -18px $c-sztarok;
        }
      }

      &.rovat-fashionbeauty,
      &.rovat-fashion-beauty {
        .category {
          background: $c-fashion;
        }

        picture,
        .image_wrapper > a > img {
          border-color: $c-fashion;
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -18px $c-fashion;
        }
      }

      &.rovat-egeszseg {
        .category {
          background: $c-egeszseg;
        }

        picture,
        .image_wrapper > a > img {
          border-color: $c-egeszseg;
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -18px $c-egeszseg;
        }
      }

      &.rovat-lifestyle {
        .category {
          background: $c-lifestyle;
        }

        picture,
        .image_wrapper > a > img {
          border-color: $c-lifestyle;
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -18px $c-lifestyle;
        }
      }

      &.rovat-utazas {
        .category {
          background: $c-utazas;
        }

        picture,
        .image_wrapper > a > img {
          border-color: $c-utazas;
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -18px $c-utazas;
        }
      }

      &.rovat-bedlove,
      &.rovat-bed-love {
        .category {
          background: $c-bed-love;
        }

        picture,
        .image_wrapper > a > img {
          border-color: $c-bed-love;
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -18px $c-bed-love;
        }
      }

      &.rovat-homedesign,
      &.rovat-home-design {
        .category {
          background: $c-home-design;
        }

        picture,
        .image_wrapper > a > img {
          border-color: $c-home-design;
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -18px $c-home-design;
        }
      }

      &.rovat-eskuvo {
        .category {
          background: $c-eskuvo;
        }

        picture,
        .image_wrapper > a > img {
          border-color: $c-eskuvo;
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -18px $c-eskuvo;
        }
      }

      &.rovat-recept {
        .category {
          background: $c-recept;
        }

        picture,
        .image_wrapper > a > img {
          border-color: $c-recept;
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -18px $c-recept;
        }
      }

      &.rovat-christmas {
        .category {
          background: $c-christmas;
        }

        picture,
        .image_wrapper > a > img {
          border-color: $c-christmas;
        }

        .views-field-title a:hover {
          box-shadow: inset 0 -18px $c-christmas;
        }
      }
    }
  }
}

