#recommended_wrapper {
  width: 100%;

  .recommended_header {
    border-top: 2px solid $main-purple;
    padding: $basic-unit 0 2 * $basic-unit;

    .title {
      font-size: 2 * $basic-unit;
      line-height: 100%;
      color: $main-purple;

      @include breakpoint($bp-tablet) {
        font-size: 1.5 * $basic-unit;
      }
    }
  }

  .recommended_content {
    @include flex-wrap;

    @include breakpoint($bp-tablet-below) {
      flex-direction: column;
    }

    .recommended_item {
      max-width: 374px;
      margin-bottom: 2 * $basic-unit;

      @include breakpoint($bp-tablet) {
        max-width: 225px;
        margin-bottom: 1.33 * $basic-unit;
      }

      @include breakpoint($bp-tablet-below) {
        max-width: 100%;
      }

      &.odd {
        margin-right: 2 * $basic-unit;

        @include breakpoint($bp-tablet) {
          margin-right: 15px;
        }

        @include breakpoint($bp-tablet-below) {
          margin-right: 0;
        }
      }

      .image-wrapper {
        position: relative;

        picture,
        & > a > img {
          border: 1px solid $main-purple;
          padding: 8px;
          display: block;
          line-height: 0;
          max-width: calc(100% - 16px);

          @include breakpoint($bp-tablet) {
            margin-bottom: $basic-unit;
          }

          &:hover {
            border-width: 9px;
            padding: 0;
          }
        }

        picture {
          @include breakpoint($bp-tablet-below) {
            max-width: 100%;
            height: auto;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        & > a > img {
          @include breakpoint($bp-tablet-below) {
            max-width: calc(100% - 16px);
            height: auto;
          }
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .category {
        position: absolute;
        padding: $basic-unit / 2 $basic-unit;
        left: 9px;
        bottom: 9px;

        @include breakpoint($bp-tablet) {
          padding: $basic-unit / 3 $basic-unit / 2;
        }

        @include breakpoint($bp-tablet-below) {
          padding: $basic-unit / 3 $basic-unit / 2;
        }

        span {
          font-size: $basic-unit;
          color: $c-black;
        }
      }

      /*
      & > a {
        overflow: hidden;
        display: block;

        &:hover {
          @include drop-shadow;

          img {
            transform: scale(1.2);
          }
        }

        img {
          transition: transform .5s ease;
        }
      }
      */

      .title_wrapper {
        margin-top: 1.5 * $basic-unit;

        .title {
          font-size: 20px;
          line-height: 125%;

          a {
            text-decoration: none;
            color: $c-black;
            position: relative;

            @include breakpoint($bp-tablet) {
              font-size: 16px;
            }

            &:hover {
              span {
                box-shadow: inset 0 -10px $secondary-purple;
                transition: 0.30s ease;
              }
            }
          }
        }
      }

      &.rovat_sztarok {
        .category {
          background: $c-sztarok;
        }

        .image-wrapper {
          picture,
          & > a > img {
            border-color: $c-sztarok;
          }
        }

        .title a:hover span {
          box-shadow: inset 0 -10px $c-sztarok;
          transition: 0.30s ease;
        }
      }

      &.rovat_fashionbeauty,
      &.rovat_fashion-beauty {
        .category {
          background: $c-fashion;
        }

        .image-wrapper {
          picture,
          & > a > img {
            border-color: $c-fashion;
          }
        }

        .title a:hover span {
          box-shadow: inset 0 -10px $c-fashion;
          transition: 0.30s ease;
        }
      }

      &.rovat_egeszseg {
        .category {
          background: $c-egeszseg;
        }

        .image-wrapper {
          picture,
          & > a > img {
            border-color: $c-egeszseg;
          }
        }

        .title a:hover span {
          box-shadow: inset 0 -10px $c-egeszseg;
          transition: 0.30s ease;
        }
      }

      &.rovat_lifestyle {
        .category {
          background: $c-lifestyle;
        }

        .image-wrapper {
          picture,
          & > a > img {
            border-color: $c-lifestyle;
          }
        }

        .title a:hover span {
          box-shadow: inset 0 -10px $c-lifestyle;
          transition: 0.30s ease;
        }
      }

      &.rovat_utazas {
        .category {
          background: $c-utazas;
        }

        .image-wrapper {
          picture,
          & > a > img {
            border-color: $c-utazas;
          }
        }

        .title a:hover span {
          box-shadow: inset 0 -10px $c-utazas;
          transition: 0.30s ease;
        }
      }

      &.rovat_bedlove,
      &.rovat_bed-love {
        .category {
          background: $c-bed-love;
        }

        .image-wrapper {
          picture,
          & > a > img {
            border-color: $c-bed-love;
          }
        }

        .title a:hover span {
          box-shadow: inset 0 -10px $c-bed-love;
          transition: 0.30s ease;
        }
      }

      &.rovat_homedesign,
      &.rovat_home-design {
        .category {
          background: $c-home-design;
        }

        .image-wrapper {
          picture,
          & > a > img {
            border-color: $c-home-design;
          }
        }

        .title a:hover span {
          box-shadow: inset 0 -10px $c-home-design;
          transition: 0.30s ease;
        }
      }

      &.rovat_eskuvo {
        .category {
          background: $c-eskuvo;
        }

        .image-wrapper {
          picture,
          & > a > img {
            border-color: $c-eskuvo;
          }
        }

        .title a:hover span {
          box-shadow: inset 0 -10px $c-eskuvo;
          transition: 0.30s ease;
        }
      }

      &.rovat_recept {
        .category {
          background: $c-recept;
        }

        .image-wrapper {
          picture,
          & > a > img {
            border-color: $c-recept;
          }
        }

        .title a:hover span {
          box-shadow: inset 0 -10px $c-recept;
          transition: 0.30s ease;
        }
      }

      &.rovat_christmas {
        .category {
          background: $c-christmas;
        }

        .image-wrapper {
          picture,
          & > a > img {
            border-color: $c-christmas;
          }
        }

        .title a:hover span {
          box-shadow: inset 0 -10px $c-christmas;
          transition: 0.30s ease;
        }
      }
    }
  }
}
