//--------------------------------------------------------------
// Color Variables
//--------------------------------------------------------------

//colors which we can change
$c-default:         #1f1f1f;
$c-default-rgb:     12, 12, 12;
$c-white:           #fff;
$c-sztarok:         #dec1de;
$c-fashion:         #d1bdef;
$c-egeszseg:        #d8daaa;
$c-lifestyle:       #eccecc;
$c-utazas:          #b3e5ed;
$c-bed-love:        #f9b2b2;
$c-home-design:     #e5d1b7;
$c-eskuvo:          #b5afc9;
$c-recept:          #dcba9f;
$c-interju:         #aac5c5;
$c-magenta:         #db45c2;
$c-red:             #e23200;
$c-horoscope:       #ccb2ca;
$c-szakertok:       #8fd1c4;
$c-royal-clinics:   #d6caaa;
$c-femanyu:         #e5d0ba;
$c-inspiralonok:    #f7e196;
$c-in-sponsor:      #2c1a49;
$c-agi-vlog:        #9ed0dd;
$c-christmas:       #e9d090;
$c-20perc-oszinten: #8abaae;
$c-dogblog:         #eccecc;
$c-highlife:        #961623;
$c-meteospasmyl:    #f0ae92;
$main-purple:       #624978;
$secondary-purple:  #9987a3;
$c-in-purple:       #3a2054;
$grey:              #e9e9e9;
$darkgrey:          #919191;
$brown:             #955f3b;
$c-caption:         #adb3ce;
$c-pr-boxes:        #edd1ed;
$c-keretes:         #eee2ff;
$tags-bg:           #f4eff4;
$bg-form-item:      #f7f5f7;
$bg-advent-cal:     #d46060;
$bg-cta-button:     #735a97;
$bg-cta-button_over: #e4478a;
$gb-cikkvegi:       #eee2ff;
$c-drop-shadow:     #9f7da0;
$colorize-yellow:   #ffff00;
$toggle-off:        #a6a6a6;
$toggle-on:         $secondary-purple;
$in-toggle-on:      #3a2054;
$in-button-on:      $c-inspiralonok;
$content-box-bg:    #ececec;
$content-box-border: #08101f;
$content-box-brd1:  #171717;
$content-box-brd2:  #b8a59e;
$overlay-bg:        rgba(0, 0, 0, .5);
$secondary-menu-bg: rgba(0, 0, 0, 0.48);
$c-black:           var(--primary, $c-default);
$c-rgb:             var(--primary-rgb, $c-default-rgb);
$c-light-blue:      rgba($c-rgb, .1);
$c-light-black:     rgba(0, 0, 0, .8);
$c-border:          rgba($c-rgb, .6);
$c-bg-color:        rgba($c-rgb, .05);
$c-orange-bg:       rgba(255, 179, 81, .7);
$webform-bg:        rgba(115, 90, 151, .1);
$drop-shadow-color: rgba($c-drop-shadow, .24);
$drop-shadow:       drop-shadow(0 0 10px $drop-shadow-color);

/*
 * Sponsorations - temporary colors.
*/
$hotwheels-bg:      #079cde;
$tesco-bg:          #279989;
