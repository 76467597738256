/* shortcode embed styles */
.tw-post {
  display: inline-block;
  margin: 0 auto;
}

.fb-post {
  display: table !important;
  margin: 0 auto;

  .mobile & {
    width: 100% !important;

    span {
      width: 100% !important;

      iframe {
        width: 100% !important;
      }
    }
  }
}

.videa-wrapper {
  position: relative;
  padding-bottom: 51.5%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.videa-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tv2-wrapper {
  position: relative;
  padding-bottom: 51.5%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.tv2-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tenyek-wrapper {
  position: relative;
  padding-bottom: 51.5%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.tenyek-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inda-wrapper {
  position: relative;
  padding-bottom: 51.5%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.pinterest-wrapper {
  text-align: center;
}

.spikeembed {
  margin: 10px 0;
  text-align: center;

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  iframe {
    margin: 0 auto !important;

    .mobile & {
      width: 100%;
    }
  }
}

.youtube-video {
  width: 100%;

  @include breakpoint($bp-desktop-below) {
    height: auto;
  }

  &.normal {
    aspect-ratio: 4 / 3;
  }

  &.widescreen {
    aspect-ratio: 16 / 9;
  }
}

.field-name-body {
  .instagram-media {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
