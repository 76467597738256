#footer {
  ul.menu {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: space-between;
    height: 40px;
    align-items: center;

    .mobile & {
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      list-style: none;

      a {
        color: $c-white;
        text-transform: capitalize;
        text-decoration: none;
      }
    }
  }
}
